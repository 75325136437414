import React, { useEffect, useState, useMemo } from "react";
import ReactPaginate from "react-paginate";
import "./UserListing.css";
import { FRANCHISE_LIST_API_PATH, HIBEE_ADMIN_URL, HIBEE_AUTH_URL, HIBEE_PRODUCT_URL, SOCIETY_LIST, USER_LISTING, USER_UPDATE_STATUS } from "../../api_interface/apiURLs";
import { getApi, getTokenApi, putTokenApi } from "../../api_interface/apis";
import MainTemplate from "../../components/MainTemplate";
import { debounce } from "../../utils/helperFunctin";
import { toast } from "react-toastify";
import { Form } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";


function UserListing() {
  const [itemOffset, setItemOffset] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [frnachiseList, setFranchiseList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")


  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(usersData.length / itemsPerPage);
  const currentItems = usersData.slice(itemOffset, endOffset);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % usersData.length;
    setItemOffset(newOffset);
  };
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [updateType, setUpdateType] = useState(true)
  const [societyList, setSocietyList] = useState([])
  const [filterSocity, setFilterSocity] = useState(false);
  const [selectedValues, setSelectedValues] = useState("");


  /** Society Listing  API **/
  const handleGetSocietyLists = async (search) => {
    setLoader(true);
    await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST)
      .then((ele, ind) => {
        if (ele.status == 200) {
          const data = ele.data.data
          const revData = data.reverse()
          setSocietyList(revData);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUsersList = async search => {
    setLoader(true)
    /** path variable**/
    const type = 'customer'
    const res = await getApi(HIBEE_AUTH_URL + USER_LISTING + `/${type}` + `?sort=${sort}&search=${search || query || ""}`);
    if (res.status == 200) {
      setUsersData(res?.data?.data);
      setLoader(false)
    }
  };

  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getUsersList(search)
  }, 500), [])

  useEffect(() => {
    getUsersList();
  }, [updateType]);


  useEffect(() => {
    getUsersList()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])


  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: ele, index: id })
    // Path Params
    const userId = id
    const status = ele
    try {
      const res = await putTokenApi(HIBEE_ADMIN_URL + USER_UPDATE_STATUS + `/${userId}/${status}`)
      if (res.status == 200) {
        if (res?.data?.status == 'approved') {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.status == 'disapproved') {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        getUsersList()
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      })
      getUsersList()
    }
  }

  const updateStatus = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedValues((prevValues) =>
        prevValues ? `${prevValues},${selectedValue}` : selectedValue
      );
    } else {
      setSelectedValues((prevValues) =>
        prevValues
          .split(",")
          .filter((value) => value !== selectedValue)
          .join(",")
      );
    }
  };
  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
    { label: "Watermelon 🍉", value: "watermelon" },
    { label: "Pear 🍐", value: "pear", disabled: true },
    { label: "Apple 🍎", value: "apple" },
    { label: "Tangerine 🍊", value: "tangerine" },
    { label: "Pineapple 🍍", value: "pineapple" },
    { label: "Peach 🍑", value: "peach" }
  ];

  const [selectedOne, setSelectedOne] = useState([]);


  useEffect(() => {
    handleGetSocietyLists()
  }, [])



  return (
    <MainTemplate
      categoryName="User Listing"
      categoryList={["User Listing"]}
    >
      <>
        <div className="row mb-4 rounded p-2 bg-white">
          <div className="col-sm-6 my-3 col-md-2">
            <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
              <option value="" selected>Sort by</option>
              <option value="name">Alphabatically</option>
              <option value="dateold">Date: Acsending</option>
              <option value="datenew">Date: Descending</option>
            </select>
          </div>
          <div className="col-sm-6 my-3 col-md-2">
            <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
              <option value="10" selected>10 per page</option>
              <option value="20"><input type='checkbox' />20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </select>
          </div>

          {/* <div className="col-sm-6 my-3 col-md-2">
            <div
              className="t-12 form-select"
              aria-label="Default select example"
              onClick={() => setFilterSocity(!filterSocity)}
            >Search</div>
            <div
              className={filterSocity ? "dropdownoptionUserListing overflow-auto userListingPageScroll" : " visually-hidden"}
            >
              <input
                type="text"
                name="query"
                className="t-12 form-control"
                placeholder="Search"
              />
              {societyList?.map((el) => <div className="dropdownselectoption ">
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  onChange={updateStatus}
                  value={el.societyName}
                  aria-label="Checkbox for following text input"
                />
                {el.societyName}
              </div>)}
            </div>
          </div> */}


          {/* <div className="col-sm-6 my-3 col-md-2">
            <select className="t-12 form-select" aria-label="Default select example"
              onChange={e => setUpdateType(e.target.value)}
            >
              <option value="" selected>type</option>
              <option selected value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
          </div> */}
          <div className="my-3 col-md-3">
            <input type="text" autoComplete="off" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
          </div>
          {/* <div className="col-sm-6 my-3 col-md-2">
          </div> */}
        </div>



        {/* table */}
        <div className="row flex-column align-items-center">
          <div className="row rounded text-center bg-white">
            {/* header */}
            <div className="row m-0 ">
              <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 px-3 col-md-2">
                NAME
              </div>
              <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-3">
                EMAIL
              </div>
              <div className="font-weight-bold t-12 text-uppercase  p-0 my-3 px-3 col-md-2">
                MOBILE NUMBER
              </div>
              <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-2">
                DATE
              </div>
              <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-1">
                SoCIETY
              </div>
              {/* <div className="font-weight-bold t-12 text-uppercase   p-0 my-3 px-3 col-md-2">
                Active{" "}/{" "}Inactive
              </div> */}
            </div>

            {/* body */}
            <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
              {
                currentItems?.map((el) => (
                  <div
                    className="row border rounded m-0 mb-2 d-flex align-items-center"
                    key={el?._id}
                  >
                    {/* <div className="t-12 text-uppercase my-3 col-md-1"> */}
                    {/* <div className="categoryImageDiv shadow-sm  rounded p-1"> */}
                    {/* <Link
                        className="text-dark"
                        to={`/usersData/${el?._id}`}
                        style={{ textDecoration: "none" }}
                      > */}
                    {/* <img
                        src={el?.image}
                        className="categoryImage h-100 rounded"
                      /> */}
                    {/* </Link> */}
                    {/* </div> */}
                    {/* </div> */}
                    {/* <div className="t-12 text-uppercase my-3 col-md-2">
                    <div className="user-photo row align-items-center"
                    //  style={{backgroundColor:`#${Math.random().toString(16).substr(-6)}`}}
                    >
                      <p className="user-initial col" >
                        {el?.name.slice(0, 1)}
                      </p>
                    </div>
                  </div> */}
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el?.name}
                    </div>
                    <div className="t-12 text-uppercase my-4 col-md-3">
                      {el?.email}
                    </div>
                    <div className="t-12 text-uppercase my-2 col-md-2">
                      {el?.phone}
                    </div>
                    <div className="t-12 text-uppercase my-2 col-md-2">
                      {el?.updatedAt?.slice(0, 10)}
                    </div>
                    <div className="t-12 text-uppercase my-2 col-md-1">
                      {el?.society?.societyName}
                    </div>
                    {/* <div className="t-12 text-uppercase my-3 col-md-2">
                      <div>
                        <label className="switch">
                          <input className="switch-input" type="checkbox"
                            // checked={[el.active].includes(aprove.status) && aprove.index == el._id ? true : false}
                            checked={el.active}
                            // onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}
                            onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}

                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div> */}
                    {/* <div className="t-12 text-uppercase my-3 col-md-4">
                    <svg
                      className="bi bi-pencil border-bottom border-dark"
                      onClick={() => {
                        setModal(true);
                        setcategory(el);
                      }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </div> */}


                    {/* <div className="t-12 text-uppercase my-3 col-md-1">
                  <div class="dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      data-menu-target={`drop-down-menu-${el?._id}`}
                      className="bi bi-three-dots dropdown-btn cursorPointer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                    <div
                      id={`drop-down-menu-${el?._id}`}
                      className="dropdown-content p-2 mobile-dropdown5"
                    >
                      <div
                        className="px-3 py-2 d-flex cursorPointer"
                        onClick={() =>
                          setActionModal({
                            ...actionModal,
                            show: true,
                            data: el,
                            type: "DELETE",
                          })
                        }
                      >
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </span>
                        <span className="mx-2">
                          <div className="font-weight-bold">Delete</div>
                          <div className="">Delete this product</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}

                  </div>

                ))}
              {loader == true &&
                <div className="text-center mt-5" >
                  <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                    Loading...
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="row m-2 d-flex justify-content-center align-items-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item mb-0"
            subContainerClassName="pages "
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </>
    </MainTemplate>
  );
}

export default UserListing;
