import React, { useEffect, useState } from "react";
import "./HomepageModifier.css";
import MainTemplate from "../../components/MainTemplate";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi, postApi, postTokenApi } from "../../api_interface/apis";
import { HIBEE_ADMIN_URL, HIBEE_ORDER_URL, HIBEE_PRODUCT_URL, HOME_CAROUSEL, HOME_GROUP, HOME_PRODUCT } from "../../api_interface/apiURLs";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function HomepageModifierAddProduct() {
    const location = useLocation();
    const { state } = location || {}
    const [productForm, setProductForm] = useState({ title: "", link: "", groupId: "", id: "", loader: false })
    const [productFormError, setProductFormError] = useState({ title: "", link: "", groupId: "", loader: false })

    const [groupForm, setGroupForm] = useState({ title: "", image: "", link: "", loader: false, id: "" })
    const [bannerFormError, setGroupFormError] = useState({ title: "", image: "", link: "", loader: false, id: "" })

    const navigate = useNavigate()


    /** Product **/
    const handleChangeCarousel = (ele) => {
        setProductForm({ ...productForm, [ele.target.name]: ele.target.value })
        setProductFormError({ ...productFormError, [ele.target.name]: null })
    }

    const handleValidationProduct = () => {
        const newErrors = {}
        if (productForm.title.trim() == "") {
            newErrors.title = "Please enter title"
        }
        if (productForm.link.trim() == "") {
            newErrors.link = "Please enter link"
        }
        if (productForm.groupId.trim() == "") {
            newErrors.groupId = "Please enter group id"
        }
        return newErrors
    }

    const handleSubmitProduct = async () => {
        const handleValidationObject = handleValidationProduct()
        if (Object.keys(handleValidationObject).length > 0) {
            setProductFormError(handleValidationObject)
        } else {
            setProductForm({ ...productForm, loader: true })
            try {
                const payload = {
                    title: productForm.title,
                    link: productForm.link,
                    groupId: productForm.groupId,
                }
                if (productForm.id) {
                    payload.productListId = productForm.id
                }
                const reponce = await postApi(HIBEE_ADMIN_URL + HOME_PRODUCT, payload)
                if (reponce?.status == 200) {
                    console.log(reponce, 'reponcereponce')
                    toast.success(reponce.data.message);
                    setProductForm({ ...productForm, title: "", link: "", groupId: "", loader: false })
                    navigate('/homepagemodifierproductslisting')
                }
            } catch (err) {
                const message = err?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
                setProductForm({ ...productForm, title: "", link: "", groupId: "", loader: false })
            }
        }
    }

    /** Group **/
    const handleChangeGroup = (ele) => {
        if (ele.target.name == 'image') {
            setGroupForm({ ...groupForm, [ele.target.name]: ele.target.files[0] })
            setGroupFormError({ ...bannerFormError, [ele.target.name]: null })
        } else {
            setGroupForm({ ...groupForm, [ele.target.name]: ele.target.value })
            setGroupFormError({ ...bannerFormError, [ele.target.name]: null })
        }
    }

    const handleValidationGroup = () => {
        const newErrors = {}
        if (groupForm.title.trim()  == "") {
            newErrors.title = "Please enter banner name"
        }
        return newErrors
    }

    const handleSubmitGroup = async () => {
        const handleValidationObject = handleValidationGroup()
        if (Object.keys(handleValidationObject).length > 0) {
            setGroupFormError(handleValidationObject)
        } else {
            try {
                const payload = {
                    title: groupForm.title,
                }
                // const reponce = await postTokenApi(HIBEE_ADMIN_URL + HOME_CAROUSEL, fd, { "Content-Type": "multipart/form-data" })
                const reponce = await postTokenApi(HIBEE_PRODUCT_URL + HOME_GROUP, payload)
                if (reponce?.status == 200) {
                    toast.success(reponce.data.message);
                    setGroupForm({ ...groupForm, loader: false, title: "", image: "" })
                    navigate('/homepage-modifer-groups-list')
                }
            } catch (err) {
                const message = err?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
                setProductForm({ ...productForm, loader: false, name: "", image: "" })
            }
        }
    }

    useEffect(() => {
        if (state) {
            setGroupForm(prevState => ({
                ...prevState,
                id: state.data
            }));
        }

        if (state && state?.page == 'PRODUCT' && state?.editData) {
            setProductForm((prevState) => ({
                ...prevState,
                title: state.editData.title,
                link: state.editData.link,
                groupId: state.editData.groupId,
                id: state.editData._id
            }))
        }

    }, [state]);


    return (
        <div>
            {
                state && state?.page == 'GROUP'
                    ?
                    <div>
                        <MainTemplate categoryName="Add Group" categoryList={["Homepage Modifier", "Product > Add Product"]}>
                            <div className="row mb-4 rounded bg-white homepageModifierPadding">
                                <div className="form-group py-2">
                                    <label className="">Add title{" "}<span className="text-danger" >*</span></label>
                                    <input type="text" name='title'
                                        value={groupForm.title} onChange={handleChangeGroup} className={`w-75 placeholder-light form-control py-2 px-3 mt-2 ${productFormError?.title && "border-danger"}`} placeholder="eg: New Launch" />
                                    <span className="text-danger" >{productFormError?.title}</span>
                                </div>
                                <div className="form-group py-2" style={{ marginTop: '100px' }}>
                                    <button className="btn btn-dark text-warning px-5"
                                        style={{ backgroundColor: "#12352f" }}
                                        onClick={handleSubmitGroup}>Save{" "}
                                        {
                                            productForm.loader
                                                ?
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                    size="sm"
                                                />
                                                :
                                                ''
                                        }
                                    </button>
                                </div>
                            </div>
                        </MainTemplate>
                    </div>
                    :
                    <div>
                        <MainTemplate categoryName="Add Product" categoryList={["Homepage Modifier", "Carousel> Add Group"]}>
                            <div className="row mb-4 rounded bg-white homepageModifierPadding">
                                <div className="form-group py-2">
                                    <label className="">Title{" "}<span className="text-danger" >*</span></label>
                                    <input type="text" name='title'
                                        value={productForm.title} onChange={handleChangeCarousel} className={`w-75 placeholder-light form-control py-2 px-3 mt-2 ${productFormError?.title && "border-danger"}`} placeholder="eg: Enter Title" />
                                    <span className="text-danger" >{productFormError?.title}</span>
                                </div>
                                <div className="form-group py-2">
                                    <label className="">Deep Link{" "}<span className="text-danger" >*</span></label>
                                    <input type="text" name='link'
                                        value={productForm.link} onChange={handleChangeCarousel} className={`w-75 placeholder-light form-control py-2 px-3 mt-2 ${productFormError?.link && "border-danger"}`} placeholder="eg: Enter Deep Link" />
                                    <span className="text-danger" >{productFormError?.link}</span>
                                </div>
                                <div className="form-group py-2">
                                    <label className="">Group Id{" "}<span className="text-danger" >*</span></label>
                                    <input type="text" name='groupId'
                                        value={productForm.groupId} onChange={handleChangeCarousel} className={`w-75 placeholder-light form-control py-2 px-3 mt-2 ${productFormError?.groupId && "border-danger"}`} placeholder="eg: New Launch" />
                                    <span className="text-danger" >{productFormError?.groupId}</span>
                                </div>
                                <div className="form-group py-2" style={{ marginTop: '100px' }}>
                                    <button className="btn btn-dark text-warning px-5"
                                        style={{ backgroundColor: "#12352f" }}
                                        onClick={handleSubmitProduct}>Save{" "}
                                        {
                                            productForm.loader
                                                ?
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                    size="sm"
                                                />
                                                :
                                                ''
                                        }
                                    </button>
                                </div>
                            </div>
                        </MainTemplate>
                    </div>
            }
        </div>
    );
}

export default HomepageModifierAddProduct;