import React, { useState, useEffect, useMemo } from "react";
import MainTemplate from "../../components/MainTemplate";
import "../Society/societylisting.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import Modal from "react-modal";
import { Button, Form, Spinner } from "react-bootstrap";
import { FRANCHISE_LIST_API_PATH, HIBEE_ADMIN_URL, HIBEE_PRODUCT_URL, HOME_CAROUSEL, SOCIETY_LIST } from "../../api_interface/apiURLs";
import { deleteTokenApi, getApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import '../../App.css'


export default function CarouselListingPage() {
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [itemOffset, setItemOffset] = useState(0);
    const [societyList, setSocietyList] = useState([])
    const [aprove, setAprove] = useState({
        status: "",
        index: ""
    })
    const [updateType, setUpdateType] = useState(true)
    const [query, setQuery] = useState("");
    const [sort, setSort] = useState("");

    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(societyList.length / itemsPerPage);
    const currentItems = societyList.slice(itemOffset, endOffset);

    const [loader, setLoader] = useState(false);


    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % societyList.length;
        setItemOffset(newOffset);
    };

    const handleGetCarousel = async (search, updateType, sort) => {
        setLoader(true)
        try {
            const reponce = await getApi(HIBEE_ADMIN_URL + HOME_CAROUSEL + `?sort=${sort}&search=${search || query || ""}&${updateType == true ? `visiblity=${true}` : `visiblity=${false}`}`)
            if (reponce?.status == 200) {
                const res = reponce.data.data
                const rverseObject = res.reverse()
                setSocietyList(rverseObject)
                setLoader(false)
            }
        } catch (err) {
            setLoader(false)
        }
    }

    const updateSort = (e) => {
        setSort(e.target.value);
    };

    useEffect(() => {
        getSearchedData(query, updateType, sort);
    }, [query, updateType, sort]);

    const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
        handleGetCarousel(search, updateType, sort);
    }, 500), []);


    const approveAdmin = async (ele, id) => {
        setAprove({ ...aprove, status: ele, index: id })
        const payload = {
            carousalId: id,
            status: "updateVisibility",
            visiblity: ele
        }
        try {
            const res = await postTokenApi(HIBEE_ADMIN_URL + HOME_CAROUSEL, payload)
            if (res.status == 200) {
                if (res?.data?.status == 'approved') {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                if (res?.data?.status == 'disapproved') {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                handleGetCarousel()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            })
            handleGetCarousel()
        }
    }

    useEffect(() => {
        handleGetCarousel()
    }, [])

    return (
        <MainTemplate
            categoryName="Carousel Listing Page"
            categoryList={["Homepage Modifier", "Carousel"]}
        >
            {/* sorting */}
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={updateSort}
                    >
                        <option value="" selected>
                            Sort by
                        </option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>+
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10" selected>
                            10 per page
                        </option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select className="t-12 form-select" aria-label="Default select example"
                        onChange={e => setUpdateType(e.target.value === 'true' ? true : false)}
                    >
                        <option value={true} selected>Active</option>
                        <option value={false}>Inactive</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <input
                        type="text"
                        name="query"
                        className="t-12 form-control"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        autoComplete="off"
                    />
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <div className="custom-color">
                        <Link to="/homepage-modifier-carousel-add-banners"
                            state={{ page: 'CAROUSEL' }}
                        >
                            <button
                                className="t-12 px-4 btn  buttoncolor-green text-warning dropdown-btn"
                                data-menu-target={`drop-down-menu-multiple-order`}
                                // to="/homepage-modifier-carousel-add-banners"
                                // state={{ page: 'CAROUSEL' }}
                                style={{ backgroundColor: "#12352f" }}
                            >
                                Create Carousels
                            </button>
                        </Link>
                    </div>
                </div>

            </div>


            {/* table */}
            <div className="row flex-column align-items-center">
                <div className="row rounded text-center bg-white">
                    {/* header */}
                    <div className="row m-0 ">
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Carousel Name
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Number of Banners
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Edit
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Active / Inactive
                        </div>
                        {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                        </div> */}
                    </div>

                    {/* body */}
                    <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
                        {
                            loader == false && currentItems?.map((ele, ind) => {
                                return <div
                                    className="row border rounded m-0 mb-2 d-flex align-items-center"
                                >
                                    {/* <div className="t-12 text-uppercase my-3 col-md-2">
                                       <img src="" alt="" />
                                      </div> */}
                                    <div className="t-12 text-uppercase my-3 col-md-3">
                                        <Link to="/carousel-banner-list" state={{ page: 'BANNER', data: ele?._id }}>  {ele.title ? ele.title : 'null'}</Link>
                                    </div>
                                    <div className="t-12 text-uppercase my-3 col-md-2">{ele?.banner?.length}</div>
                                    <div className="t-12 text-uppercase my-3 col-md-3">
                                        <Link
                                            to="/homepage-modifier-carousel-add-banners"
                                            state={{ page: 'CAROUSEL', eidtData: ele }}
                                            className="t-12 text-uppercase my-3 col-md-2">
                                            <svg
                                                className="bi bi-pencil border-bottom border-dark text-dark cursorPointer"
                                                // onClick={() => {
                                                //   setSocietyModal(true);
                                                //   setcategory(el);
                                                // }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                            </svg>
                                        </Link>
                                    </div>
                                    <div className="t-12 text-uppercase my-3 col-md-3">
                                        <div className="m-auto" >
                                            <label className="switch">
                                                <input className="switch-input" type="checkbox"
                                                    checked={ele.visiblity}
                                                    onChange={e => e.target.checked ? approveAdmin(e.target.checked, ele?._id) : approveAdmin(e.target.checked, ele?._id)}
                                                />
                                                <span className="switch-label"></span>
                                                <span className="switch-handle"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                        {loader == true && (
                            <div className="text-center mt-5">
                                <button
                                    class="btn mt-5"
                                    style={{ backgroundColor: "#FFEDC2" }}
                                    type="button"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>{" "}
                                    Loading...
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
        </MainTemplate >
    );
}
