import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "../FranchiseRequestListning/FranchiseRequestListning.css";
import MainTemplate from "../../components/MainTemplate";

function FranchiseRequestListning() {
  return (
    <MainTemplate
      categoryName="Categories"
      categoryList={["Admin", "Categories"]}
    >
      <div className="container">
        <div className="row p-0 ">
          <div className="col my-3 col-md-1">
            <select className="form-select sortb" outline="none">
              <option selected>Sort By</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>

          <div className="col my-3 col-md-1 srch">
            <select className="form-select widthselectes" outline="none">
              <option selected>10</option>
              <option value="1">9</option>
              <option value="2">8</option>
              <option value="3">7</option>
            </select>
          </div>

          <div className="col-sm-6 my-3 col-md-2">
            <input
              id="srchsizing"
              type="text"
              className="form-control "
              placeholder="Search"
            />
          </div>

          <div className="col-sm-6 my-3 col-md-5"></div>
          <div className="col-sm-6 my-3 col-md-2 ">
            <button
              type="button"
              className="btn btn-success form-select actiondetail"
              outline="none"
            >
              Action
            </button>
          </div>
        </div>
        <div className="midspace">
          <div className="row">
            {/* header */}
            <div className="row m-3  fntdrk">
              <div className="t-12 my-3 col-md-1">
                <input type="checkbox" />
              </div>
              <div className=" my-3 mt-4 col-md-2 paddingtext">request ID</div>
              <div className=" my-3 mt-4 col-md-2">FULLName</div>
              <div className=" my-3 mt-4 col-md-2">phone number</div>
              <div className=" my-3 mt-4 col-md-1">city</div>
              <div className=" my-3 mt-4 col-md-3"></div>
            </div>
          </div>
          <div id="scrollingup">
            <div className="row rounded">
              {/* Body */}
              <div className="row m-3 mt-1 fntsmal rwidhtl paddingtops">
                <div className="t-12 my-4 col-md-1">
                  <input type="checkbox" />{" "}
                </div>
                <div className="my-3 mt-4 col-md-2">#6784513</div>
                <div className="my-3 mt-4 col-md-2">Sanjiv Mehta</div>
                <div className="my-3 mt-4 col-md-2">9846738763</div>
                <div className="my-3 mt-4 col-md-1">Jalandhar</div>
                <div className="my-3 mt-4 col-md-2"></div>
                <div className="my-3 mt-3 col-md-2">
                  <span className="dots">...</span>
                </div>
              </div>

              <div className="row m-3 mt-2 fntsmal rwidhtl paddingtops">
                <div className="t-12 my-4 col-md-1">
                  <input type="checkbox" label="" />{" "}
                </div>
                <div className="my-3 mt-4 col-md-2">#6743823</div>
                <div className="my-3 mt-4 col-md-2">Salman Hadid</div>
                <div className="my-3 mt-4 col-md-2">6376387267</div>
                <div className="my-3 mt-4 col-md-1">Ludhiana</div>
                <div className="my-3 mt-4 col-md-2"></div>
                <div className="my-3 mt-3 col-md-2">
                  <span className="dots">...</span>
                </div>
              </div>

              <div className="row m-3 mt-2 fntsmal rwidhtl paddingtops">
                <div className="t-12 my-4 col-md-1">
                  <input type="checkbox" label="" />{" "}
                </div>
                <div className="my-3 mt-4 col-md-2">#6783433</div>
                <div className="my-3 mt-4 col-md-2">Bahadur Ali </div>
                <div className="my-3 mt-4 col-md-2">7498373676</div>
                <div className="my-3 mt-4 col-md-1">Faridabad</div>
                <div className="my-3 mt-4 col-md-2"></div>
                <div className="my-3 mt-3 col-md-2">
                  <span className="dots">...</span>
                </div>
              </div>

              <div className="row m-3 mt-2 fntsmal rwidhtl paddingtops">
                <div className="t-12 my-4 col-md-1">
                  <input type="checkbox" label="" />{" "}
                </div>
                <div className="my-3 mt-4 col-md-2">#6784324</div>
                <div className="my-3 mt-4 col-md-2">Mhavina K Rai</div>
                <div className="my-3 mt-4 col-md-2">9836563763</div>
                <div className="my-3 mt-4 col-md-1">Gorakhpur</div>
                <div className="my-3 mt-4 col-md-2"></div>
                <div className="my-3 mt-3 col-md-2">
                  <span className="dots">...</span>
                </div>
              </div>

              <div className="row m-3 mt-2 fntsmal rwidhtl paddingtops">
                <div className="t-12 my-4 col-md-1">
                  <input type="checkbox" label="" />{" "}
                </div>
                <div className="my-3 mt-4 col-md-2">#6723485</div>
                <div className="my-3 mt-4 col-md-2">Gopi Rao Sha</div>
                <div className="my-3 mt-4 col-md-2">8937876398</div>
                <div className="my-3 mt-4 col-md-1">Saharanpur</div>
                <div className="my-3 mt-4 col-md-2"></div>
                <div className="my-3 mt-3 col-md-2">
                  <span className="dots">...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainTemplate>
  );
}

export default FranchiseRequestListning;
