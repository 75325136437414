import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "./FranchiseDetails.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getApi, getTokenApi, putApi } from "../../api_interface/apis";
import { CHANGE_PASSWORD, FRANCHISE_LIST_API_PATH, HIBEE_AUTH_URL, HIBEE_PRODUCT_URL } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import imagenotavailable from '../../assets/images/imagenotavailable.png'
import Modal from "react-modal";
import { checkPasswordValidity, customModalStyles } from "../../utils/helperFunctin";
import { toast } from "react-toastify";
import { Spinner } from 'react-bootstrap'

function FranchiseDetails() {
  const [franchiseDetails, setFranchiseDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [modal, setModal] = useState(false);
  const [changePassword, setChanePassword] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })
  const [changePasswordError, setChanePasswordError] = useState({ currentPassword: '', newPassword: '', confirmPassword: "", loader: false })

  const handleChange = (ele) => {
    setChanePassword({ ...changePassword, [ele.target.name]: ele.target.value })
    setChanePasswordError({ ...changePasswordError, [ele.target.name]: null })
  }


  const frenchiseId = useParams();
  const handleGetFranchiseDetails = async () => {
    if (frenchiseId) {
      setLoader(true);
      await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `/?frenchiseId=${frenchiseId?.franchiseId}`)
        .then((ele, ind) => {
          if (ele.status == 200) {
            setLoader(false);
            // console.log(ele?.data?.data, '@@@@@@@');
            setFranchiseDetails(ele?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    }
  };

  const handlaValidation = () => {
    const newErrors = {}
    const { currentPassword, newPassword, confirmPassword } = changePassword
    if (!currentPassword) newErrors.currentPassword = 'please enter current password'
    // else if (!checkPasswordValidity(currentPassword)) newErrors.confirmPassword = 'password && confirm password should be same'
    if (!newPassword) newErrors.newPassword = 'please enter new password'
    else if (!checkPasswordValidity(newPassword)) newErrors.confirmPassword = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
    if (!confirmPassword) newErrors.confirmPassword = 'please enter confirm password'
    // else if (!checkPasswordValidity(currentPassword))  = 'Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number'
    else if (newPassword != confirmPassword) newErrors.confirmPassword = 'password && confirm password should be same'
    return newErrors
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setChanePassword({ ...changePassword, loader: true })
      const handlaValidationObject = handlaValidation()
      if (Object.keys(handlaValidationObject).length > 0) {
        setChanePasswordError(handlaValidationObject)
        setChanePassword({ ...changePassword, loader: false })

      } else {
        const payload = {
          old_password: changePassword.currentPassword,
          new_password: changePassword.confirmPassword,
          adminId: franchiseDetails?.user_id
        }
        const responce = await putApi(HIBEE_AUTH_URL + CHANGE_PASSWORD, payload)
        if (responce.status == 200) {
          console.log(responce, 'responce');
          toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
          handleClear()
        }
      }
    } catch (err) {
      const message = err?.response?.data?.message || "Something went wrong";
      toast.error(message, { position: "top-center", autoClose: 2000 });
      handleClear()
    }
  }


  const handleClear = () => {
    setChanePassword({
      ...changePassword,
      currentPassword: '',
      newPassword: '',
      confirmPassword: "",
      loader: false
    })
    setChanePasswordError({
      ...changePasswordError,
      currentPassword: '',
      newPassword: '',
      confirmPassword: "",
      loader: false

    })
    setModal(false)
  }

  useEffect(() => {
    handleGetFranchiseDetails();
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  console.log(franchiseDetails, 'franchiseDetails');

  return (
    <MainTemplate
      categoryName={`Franchise ${franchiseDetails?.unique_code ? franchiseDetails?.unique_code : 'Null'}`}
      categoryList={["Admin", "Franchise", franchiseDetails?.unique_code ? franchiseDetails?.unique_code : "Null"]}
    >
      <div className="containerbg overflow-auto rounded bg-white row w-100">
        <div className="container">
          <div className="tab-header d-flex p-2">
            <div
              className={`mx-3 m-2  tab-item nav-link ${activeTab === 0 ? "aactive" : ""}`}
              onClick={() => handleTabClick(0)} >
              Personal Info
            </div>
            <div
              className={`mx-3 m-2  tab-item nav-link ${activeTab === 1 ? "aactive" : ""}`}
              onClick={() => handleTabClick(1)}
            >
              Bank Details
            </div>
          </div>
          {loader == false && activeTab == 0 && (
            <div className="row">
              {/* <div className="rowhi"></div> */}
              <div className="col-md-5 row justify-content-center">
                <div className="col-6 mt-4">
                  <img
                    src={franchiseDetails?.profile_img ? franchiseDetails?.profile_img : imagenotavailable}
                    alt=""
                    className="imge mb-4"
                  />
                  <div className="box1 md-3 mb-3">
                    <div className="size1">Name</div>
                    <div className="size2 color1">
                      {franchiseDetails?.owner_name}
                    </div>
                  </div>

                  <div className="box2 md-3 mb-3">
                    <div className="size1">Phone Number</div>
                    <div className="size2 color1 underli">
                      {franchiseDetails?.phone_number}
                    </div>
                  </div>

                  <div className="box3 md-3 mb-3">
                    <div className="size1">Email</div>
                    <div className="size2 color1 underli">
                      {franchiseDetails?.email}
                    </div>
                  </div>

                  {/* <div className="box4 md-3 mb-3">
                    <div className="size1">Unique code</div>
                    <div className="size2 color1">
                      {franchiseDetails?.unique_code}
                    </div>
                  </div> */}

                  <div>
                    <div className="box3 md-2 d-flex justify-content-between">
                      {/* <div className="size1">Active</div>
                      <div>
                        <label className="switch">
                          <input
                            className="switch-input"
                            type="checkbox"
                            name="cod_status"
                            checked={franchiseDetails?.cod_status}
                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div> */}
                    </div>
                    <div className="my-3">
                      <button
                        type="button"
                        className="t-12 w-100 btn btn-outline-dark"
                        onClick={() => {
                          setModal(true);
                          // setcategory({});
                        }}
                      >

                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-1">
                <div className="horriLine"></div>
              </div>

              <div className="col-md-3 part2 md-3">
                <div className="box5 md-3 mt-4">
                  <div className="size1">Address :-</div>
                  <div className="box6 md-3 mb-3">
                    <div className="size1">Society Name</div>
                    <div className="size2 color2">
                      {
                        franchiseDetails?.society?.societyName ? franchiseDetails?.society?.societyName : 'null'
                      }
                    </div>
                  </div>
                  {
                    franchiseDetails?.towerVillaType == 'TOWER'
                      ?
                      <div>
                        <div className="size1">Tower Name</div>
                        <div className="addr">
                          {
                            franchiseDetails?.tower?.name
                          }
                        </div>

                        <div style={{ marginTop: "10px" }} >
                          <div className="size1">Floor Number</div>
                          <div className="addr">
                            {
                              franchiseDetails?.floor?.floorName
                            }
                          </div>
                        </div>
                        <div className="box7 md-3 mb-3">
                          <div style={{ marginTop: "10px" }}>
                            <div className="size1">Flat Number</div>
                            <div className="addr">
                              {
                                franchiseDetails?.flatNumber
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      :
                      ''
                  }
                  {
                    franchiseDetails?.towerVillaType == 'VILLA'
                      ?
                      <div>
                        <div className="size1">Villa Name</div>
                        <div className="addr">
                          {
                            franchiseDetails?.villa?.villaName
                          }
                        </div>

                      </div>
                      :
                      ''
                  }
                </div>

                {/* <div className="box7 md-3 mb-3">
                  <div className="size1">Reffered by</div>
                  <div className="size2 color2">
                    {franchiseDetails?.referrals}
                  </div>
                </div> */}

                <div className="md-3 mb-3">
                  <div className="size1">Aadhar card</div>
                  <div className="size2 color2">
                    <img
                      src={franchiseDetails?.adhar_img}
                      alt=""
                      width="200px"
                      height="100px"
                    />
                  </div>
                </div>

                <div className="md-3 mb-3">
                  <div className="size1">PAN</div>
                  <div className="size2 color2">
                    <img
                      src={franchiseDetails?.pan_img}
                      alt=""
                      width="200px"
                      height="100px"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3 mb-3 row align-items-end">
                <div className="last col-8">
                  {/* <div className="col-9 md-3"></div>
               <img src={Edit} />
               <div className="Edits" onClick={handEditClick} >
                 Edit
               </div> */}
                </div>
              </div>
            </div>
          )}

          {activeTab === 1 && (
            <div className="mt-3 p-3">
              <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary" >BANK NAME
                </p>
                <p className="fw-bolder">{franchiseDetails?.bank_name ? franchiseDetails?.bank_name : '--'}</p>

              </div>
              <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary" >ACCOUNT HOLDER’S NAME
                </p>
                <p className="fw-bolder">{franchiseDetails?.account_holder_name ? franchiseDetails?.account_holder_name : '--'}</p>

              </div>
              <div className="border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary" >BANK ACCOUNT NUMBER
                </p>
                <p className="fw-bolder">{franchiseDetails?.account_number ? franchiseDetails?.account_number : '--'}</p>

              </div>
              <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary " >IFSC CODE </p>
                <p className="fw-bolder">{franchiseDetails?.ifsc_code ? franchiseDetails?.ifsc_code : '--'}</p>

              </div>
              {/*               
              <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary " >
                  BRANCH
                </p>
                <p className="fw-bolder">{franchiseDetails?.bank_branch ? franchiseDetails?.bank_branch : '--'}</p>
              </div> */}

              <div className=" border-b-dashed mt-4 m-2 d-flex justify-content-between">
                <p className="fs-6 fw-bolder text-secondary" >ACCOUNT TYPE
                </p>
                <p className="fw-bolder">{franchiseDetails?.account_type ? franchiseDetails?.account_type : '--'}</p>
              </div>
            </div>
          )}

          {loader == true && (
            <div className="text-center mt-5">
              <button
                class="btn mt-5"
                style={{ backgroundColor: "#FFEDC2" }}
                type="button"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                Loading...
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-5 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => {
                  setModal(false)
                  handleClear()
                }}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="modalMaxHeight">
            <h5 className="text-center" >Change Password</h5>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Current Password <span className="text-danger" >*</span></span>
              </div>
              <div className="col-6">
                <input
                  className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.currentPassword ? 'border border-danger' : ''}`}
                  type="password"
                  placeholder="please enter current password"
                  name='currentPassword'
                  value={changePassword.currentPassword}
                  onChange={handleChange}
                />
                <span className="text-danger"  >{changePasswordError.currentPassword}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span  >New Password  <span className="text-danger" >*</span></span>
              </div>
              <div className="col-6">
                <input
                  className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.newPassword ? 'border border-danger' : ''}`}
                  type="password"
                  placeholder="please enter new password"
                  name='newPassword'
                  value={changePassword.newPassword}
                  onChange={handleChange}
                />
                <span className="text-danger">{changePasswordError.newPassword}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span >Confirm New Password <span className="text-danger" >*</span></span>
              </div>
              <div className="col-6">
                <input
                  className={`placeholder-light form-control my-1 py-2 px-3 ${changePasswordError.confirmPassword ? 'border border-danger' : ''}`}
                  type="password"
                  placeholder="please enter confirm password"
                  name='confirmPassword'
                  value={changePassword.confirmPassword}
                  onChange={handleChange}
                />
                <span className="text-danger" >{changePasswordError.confirmPassword}</span>
              </div>
            </div>
          </div>

          <div className="row" >
            <div className="col-md-3 form-group my-3">
              <button className="btn btn-dark text-warning px-5" onClick={handleSubmit}>
                Submit
                {
                  changePassword.loader
                    ?
                    <Spinner
                      animation="border"
                      variant="light"
                      size="sm"
                    />
                    :
                    ''
                }
              </button>
            </div>
            <div className="col-md-3 form-group my-3" style={{ marginLeft: '10px' }}>
              <button className="btn btn-dark text-warning px-5" onClick={handleClear}>Cleare</button>
            </div>
          </div>
        </div>
      </Modal>
    </MainTemplate>
  );
}

export default FranchiseDetails;
