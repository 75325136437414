import React, { useEffect, useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import BulkCategoryCsv from "../../assets/BulkCategory.csv";
import Papa from "papaparse";
import excell from "../../assets/images/excell.jpg";
import axios from "axios";
import '../UploadImageList/UploadImageList.css'
import { BsUpload } from "react-icons/bs";


import { deleteTokenApi, getTokenApi, postTokenApi, getApi, putTokenApi } from "../../api_interface/apis";
import { BULK_CATEGORY_PATH, CATEGORY_PATH, HIBEE_PRODUCT_URL, HIBEE_BULK_IMAGE, WITHOUT_IMAGE_LIST, CATEGORY_IMAGE, WITHOUT_SUBCATEGORY_IMAGE_LIST, SUB_CATEGORY_IMAGE } from "../../api_interface/apiURLs";
import { customActionModalStyles, custombulkModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";
import { Spinner } from "react-bootstrap";
const allowedExtensions = ["csv"];

function UploadSubImageList() {
    const [itemOffset, setItemOffset] = useState(0);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const reader = new FileReader();
    const [actionModal, setActionModal] = useState({
        loader: false,
        show: false,
        type: "",
        data: {},
    });
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "", imagePreview: "" });
    const [categoryError, setcategoryError] = useState({ name: "", image: "", discount: "", commission: "", imageFile: "" });
    const [error, setError] = useState({});
    const [loader, setLoader] = useState(false);
    const [sort, setSort] = useState("");
    const [query, setQuery] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [bulkCategories, setBulkCategories] = useState({
        bulkData: [],
        show: false,
        error: "",
    });
    const [imageData, setImageData] = useState({
        image: '',
        id: '',
        loader: false
    });
    const [imageDataError, setImageDataError] = useState({
        image: '',
        id: '',
        loader: false
    });

    const [aprove, setAprove] = useState({
        status: "",
        index: ""
    })
    const [updateType, setUpdateType] = useState()

    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(categories.length / itemsPerPage);
    const currentItems = categories.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % categories.length;
        setItemOffset(newOffset);
    };

    const getCategories = async (search) => {
        setLoader(true);
        const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + WITHOUT_SUBCATEGORY_IMAGE_LIST + `?sort=${sort}&search=${search || query || ""}`);
        // const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + WITHOUT_SUBCATEGORY_IMAGE_LIST);
        if (res.status == 200) {
            setCategories(res?.data?.data);
            setLoader(false);
        }
    };

    const validation = () => {
        const { name, image, discount, commission, imageFile, _id } = category;
        let error = {};
        if (!name) {
            error.name = "Name Required";
        }
        // if (!discount) {
        //   error.discount = "Discount Required";
        // }
        // if (!commission) {
        //   error.commission = "Commission Required";
        // }

        if (!(image || imageFile)) {
            error.image = "Image Required";
        }
        // if (Object.keys(error)?.length > 0) {
        //   setError(error);
        //   return false;
        // } else {
        //   setError({});
        //   return true;
        // }
        return error;
    };
    const [bulkImages, setBulkImages] = useState([]);



    function fileToBinary(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        });
    }
    // const Submit1 = async (e) => {
    //   try {
    //     e.preventDefault();
    //     const res = await getApi(HIBEE_BULK_IMAGE + bulkImages.length);
    //     const data = res.data;
    //     data.map((el, index) => {
    //       console.log(el);
    //       const uploadFile = async () => {
    //         try {
    //           console.log("SINGLE IMAGE", bulkImages[index]);
    //           const body = new FormData();
    //           body.append("file", bulkImages[index]);
    //           const headers = { "Content-Type": bulkImages[index].type };
    //           const response = await axios.put(el,bulkImages[index], { headers });

    //           console.log(response);
    //         } catch (error) {
    //           console.error(error);
    //         }
    //       };
    //       uploadFile();
    //     });
    //     console.log(res, "getimages");
    //   } catch (err) {
    //     const message = err?.response?.data?.message || "Something went wrong";
    //     toast.error(message, { position: "top-center", autoClose: 2000 });
    //   }
    // };

    const deleteCategory = async (id) => {
        setActionModal({ ...actionModal, loader: true });
        const res = await deleteTokenApi(
            HIBEE_PRODUCT_URL + CATEGORY_PATH + `/${id}`
        );
        setActionModal({});
        getCategories();
    };

    const archieveCategory = (cat) => {
        setActionModal({ ...actionModal, loader: true });

        setActionModal({});
        getCategories();
    };

    const onChange = (e, type) => {
        if (type === "IMAGE") {
            setCategory({ ...category, image: "", imageFile: e.target?.files?.[0] });
        } else {
            setCategory({ ...category, name: e.target.value });
        }
        setTimeout(() => { }, 500);
    };
    function downloadFile(url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = url.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const closeCategoryModal = () => {
        setModal(false);
        setCategory({});
        setLoader(false);
        // setError({});
        hendleCrearCategory();
    };

    const updateSort = (e) => {
        setSort(e.target.value);
    };

    const getSearchedData = useMemo(
        () =>
            debounce(async (search) => {
                getCategories(search);
            }, 500),
        []
    );

    useEffect(() => {
        getCategories();
    }, [sort]);

    useEffect(() => {
        getCategories();
    }, [updateType]);

    useEffect(() => {
        getSearchedData(query);
    }, [query]);

    useEffect(() => {
        if (Object.keys(error)?.length) {
            validation();
        }
    }, [error]);

    /** Bulk Data **/
    const handleFileChange = (e) => {
        if (e.target.files.length) {
            setBulkCategories({ ...bulkCategories, error: "", ele: "" });
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                // setError("Please input a csv file");
                setBulkCategories({
                    ...bulkCategories,
                    error: "Please input a csv file",
                });
                return;
            }
            // setFile(inputFile);
            // if (!file) return setBulkCategories({ ...bulkCategories, error: "Enter a valid file" });
            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, { header: true });
                setBulkCategories({
                    ...bulkCategories,
                    bulkData: csv.data.slice(0, csv.data.length - 1),
                    error: "",
                });
            };
            reader.readAsText(inputFile);
        }
    };

    const hendleCrearCategory = () => {
        setcategoryError({
            ...categoryError,
            commission: "",
            discount: "",
            image: "",
            name: "",
        });
    };

    useEffect(() => {
        if (bulkCategories.bulkData.length != 0) {
            // setError({})
            hendleCrearCategory();
        }
    }, [bulkCategories]);

    const approveAdmin = async (ele, id) => {
        setAprove({ ...aprove, status: ele, index: id })
        // Path Params
        const categoryId = id
        const status = ele
        try {
            const res = await putTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + `/visiblity/${categoryId}?activated=${status}`)
            if (res.status == 200) {
                if (res?.data?.status == 'activated') {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                if (res?.data?.status == 'disactivated') {
                    toast.error(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                }
                getCategories()
            }
        } catch (err) {
            toast.error(err?.response?.data?.message, {
                position: "top-center",
                autoClose: 2000,
            })
            getCategories()
        }
    }


    const handleSubmitImage = async () => {
        if (imageData?.image != '') {
            const formData = new FormData()
            formData.append('image', imageData?.image)
            formData.append('_id', imageData.id._id)
            try {
                setImageData({ ...imageData, loader: true })
                const res = await putTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + SUB_CATEGORY_IMAGE, formData, { "Content-Type": "multipart/form-data" })
                if (res.status == 200) {
                    toast.success(res?.data?.message, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    getCategories()
                    setImageData({ ...imageData, image: "", loader: false })
                    setModal(false)
                }
            } catch (err) {
                toast.error(err?.response?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                })
                getCategories()
                setImageData({ ...imageData, image: "", loader: false })
                setModal(false)
            }
        } else {
            setImageDataError({
                ...imageDataError,
                image: "NO"
            })
        }
    }
    const handleUpload = () => {
        document.getElementById('upload-image').click()
    }

    useEffect(() => {
        getCategories()
    }, [])

    return (
        <MainTemplate
            categoryName="Subcategories Image Upload"
            categoryList={["Categories", "Subcategories Image Upload"]}
        >
            <div className="row mb-4 rounded p-2 bg-white">
                <div className="col-sm-6 my-3 col-md-2">
                    <Link to='/upload-category-image-list' className="text-decoration-none" >  <p className="tab-item" style={{ color: '#12352F', width: '100px' }} >Category</p></Link>
                </div>
                <div className="col-sm-6 my-3 col-md-2 bats-past-current">
                    <Link to='/upload-sub-image-list' className="text-decoration-none" > <p className="togle-nave nav-link activev" style={{ color: '#12352F', width: '120px' }} >Subcategory</p></Link>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={updateSort}
                    >
                        <option value="" disabled={true} selected>
                            Sort by
                        </option>
                        <option value="name">Alphabatically</option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={(e) => setItemsPerPage(e.target.value)}
                    >
                        <option value="10" selected>
                            10 per page
                        </option>
                        <option value="20">20 per page</option>
                        <option value="50">50 per page</option>
                        <option value="100">100 per page</option>
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-3">
                    <input
                        type="text"
                        name="query"
                        className="t-12 form-control"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="d-none d-md-block col-md-1"></div>
                {/* <div className="col-sm-6 my-3 col-md-2">
                    <button
                        type="button"
                        className="t-12 w-100 btn btn-outline-dark"
                        onClick={() => {
                            setModal(true);
                            setCategory({});
                        }}
                    >
                        Add Category
                    </button>
                </div> */}
                {/* <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn btn-outline-dark"
            onClick={() => {
              setModal1(true);
              setcategory({});
            }}
          >
            Add Images
          </button>
        </div> */}
            </div>

            <div className="row flex-column align-items-center">
                <div className="row  rounded text-center bg-white">
                    {/* header */}
                    <div className="row m-0 ">
                        {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Photo
                    </div> */}
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Sub Category Name
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Discount
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Commission
                        </div>
                        <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Updates
                        </div>
                        {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                        Sub category count
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Edit
                    </div>
                    <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                        Active{" "}/{" "}Inactive
                    </div> */}
                    </div>

                    {/* body */}
                    <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
                        {currentItems?.map((el) => (
                            <div
                                className="row border rounded m-0 mb-2 d-flex align-items-center"
                                key={el?._id} >
                                {/* <div className="t-12 text-uppercase my-3 col-md-1">
                                <div className="categoryImageDiv shadow-sm  rounded p-1">
                                    <Link
                                        className="text-dark"
                                        to={`/category/subcategory/${el?._id}`}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <img
                                            src={el?.image}
                                            className="categoryImage h-100 rounded"
                                        />
                                    </Link>
                                </div>
                            </div> */}
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                    <Link
                                        className="text-dark"
                                        to={`/category/subcategory/${el?._id}`}
                                        style={{ textDecoration: "none" }}
                                    >
                                        {el?.name}
                                    </Link>
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                    {el?.discount}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                    {el?.commission}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                    <div>
                                        <button
                                            // className="t-12 w-100 btn btn-unupload-green-color"
                                            className="t-12 w-50 btn text-warning"
                                            style={{ backgroundColor: "#12352f" }}
                                            onClick={() => { setImageData({ ...imageData, id: el }); setModal(true) }}>Upload Image</button>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {loader == true && (
                            <div className="text-center mt-5">
                                <button
                                    class="btn mt-5"
                                    style={{ backgroundColor: "#FFEDC2" }}
                                    type="button"
                                >
                                    <span
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>{" "}
                                    Loading...
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="row m-2 d-flex justify-content-center align-items-center">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item mb-0"
                    subContainerClassName="pages "
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
            {/* Catgory model */}
            <Modal
                isOpen={modal}
                onRequestClose={closeCategoryModal}
                style={custombulkModalStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p className="bulkuploadmodelheader">
                                {imageData?.id?.name}
                            </p>
                            <p className="bulkuploadmodelsubheader">
                                Upload a file
                            </p>
                        </div>
                        <p className="bulkuploadchoosefile">Browse files</p>
                    </div>
                    <div>
                        <input
                            type="file"
                            id="upload-image"
                            name="pan"
                            onChange={(e) => {
                                setImageData({
                                    ...imageData,
                                    image: e.target.files[0]
                                })
                                setImageDataError({
                                    ...imageDataError,
                                    image: 'YES'
                                })
                            }
                            }
                            className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                        />
                        <div className={`d-flex justify-content-center align-items-center   ${imageDataError.image == 'YES' && 'bulkuploadoption-success'}   ${imageDataError.image == 'NO' && 'bulkuploadoption-danger'}  ${imageDataError.image == '' && 'bulkuploadoption-success'} p-5`}  >
                            {
                                imageData.image
                                    ?
                                    <div className="" >
                                        <div
                                            className="image-containerr-category"
                                            onClick={() => {
                                                setImageData({ ...imageData, image: "" })
                                                setImageDataError({
                                                    ...imageDataError,
                                                    imageData: 'NO'
                                                })
                                            }}
                                        >
                                            <img width='510px' height='190px' style={{ borderRadius: "15px" }}
                                                src={URL.createObjectURL(imageData?.image)} />
                                        </div>
                                    </div>
                                    :
                                    <div onClick={handleUpload}>
                                        <div className="bulkuploadsinput">
                                            <BsUpload className=""></BsUpload>
                                        </div>
                                        <p className="bulkuploadinputtag">Upload files here</p>
                                    </div>
                            }
                        </div>
                        <div className="d-flex justify-content-center align-items-center m-4">
                            <div>
                                <button type="button" class="btn-unupload-green-color ms-2" onClick={handleSubmitImage}>
                                    Submit{" "}
                                    {
                                        imageData?.loader
                                            ?
                                            <Spinner
                                                animation="border"
                                                variant="light"
                                                size="sm"
                                            />
                                            :
                                            ''
                                    }
                                </button>
                            </div>
                            <div style={{ marginLeft: "15px" }} >
                                <button type="button" onClick={() => { setModal(false); setImageData({ ...imageData, image: '', id: '', loader: false }); setImageData({ ...imageData, image: '', id: '', loader: false }); setImageDataError({ ...imageDataError, image: '' }) }} class="bulkuploadbutton-cancel">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


        </MainTemplate >
    );
}

export default UploadSubImageList;
