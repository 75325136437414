import React, { useEffect, useState } from "react";
import "../Otp/Otp.css";
import Container from "react-bootstrap/Container";
import { Row, Col, Form, FormControl, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { putApi } from "../../api_interface/apis";
import { toast } from "react-toastify";
import { HIBEE_AUTH_URL, REACT_SEND_OTP_API_PATH, REACT_VERIFY_OTP_API_PATH } from "../../api_interface/apiURLs";

function Otp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { phone, type } = location?.state

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderResend, setLoaderResend] = useState(false);

  const validation = () => {
    if (otp && otp?.length === 4) {
      setError("");
      return true;
    } else {
      setError("Invalid OTP");
      return false;
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validation()) {
      try {
        if (phone) {
          await putApi(
            HIBEE_AUTH_URL + REACT_VERIFY_OTP_API_PATH +
            `?otp=${otp}&phone=${phone}&type=${type}`
          );
          toast.success("OTP verified", {
            position: "top-center",
            autoClose: 2000,
          });
          navigate("/newPassword", { state: { phone } });
        }
      } catch (err) {
        const message = err?.response?.data?.message || "Something went wrong";
        toast.error(message, { position: "top-center", autoClose: 2000 });
      }
    }
    setLoader(false);
  };

  const handleReset = async () => {
    if (phone) {
      setLoaderResend(true)
      try {
        if (phone) {
          const responceData = await putApi(HIBEE_AUTH_URL + REACT_SEND_OTP_API_PATH + `?phone=${phone}&type=${type}`);
          if (responceData.status == 200) {
            setLoaderResend(false)
            toast.success(`OTP : ${responceData.data.otp}  sent successfully`, {
              position: "top-center",
              autoClose: 2000,
            });
          }
        }
      } catch (err) {
        setLoaderResend(false)
        const message = err?.response?.data?.message || "Something went wrong";
        toast.error(message, { position: "top-center", autoClose: 2000 });
      }
    }
  }
  useEffect(() => {
    setError("");
  }, [otp]);

  return (
    <div className="main-div">
      <Container fluid>
        <Row className="pt-5">
          <Col xs lg="3.5" className=""></Col>
          <Col xs lg="5" className="g-0 ">
            <div className="first-box text-center">
              <div className="d-flex justify-content-center">
                <div className="hibee-logo mt-5"></div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <p className="first-text">Enter OTP</p>
              </div>

              <div className="d-flex justify-content-center">
                <p className="second-text">
                  Check your phone number for the OTP
                </p>
              </div>

              <Form onSubmit={submit}>
                <div className="d-flex justify-content-center">
                  <div className="second-boxd mt-3">
                    <div>
                      <div>
                        <Form.Group>
                          <Form.Control
                            type="number"
                            placeholder="enter otp here"
                            className="form-control p-3 remove-spinner"
                            value={otp}
                            name="otp"
                            onWheelCapture={(e)=>e.target.blur()}

                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                                setOtp(e.target.value)
                              }
                            }}
                            isInvalid={error}
                            autoComplete="off"
                            maxLength={4}
                            onInput={(e) => {
                              if (e.target.value.length > e.target.maxLength) {
                                e.target.value = e.target.value.slice(
                                  0,
                                  e.target.maxLength
                                );
                              }
                            }}
                          />
                          <FormControl.Feedback
                            type="invalid"
                            className="text-start"
                          >
                            {error}
                          </FormControl.Feedback>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <span className="third-text">
                        Did you receive?
                        <span className="fourth-text" onClick={handleReset} >{" "}Resend{" "}{
                          loaderResend &&
                          <span class="spinner-border spinner-border-sm" role="status"></span>
                        }</span>
                      </span>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <button
                        className="login-button"
                        type="submit"
                      >
                        Submit {" "}
                        {
                          loader
                            ?
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            :
                            ''
                        }
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Col>
          <Col xs lg="3.5" className=""></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Otp;
