import React, { useState } from "react";
import "./Login.css";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Row, Col, Form, Container, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { postApi } from "../../api_interface/apis";
import { checkPasswordValidity } from "../../utils/helperFunctin";
import { toast } from "react-toastify";
import {
  HIBEE_AUTH_URL,
  REACT_LOGIN_API_PATH,
} from "../../api_interface/apiURLs";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { addUser } from "../../redux/reducers/userSlice";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEDC2",
    border: "1px solid #ced4da",
    fontSize: 20,
    fontWeight: 700,
    color: "#12352f",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      color: "#12352f",
      fontFamily: "Inter",
      backgroundColor: "#FFEDC2",
      fontWeight: 700,
      fontSize: 20,
    },
  },
}));

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [userData, setUserData] = useState({
    phone: "",
    type: "superadmin",
    password: "",
  });
  const [error, setError] = useState({
    phone: "",
    type: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [PasswordVisible, setPasswordVisible] = useState("no");

  const handleChange = (e) => {
    if (e.target.type == 'number') {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
        setUserData({ ...userData, [e.target.name]: e.target.value });
        setError({ ...error, [e.target.name]: null });
      }
    } else {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: null });
    }
  };

  const validation = () => {
    const errorObj = {};
    if (!userData?.type) {
      errorObj.type = "Select Login Type";
    }
    if (!userData?.phone.trim()) {
      errorObj.phone = "Invalid Phone";
    } else if (userData?.phone && userData?.phone.length != 10) {
      errorObj.phone = " Phone number should be 10 digits";
    }
    if (!(userData?.password && checkPasswordValidity(userData?.password))) {
      errorObj.password =
        "Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number";
    }
    return errorObj;
  };

  const submit = async (e) => {
    e.preventDefault();
    const validationObject = validation();
    if (Object.keys(validationObject)?.length > 0) {
      setError(validationObject);
    } else {
      setLoader(true);
      if (validation()) {
        try {
          const data = await postApi(
            HIBEE_AUTH_URL + REACT_LOGIN_API_PATH,
            userData
          );
          const accessToken = data?.headers?.["x-access-token"];
          const refreshToken = data?.headers?.["x-refresh-token"];
          localStorage.setItem("x-access-token", accessToken);
          localStorage.setItem("x-refresh-token", refreshToken);

          const user = data?.data?.data;
          dispatch(addUser(user));
          toast.success("Login Success", {
            position: "top-center",
            autoClose: 2000,
          });
          // navigate("/categories");
        } catch (err) {
          const message =
            err?.response?.data?.message || "Something went wrong";
          toast.error(message, { position: "top-center", autoClose: 2000 });
        }
      }
      setLoader(false);
    }
  };

  const handlePasswordVisible = () => {
    setPasswordVisible("no");
  };
  const handlePasswordNotVisible = () => {
    setPasswordVisible("yes");
  };

  return (
    <div className="main-div">
      <Container fluid>
        <Row className="pt-5">
          <Col xs lg="3.5" className=""></Col>
          <Col xs lg="5" className="g-0 ">
            <div className="first-box">
              <div className="d-flex justify-content-center">
                <div className="hibee-logo mt-5"></div>
              </div>
              <div className=" d-flex justify-content-center">
                <div className="second-boxd mt-4">
                  <div className="mb-4">
                    <h2>Welcome, SuperAdmin</h2>
                  </div>
                  <form onSubmit={submit}>
                    <div>
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="label-style">
                          Phone number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter your phone number"
                          className="form-control p-3 remove-spinner"
                          value={userData.phone}
                          name="phone"
                          onChange={handleChange}
                          autoComplete="off"
                          onWheelCapture={(e)=>e.target.blur()}

                          maxLength={10}
                          onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength) {
                              e.target.value = e.target.value.slice(
                                0,
                                e.target.maxLength
                              );
                            }
                          }}
                          isInvalid={error?.phone}
                        />
                        {Boolean(error?.phone) && (
                          <p className="text-danger">{error.phone}</p>
                        )}
                      </Form.Group>
                    </div>
                    <div className="d-flex">
                      <div className="col-md-12">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="label-style">
                            Password
                          </Form.Label>
                          <Form.Control
                            type={
                              PasswordVisible == "yes" ? "text" : "password"
                            }
                            className="form-control p-3"
                            placeholder="Enter your password"
                            name="password"
                            onChange={handleChange}
                            value={userData.password}
                            isInvalid={error?.password}
                          />
                          {Boolean(error?.password) && (
                            <p className="text-danger">{error.password}</p>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col mt-5">
                        <div className="mt-2">
                          {PasswordVisible == "no" && (
                            <div
                              onClick={handlePasswordNotVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "0px",
                              }}
                            >
                              <AiOutlineEyeInvisible
                                size={20}
                              ></AiOutlineEyeInvisible>
                            </div>
                          )}
                          {PasswordVisible == "yes" && (
                            <div
                              onClick={handlePasswordVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "0px",
                              }}
                            >
                              <AiOutlineEye size={20}></AiOutlineEye>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <p className="forget-text text-end mt-5">
                      <Link to="/forget-password" style={{ color: "#7D7D7D" }}>
                        {" "}
                        Forgot password ?
                      </Link>
                    </p>
                    <div className="d-flex justify-content-center">
                      <button className="login-button">
                        Log In {" "}
                        {
                          loader
                            ?
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                            :
                            ''
                        }
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Col>
          <Col xs lg="3.5" className=""></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
