import React, { useState, useEffect, useMemo } from "react";
import MainTemplate from "../../components/MainTemplate";
import "../Society/societylisting.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import Modal from "react-modal";
import { Button, Form, Spinner } from "react-bootstrap";
import { FRANCHISE_LIST_API_PATH, HIBEE_PRODUCT_URL, SOCIETY_LIST } from "../../api_interface/apiURLs";
import { deleteTokenApi, getApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";


export default function SocietyListing() {
  const location = useLocation()
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [bulkCategories, setBulkCategories] = useState({
    bulkData: [],
    show: false,
    error: "",
  });
  const [categories, setCategories] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [societyData, setSocietyData] = useState({ societyName: "", addline1: "", addline2: "", landMark: "", city: "", state: "", pincode: "", latitude: "", longitude: "", franchiseName: "", loader: false })
  const [societyDataError, setSocietyDataError] = useState({ societyName: "", addline1: "", addline2: "", landMark: "", city: "", state: "", pincode: "", latitude: "", longitude: "", franchiseName: "" })
  const [societyList, setSocietyList] = useState([])
  const [updateType, setUpdateType] = useState(true)
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState("");



  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(societyList.length / itemsPerPage);
  const currentItems = societyList.slice(itemOffset, endOffset);
  const [loader, setLoader] = useState(false);
  const [societyModal, setSocietyModal] = useState(false || location?.state?.show);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % societyList.length;
    setItemOffset(newOffset);
  };

  /** Society Listing  API **/
  const handleGetSocietyLists = async (search, updateType, sort) => {
    setLoader(true);
    const one = localStorage.getItem('societyStatus')
    const two = localStorage.getItem('societySort')
    await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + `?sort=${two ? two : 'name'}&search=${search || query || ""}&status=${one}`)
      .then((ele, ind) => {
        if (ele.status == 200) {
          const data = ele.data.data
          const revData = data.reverse()
          setSocietyList(revData);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (ele) => {
    if (ele.target.type == 'number') {
      const inputValue = ele.target.value;
      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
        setSocietyData({ ...societyData, [ele.target.name]: ele.target.value })
        setSocietyDataError({ ...societyDataError, [ele.target.name]: null })
      }
    }
    else {
      setSocietyData({ ...societyData, [ele.target.name]: ele.target.value })
      setSocietyDataError({ ...societyDataError, [ele.target.name]: null })
    }
  }
  const handleValidation = () => {
    const { societyName, addline1, addline2, landMark, city, state, pincode, franchiseName } = societyData
    let newErrors = {}
    if (!societyName) newErrors.societyName = 'please enter society name'
    if (!addline1) newErrors.addline1 = 'please enter address line1'
    if (!addline2) newErrors.addline2 = 'please enter address line2'
    if (!landMark) newErrors.landMark = 'please enter land mark'
    if (!city) newErrors.city = 'please enter city'
    if (!state) newErrors.state = 'please enter state'
    if (!pincode) newErrors.pincode = 'please enter pincode'
    else if (pincode.length != 6) newErrors.pincode = 'pincode  should be 6 digits'
    // if (!franchiseName) newErrors.franchiseName = 'select franchise'
    return newErrors
  }

  const handleSubmit = async (ele) => {
    ele.preventDefault()
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setSocietyDataError(handleValidationObject)
    } else {
      try {
        const payload =
        {
          societyName: societyData?.societyName,
          addressLine1: societyData?.addline1,
          addressLine2: societyData?.addline2,
          city: societyData?.city,
          state: societyData?.state,
          pinCode: societyData?.pincode,
          // active: true,
          lat: societyData.latitude,
          long: societyData.longitude,
          landmark: societyData.landMark
          // towers: [
          //   {
          //     towerName: "Tower Alpha", "floors": [
          //       { "floorName": "1", "active": true },
          //       { "floorName": "2", "active": true },
          //       { "floorName": "3", "active": true }
          //     ]
          //   },
          //   {
          //     towerName: "Tower Beta", "floors": [
          //       { floorName: "1", "active": true },
          //       { floorName: "2", "active": true },
          //       { floorName: "3", "active": true }
          //     ]
          //   }
          // ],
          // villas: [{ villaName: "Villa A" }, { villaName: "Villa B" }, { villaName: "Villa C" }]
        }

        setSocietyData({ ...societyData, loader: true })
        const responce = await postTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST, payload)
        if (responce.status == 200) {
          localStorage.setItem('societyStatus', true)
          localStorage.setItem('societyeSort', 'name')
          toast.success(responce?.data?.message, { position: "top-center", autoClose: 2000 });
          setSocietyModal(false)
          handleCleareState()
          handleGetSocietyLists()
        }
      } catch (error) {
        localStorage.setItem('societyStatus', true)
        localStorage.setItem('societyeSort', 'name')
        const message = error?.response?.data?.message || "Something went wrong";
        toast.error(message, { position: "top-center", autoClose: 2000 });
        handleCleareState()
        setSocietyModal(false)
        handleGetSocietyLists()
      }
    }
  }

  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: ele, index: id })
    // Path Params
    const adminId = id
    const status = ele
    localStorage.setItem('societyStatus', status)
    try {
      const res = await putTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + `/${adminId}/${status}`)
      if (res.status == 200) {
        const newStatus = localStorage.getItem('societyStatus')
        localStorage.setItem('societyStatus',  newStatus == 'true' ? false : true)
        if (res?.data?.status == 'approved') {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.status == 'disapproved') {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        handleGetSocietyLists()
      }
    } catch (err) {
      const newStatus = localStorage.getItem('societyStatus')
      localStorage.setItem('societyStatus',  newStatus == 'true' ? false : true)
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      })
      handleGetSocietyLists()
    }
  }

  const handleCleareState = () => {
    setSocietyData({
      ...societyData,
      societyName: "",
      addline1: "",
      addline2: "",
      landMark: "",
      city: "",
      state: "",
      pincode: "",
      latitude: "",
      longitude: "",
      franchiseName: "",
      loader: false
    })
    setSocietyDataError({
      ...societyDataError,
      societyName: "",
      addline1: "",
      addline2: "",
      landMark: "",
      city: "",
      state: "",
      pincode: "",
      latitude: "",
      longitude: "",
      franchiseName: "",
      loader: false
    })
  }

  /*  Spciety Delete API */
  const handleDelete = async (id) => {
    try {
      const societyId = id._id
      const res = await deleteTokenApi(HIBEE_PRODUCT_URL + `/society/${societyId}`)
      if (res.status == 200) {
        handleGetSocietyLists()
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updateSort = e => {
    localStorage.setItem('societySort', e.target.value)
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
    handleGetSocietyLists(search, updateType, sort);
  }, 500), []);

  useEffect(() => {
    getSearchedData(query, updateType, sort);
  }, [query, updateType, sort]);


  useEffect(() => {
    handleGetSocietyLists()
  }, [])


  /** filter **/
  useEffect(() => {
    localStorage.setItem('societyStatus', true)
  }, [])

  useEffect(() => {
    const data = localStorage.getItem('societyStatus')
    setUpdateType(data)
  }, [updateType])

  return (
    <MainTemplate
      categoryName="Society"
      categoryList={[ "Society"]}
    >
      {/* sorting */}
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={updateSort}
          >
            <option value="" selected>
              Sort by
            </option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>+
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
          // onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10" selected>
              10 per page
            </option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example"
            onChange={e => {
              setUpdateType(e.target.value === "true" ? true : false)
              localStorage.setItem('societyStatus', e.target.value === "true" ? true : false)
            }}
          >
            <option value={true} selected>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn text-warning"
            onClick={() => { setSocietyModal(true) }}
            style={{ backgroundColor: "#12352f" }}
          >
            Add Society
          </button>
        </div>
      </div>

      {/* table */}
      <div className="row flex-column align-items-center">
        <div className="row rounded text-center bg-white">
          {/* header */}
          <div className="row m-0 ">
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Society name
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
              Address
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Tower count
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Villa Count
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
              Edit
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Active{" "}/{" "}Inactive
            </div>
          </div>

          {/* body */}
          <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
            {
              currentItems?.map((ele, ind) => {
                return <div
                  className="row border rounded m-0 mb-2 d-flex align-items-center"
                >
                  <div className="t-12 text-uppercase my-3 col-md-2">
                    <Link
                      className="text-dark"
                      // to={`/category/subcategory/`}
                      style={{ textDecoration: "none" }}
                    >
                      {ele?.societyName}
                    </Link>
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-3">
                    {ele?.addressLine1}
                  </div>
                  <div className="t-12 text-uppercase my-3 col-md-2">{ele?.towers?.length}</div>
                  <div className="t-12 text-uppercase my-3 col-md-2">{ele?.villas?.length}</div>
                  <div className="t-12 text-uppercase my-3 col-md-1">
                    <Link to="/society-details" state={ele} className="t-12 text-uppercase my-3 col-md-2">
                      <svg
                        className="bi bi-pencil border-bottom border-dark text-dark cursorPointer"
                        // onClick={() => {
                        //   setSocietyModal(true);
                        //   setcategory(el);
                        // }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </Link>
                  </div>

                  {/* <div className="t-12 text-uppercase my-3 col-md-1 cursorPointer">
                    <div class="dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        data-menu-target={`drop-down-menu-$`}
                        className="bi bi-three-dots dropdown-btn cursorPointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                      <div id={`drop-down-menu-$`} className="dropdown-content p-2 mobile-dropdown" >
                        <div
                          className="px-3 py-2 d-flex cursorPointer"
                        // onClick={() =>
                        //   setActionModal({
                        //     ...actionModal,
                        //     show: true,
                        //     data: el,
                        //     type: "DELETE",
                        //   })
                        // }
                        >
                          <span className="mx-2 d-flex align-items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                            </svg>
                          </span>
                          <span className="mx-2">
                            <div className="font-weight-bold">Delete</div>
                            <div className="">Delete this product</div>
                          </span>
                        </div>
                        <div className="px-3 py-2 d-flex cursorPointer" onClick={() => setActionModal({ ...actionModal, show: true, data: el, type: "ARCHIEVE" })}>
                          <span className="mx-2 d-flex align-items-center">
                            <svg width="16" height="16" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M2.44444 22C1.77222 22 1.19656 21.7609 0.717444 21.2826C0.239148 20.8034 0 20.2278 0 19.5556V4.27778C0 3.97222 0.0509259 3.69722 0.152778 3.45278C0.25463 3.20833 0.387037 2.97407 0.55 2.75L2.26111 0.672222C2.42407 0.448148 2.62778 0.279889 2.87222 0.167445C3.11667 0.0558149 3.38148 0 3.66667 0H18.3333C18.6185 0 18.8833 0.0558149 19.1278 0.167445C19.3722 0.279889 19.5759 0.448148 19.7389 0.672222L21.45 2.75C21.613 2.97407 21.7454 3.20833 21.8472 3.45278C21.9491 3.69722 22 3.97222 22 4.27778V19.5556C22 20.2278 21.7609 20.8034 21.2826 21.2826C20.8034 21.7609 20.2278 22 19.5556 22H2.44444ZM2.93333 3.66667H19.0667L18.0278 2.44444H3.97222L2.93333 3.66667ZM2.44444 6.11111V19.5556H19.5556V6.11111H2.44444ZM11 18.3333L15.8889 13.4444L14.1778 11.7333L12.2222 13.6889V8.55556H9.77778V13.6889L7.82222 11.7333L6.11111 13.4444L11 18.3333ZM2.44444 19.5556H19.5556H2.44444Z" fill="#666666" />
                            </svg>
                          </span>
                          <span className="mx-2">
                            <div className="font-weight-bold">Archive</div>
                            <div className="">Archive this product</div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="t-12 text-uppercase my-3 col-md-2">
                    <div className="m-auto" >
                      <label className="switch">
                        <input className="switch-input" type="checkbox"
                          checked={ele.active}
                          onChange={e => e.target.checked ? approveAdmin(e.target.checked, ele?._id) : approveAdmin(e.target.checked, ele?._id)}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                    {/* <button onClick={() => handleDelete(ele)} className="btn btn-primary" >Delete</button> */}
                  </div>

                </div>
              })
            }

            {loader == true && (
              <div className="text-center mt-5">
                <button
                  class="btn mt-5"
                  style={{ backgroundColor: "#FFEDC2" }}
                  type="button"
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Loading...
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <Modal
        isOpen={societyModal}
        onRequestClose={() => setSocietyModal(false)}
        style={{ ...customModalStyles, content: { ...customModalStyles?.content, width: "60vw" } }}
        contentLabel="Example Modal"
        ariaHideApp={false}

      // isOpen={modal}
      // onRequestClose={() => setActionModal({})}
      // style={customActionModalStyles}
      // contentLabel="Action Modal"
      // ariaHideApp={false}
      >
        <div className="px-5 py-3">
          <div className="row ">
            <h4>Add Society Form</h4>
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => {
                  setSocietyModal(false)
                  handleCleareState()
                }}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row m-0 p-0 pe-2 overflow-auto caterogyPageScroll">
            <div className="form-group my-2">
              <label className=""> Society Name <span className="text-danger">*</span></label>
              <input
                type="text"
                autoComplete="off"
                name="societyName"
                onChange={handleChange}
                value={societyData.societyName}
                className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.societyName ? 'border-danger' : ""}`}
                placeholder="Enter society name here..."
              />
              <span className="text-danger">{societyDataError.societyName}</span>
            </div>
            <div className="form-group my-2">
              <label className="">  Society Address <span className="text-danger">*</span></label>
              <input
                name="addline1"
                type="text"
                autoComplete="off"
                onChange={handleChange}
                value={societyData.addline1}
                className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.addline1 ? 'border-danger' : ""}`}
                placeholder="Address Line 1"
              />
              <span className="text-danger">{societyDataError.addline1}</span>
              <input
                name="addline2"
                onChange={handleChange}
                value={societyData.addline2}
                autoComplete="off"
                type="text"
                className={`placeholder-light form-control my-2 py-2 px-3  ${societyDataError.addline2 ? 'border-danger' : ""}`}
                placeholder="Address Line 2"
              />
              <span className="text-danger">{societyDataError.addline2}</span>
              <div className="d-flex justify-content-between">
                <div className="col-6" style={{ paddingRight: '5px' }} >
                  <input
                    name="landMark"
                    onChange={handleChange}
                    value={societyData.landMark}
                    type="text"
                    autoComplete="off"
                    className={`placeholder-light form-control my-2 py-2 px-3  ${societyDataError.landMark ? 'border-danger' : ""}`}
                    placeholder="Landmark / Locality"
                  />
                  <span className="text-danger">{societyDataError.landMark}</span>
                </div>
                <div className="col-6" style={{ paddingRight: '5px', marginLeft: '2px' }}>
                  <input
                    name="city"
                    onChange={handleChange}
                    value={societyData.city}
                    type="text"
                    autoComplete="off"
                    className={`placeholder-light form-control my-2 py-2 px-3 ${societyDataError.city ? 'border-danger' : ""}`}
                    placeholder="City"
                  />
                  <span className="text-danger">{societyDataError.city}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-6" style={{ paddingRight: '5px' }}>
                  <input
                    name="state"
                    onChange={handleChange}
                    value={societyData.state}
                    type="text"
                    autoComplete="off"
                    className={`placeholder-light form-control  my-2 py-2 px-3 ${societyDataError.state ? 'border-danger' : ""}`}
                    placeholder="State"
                  />
                  <span className="text-danger">{societyDataError.state}</span>
                </div>
                <div className="col-6" style={{ paddingRight: '5px', marginLeft: '2px' }} >
                  <input
                    name="pincode"
                    autoComplete="off"
                    onChange={handleChange}
                    type="number"
                    onWheelCapture={(e)=>e.target.blur()}

                    value={societyData.pincode}
                    className={`placeholder-light form-control my-2 py-2 px-3 remove-spinner ${societyDataError.pincode ? 'border-danger' : ""}`}
                    placeholder="Pincode"
                    maxLength={6}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      }
                    }}
                  />
                  <span className="text-danger">{societyDataError.pincode}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between py-2">
                <div className="col-6" style={{ paddingRight: '5px' }}>
                  <label className="">Latitude</label>
                  <input
                    name="latitude"
                    onChange={handleChange}
                    value={societyData.latitude}
                    type="text"
                    autoComplete="off"
                    className={`placeholder-light form-control  my-2 py-2 px-3`}
                    placeholder="Latitude"
                  />
                </div>
                <div className="col-6" style={{ paddingRight: '5px', marginLeft: '2px' }} >
                  <label className="">Longitude</label>
                  <input
                    name="longitude"
                    autoComplete="off"
                    onChange={handleChange}
                    type="text"
                    value={societyData.longitude}
                    className={`placeholder-light form-control my-2 py-2 px-3`}
                    placeholder="Longitude"
                  />
                </div>
              </div>
              <span className="text-danger"></span>
            </div>
            {/* <div className="col mb-3">
              <Form.Label>Select Franchise <span className="text-danger">*</span></Form.Label>
              <Form.Select
                className="Textbar"
                controlId="Product category"
                type="text"
                placeholder="Select Franchise"
                name="franchiseName"
                onChange={handleChange}
                value={societyData.franchiseName}
                className={`${societyDataError.franchiseName ? "border-danger" : ""}`}
                autoComplete="off"
              >
                <option selected disabled> Select Franchise Name </option>
              </Form.Select>
              <span className="text-danger">{societyDataError.societyName}</span>
            </div> */}
          </div>

          {
            // true
            //   ?
            //   <div>
            //     <p className="d-flex justify-content-center">or</p>
            //     <div>
            //       <h6>Uploading many Societies at once?</h6>
            //       <p className="select-text-light">
            //         Upload a file in a .csv format{" "}
            //         <span
            //           className="text-success fw-bold"
            //           role="button"
            //         // onClick={() => downloadFile(BulkCategoryCsv)}
            //         >
            //           Download sample format here
            //         </span>
            //       </p>
            //       {/* Bulk Upload */}
            //       <input
            //         name="file"
            //         type="file"
            //         autoComplete="off"
            //         accept=".csv"
            //         // onChange={handleFileChange}
            //         // value={bulkCategories.ele}
            //         className={`py-2 opacity0 position-absolute  w-25 placeholder-light form-control py-2 px-3 border`}
            //         placeholder="eg: https://www.apple.com/in/watch"
            //       />

            //       <div className="d-flex">
            //         <div
            //           className={`my-2 col-5 py-2 select-text-light w-25 d-flex align-items-center px-3 border rounded ${bulkCategories?.error != "" ? "border-danger" : ""
            //             }`}
            //         >
            //           <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width="16"
            //             height="16"
            //             fill="currentColor"
            //             class="bi bi-cloud-arrow-up"
            //             viewBox="0 0 16 16"
            //           >
            //             <path
            //               fill-rule="evenodd"
            //               d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
            //             />
            //             <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
            //           </svg>
            //           <span className="px-2">Upload</span>
            //         </div>
            //         <div className="col-1"></div>
            //         <div className="col-6 form-group my-2">
            //           <button className="btn btn-dark text-warning px-5">
            //             {" "}
            //             Preview
            //           </button>
            //         </div>
            //       </div>
            //       <div>
            //         {bulkCategories.bulkData.length != 0 ? (
            //           <div
            //             className="bg-danger rounded categoryImageDiv shadow-sm "
            //             style={{ width: "80px", height: "50px" }}
            //           >
            //             <div
            //               className="image-containerr-excell"
            //               onClick={() =>
            //                 setBulkCategories({
            //                   ...bulkCategories,
            //                   bulkData: [],
            //                   error: "",
            //                   ele: "",
            //                 })
            //               }
            //             ></div>
            //             {/* <img src={excell} className="h-100 rounded" /> */}
            //           </div>
            //         ) : (
            //           ""
            //         )}
            //       </div>
            //     </div>
            //   </div>
            //   :
            //   ''
          }

          <div className="form-group my-3">
            <button
              disabled={societyData.loader}
              className="btn btn-dark text-warning px-5"
              onClick={handleSubmit}
              style={{ backgroundColor: "#12352f" }}
            >
              Submit{" "}
              {
                societyData?.loader
                  ?
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                  :
                  ''
              }

            </button>
          </div>
        </div>
      </Modal>
    </MainTemplate >
  );
}
