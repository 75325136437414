import React from "react";
import { Route, Routes, BrowserRouter as Router, Navigate } from "react-router-dom";
import ForgetPassword from "../pages/ForgetPassword/ForgetPassword";
import Login from "../pages/Login/Login";
import NewPassword from "../pages/NewPassword/NewPassword";
import Otp from "../pages/Otp/Otp";
import Orders from "../pages/Orders/Orders";
import Inventory from "../pages/Inventory/Inventory";
import InventoryProductAddForm from "../pages/InventoryProductAddForm/InventoryProductAddForm";
import HomepageModifierAppSections from "../pages/HomepageModifier/HomepageModifierAppSection"
import HomepageModifierCategory from "../pages/HomepageModifier/HomepageModifierCategory";
import HomepageModifierCategoryInternal from "../pages/HomepageModifier/HomepageModifierCategoryInternal";
import HomepageModifierAdvertisement from "../pages/HomepageModifier/HomepageModifierAdvertisement";
import Notification from "../pages/Notification/Notification";
import ProductList from "../pages/Product-Price/ProductList";
import FranchiseDetails from "../pages/FranchiseDetails/FranchiseDetails";
import AdminForm from "../pages/AdminForm/AdminForm";
import AdminDetail from "../pages/AdminDetail/AdminDetail";
import FranchiseRequestDetails from "../pages/FranchiseRequestDetails/FranchiseRequestDetails";
import NewOfferForm from "../pages/NewOfferForm/NewOfferForm";
import FeedbackInternal from "../pages/FeedbackInternal/FeedbackInternal";
import { useSelector } from "react-redux";
import MainTemplate from "../components/MainTemplate";
import OfferInternal from "../pages/OfferInternal/OfferInternal";
import FranchiseListing from "../pages/FranchiseListing/FranchiseListing";
import FranchiseRequestListning from "../pages/FranchiseRequestListning/FranchiseRequestListning";
import AdminList from "../pages/AdminList/AdminList";
import OfferListing from "../pages/OffersListing/OfferListing";
import NotificationList from "../pages/NotificationList/NotificationList";
import ProductEdit from "../pages/Product-Price/ProductEdit";
import FeedbackList from "../pages/FeedbackList/FeedbackList";
import AdvertismentList from "../pages/AdvertisementList/AdvertismentList";
import InventoryList from "../pages/InventoryList/InventoryList";
import UserListing from "../pages/UserListing/UserListing";
import NewFranchise from "../pages/NewFranchise/NewFranchise.js"
import OrderDetails from "../pages/Orders/OrderDetails";
import CategoryDetail from '../pages/CategoryDetail/CategoryDetail.js'
import CategoryPage from '../pages/CategoryPage/CategoryPage.js'
import ProductPrice from "../pages/Product-Price/ProductPrice";
import SocietyListing from "../pages/Society/SocietyListing";
import SocietyDetails from "../pages/Society/SocietyDetails";
import SocietyInternal from "../pages/Society/SocietyInternal";
import Addinventory from "../pages/AddInventory/Addinventory";
import UploadImageList from "../pages/UploadImageList/UploadImageList";
import UploadSubImageList from "../pages/UploadSubImageList/UploadSubImageList";
import FranchiseCodRequest from "../pages/FranchiseCodRequest/FranchiseCodRequest";
import Custominventory from "../pages/CustomInventory/Custominventory";
import CustominventoryHistory from "../pages/CustomInventory/CustominventoryHistory";
import UploadCategoryImageList from "../pages/UploadCategoryImageList/UploadCategoryImageList";
import CustomInventoryList from "../pages/CustomInventory/CustomInventoryList";
import ProductImageUpload from "../pages/Product-Price/ProductImageUpload";
import HomepageModifierAddedProducts from "../pages/HomepageModifier/HomepageModifierAddedProducts";
import HomepageModifierProductsListing from "../pages/HomepageModifier/HomepageModifierProductsListing";
import HomepageModifierNativity from "../pages/HomepageModifier/HomepageModifierNativity";
import HomepageModifierAddProduct from "../pages/HomepageModifier/HomepageModifierAddProduct";
import HomepageModiferGroupsList from "../pages/HomepageModifier/HomepageModiferGroupsList";
import HomepageModifierCarouselAddBanners from "../pages/HomepageModifier/HomepageModifierCarouselAddBanners";
import CarouselBannerList from "../pages/HomepageModifier/CarouselBannerList";
import CarouselListingPage from "../pages/HomepageModifier/CarouselListingPage";
import HomepageModifierGroupProductsList from "../pages/HomepageModifier/HomepageModifierGroupProductsList";
import AdminAccounts from "../pages/AdminAccounts/AdminAccounts";



function ScreenRouting() {
  const user = useSelector((state) => state.userReducer);

  return !user?._id ? (
    <Router>
      <Routes>
        {/* Onboarding */}
        <Route path="/Otp" element={<Otp />}></Route>
        <Route path="/forget-password" element={<ForgetPassword />}></Route>
        <Route path="/" element={<Login />}></Route>
        <Route path="/newPassword" element={<NewPassword />}></Route>
        <Route path="*" element={<Navigate to="/" />}></Route>
      </Routes>
    </Router>
  ) : (
    <Router>
      <Routes>
        {/* Super Admin Screens */}
        <Route path="/categories" element={<CategoryPage />}></Route>
        <Route path="/upload-image-list" element={<UploadImageList />}></Route>
        <Route path="/upload-category-image-list" element={<UploadCategoryImageList />}></Route>
        <Route path="/upload-sub-image-list" element={<UploadSubImageList />}></Route>
        <Route path="/category/subcategory/:categoryId" element={<CategoryDetail />} ></Route>
        <Route path="/franchise" element={<NewFranchise />} />
        <Route path="/franchise-cod-request" element={<FranchiseCodRequest />} />

        <Route path="/orders" element={<Orders />} />
        <Route path="/order-details/:orderId" element={<OrderDetails />} />
        <Route path="/" element={<Inventory />} />
        <Route path="/inventory-product-add" element={<InventoryProductAddForm />} />
        <Route path="/inventory-list" element={<InventoryList />} />

        {/* homepage modifiers screens */}
        {/* <Route path="/homepage-modifier" element={<HomepageModifier />} /> */}
        <Route path="/homepage-modifier/sections" element={<HomepageModifierAppSections />} />
        <Route path="/homepage-modifier-carousel-add-banners" element={<HomepageModifierCarouselAddBanners />} />

        <Route path="/homepage-modifier/category" element={<HomepageModifierCategory />} />
        <Route path="/carousel-banner-list" element={<CarouselBannerList />} />
        <Route path="/homepage-modifier/add/product" element={<HomepageModifierAddProduct />} />
        <Route path="/homepage-modifier/categories/:categoryId" element={<HomepageModifierCategoryInternal />} />
        <Route path="/homepage-modifier/advertisement" element={<AdvertismentList />} />
        {/* <Route path="/homepage-modifier-products" element={<HomepageModifierProducts />} /> */}

        <Route path="/franchise-listing" element={<FranchiseListing />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:subCategoryId" element={<ProductList />} />
        <Route path="/product/:id" element={<ProductPrice />} />
        <Route path="/franchise-details/:franchiseId" element={<FranchiseDetails />} />
        <Route path="/admin-form" element={<AdminForm />} />
        <Route path="/admin-list" element={<AdminList />} />
        <Route path="/admin/:adminId" element={<AdminDetail />} />
        <Route path="/productedit" element={<ProductEdit />} />
        <Route path="/franchise-request-details" element={<FranchiseRequestDetails />}></Route>
        <Route path="/new-offer-form/:id" element={<NewOfferForm />}></Route>
        <Route path="/Franchise-request-listning" element={<FranchiseRequestListning />}></Route>
        <Route path="/offer-internal/:offerId" element={<OfferInternal></OfferInternal>}></Route>
        <Route path="/offer-listing" element={<OfferListing></OfferListing>} ></Route>
        <Route path="/notification-listing" element={<NotificationList></NotificationList>}></Route>
        <Route path="/advertisment-list" element={<AdvertismentList></AdvertismentList>} ></Route>
        <Route path="/homepage-modifier-advertisement" element={<HomepageModifierAdvertisement></HomepageModifierAdvertisement>} ></Route>HomepageModifierAdvertisement
        <Route path="/user-listing" element={<UserListing />}></Route>
        <Route path="/feedback-list" element={<FeedbackList />} />
        <Route path="/fedback-internal/:feedbackId" element={<FeedbackInternal />} />
        <Route path="/admin-accounts" element={<AdminAccounts />} />
        <Route path="/society-listing" element={<SocietyListing />} />
        <Route path="/society-details" element={<SocietyDetails />} />
        <Route path="/society-internal" element={<SocietyInternal />} />
        <Route path="/addinventory" element={<Addinventory />} />
        <Route path="/custominventory" element={<Custominventory />} />
        <Route path="/custominventoryhistory" element={<CustominventoryHistory />} />
        <Route path="/custominventorylist" element={<CustomInventoryList />} />
        <Route path="/productimageupload" element={<ProductImageUpload />} />
        <Route path="/homepagemodifieraddedproducts" element={<HomepageModifierAddedProducts />} />
        <Route path="/homepagemodifierproductslisting" element={<HomepageModifierProductsListing />} />
        <Route path="/Homepage-modifier-group-products-list" element={<HomepageModifierGroupProductsList />} />
        <Route path="/homepagemodifiernativity" element={<HomepageModifierNativity />} />
        <Route path="/carousellistingpage" element={<CarouselListingPage />} />
        <Route path="/homepage-modifer-groups-list" element={<HomepageModiferGroupsList />} />
      </Routes>
    </Router>
  );
}

export default ScreenRouting;
