import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useRef } from "react";
import { Row, Col, Form, Container, Button, Spinner } from "react-bootstrap";
import "./NewFranchise.css";
import placeholder from "../../assets/images/placeholder.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MainTemplate from "../../components/MainTemplate";
import { CREATE_FRANCHISE_API_PATH, FLOORS_LIST, HIBEE_PRODUCT_URL, SOCIETY_LIST, UPDATE_FRANCHISE_API_PATH } from "../../api_interface/apiURLs";
import { getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { checkPasswordValidity, emailValidity } from "../../utils/helperFunctin";
import imagenotavailable from '../../assets/images/imagenotavailable.png'


function NewFranchise() {
  const location = useLocation()
  const navigagation = useNavigate();
  const [aadharImage, setAadharImage] = useState("");
  const [panImage, setpanImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [PasswordVisible, setPasswordVisible] = useState("no");

  const [selectedTowerIDs, setSelectedTowerIDs] = useState([]);
  const [selectedVillaIDs, setSelectedVillaIDs] = useState([]); // 
  // console.log(selectedTowerIDs, selectedVillaIDs, ">>>>>>>>>>");
  const [loader, setLoader] = useState(false);
  // const editFranchise = location?.state?.data
  const editFranchise = location?.state
  const [franchiseData, setFranchiseData] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
    confirmPassword: "",
    towerVillaType: "",
    society: [],
    tower: [],
    villa: [],
    floor: [],
    selectedSocietyObject: [],
    selectedTowerObject: [],
    selectedVillaObject: [],
    selectedFloorObject: [],
    selectedTowerOrVilla: '',
    towersAssigned: [],
    villasAssigned: [],
    flat: "",
    address1: "",
    // address2: '',
    state: "",
    city: "",
    pincode: "",
    aadhar: "",
    aadhar_number: "",
    pan: "",
    pan_number: "",
    cod_status: false,
    minimum_cod_amount: "10000",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    accountType: "",
    towerVillaType: "",
    referred_by: "",
    profile: "",
    _id: ""
  });
  console.log(franchiseData?.tower, "franchiseData?.tower");
  // console.log(franchiseData?.villasAssigned, "franchiseData?.villasAssigned");

  // console.log(franchiseData?.selectedSocietyObject, "selectedSocietyObject");
  const [franchiseDataError, setFranchiseDataError] = useState({
    name: "",
    number: "",
    email: "",
    password: "",
    confirmPassword: "",
    society: "",
    tower: "",
    villa: "",
    floor: "",
    selectedSocietyObject: '',
    selectedTowerObject: '',
    selectedVillaObject: '',
    selectedFloorObject: '',
    selectedTowerOrVilla: '',
    flat: "",
    // address2: '',
    state: "",
    city: "",
    pincode: "",
    aadhar: "",
    aadhar_number: "",
    pan: "",
    pan_number: "",
    cod_status: false,
    minimum_cod_amount: "",
    bankName: "",
    accountHolderName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
    accountType: "",
    referred_by: "",
    profile: "",
    _id: ""
  });

  const [societyList, setSocietyList] = useState([])
  const [towerList, setTowerList] = useState([])
  const [VillaList, setVillaList] = useState([])
  const [floorsList, setFloorsList] = useState([])
  console.log(towerList, "towerList");
  const [accountType, setAccountType] = useState({
    data: [
      // { id: '1', accountType: 'Select account type', value: '' },
      { id: '2', accountType: 'Saving', value: 'Saving' },
      { id: '3', accountType: 'Current', value: 'Current' },
    ],
    error: false
  })

  const [selectedTower, setSelectTower] = useState([])

  // console.log(selectedTower, 'selectedTower');
  const [selectedVillaOptions, setSelectedVillaOptions] = useState([]);
  const [selectedVilla, setSelectVilla] = useState([])

  // console.log(selectedVilla, 'selectedVilla');

  /** Society Listing  API **/
  const handleGetSocietyLists = async (search) => {
    await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST
      + `${!franchiseData?._id ? "" : '?onboard==true'}`
      // + `?onboard=${true}`
    )
      .then((ele, ind) => {
        if (ele.status == 200) {
          const data = ele.data.data
          const revData = data.reverse()
          setSocietyList(revData);
          // setFranchiseData({
          //   ...franchiseData,
          //   society: revData
          // })
        }
      })
      .catch((error) => {
      });
  };

  const handlePasswordVisible = () => {
    setPasswordVisible("no");
  };
  const handlePasswordNotVisible = () => {
    setPasswordVisible("yes");
  };

  const handleSelectFiled = (ele) => {
    document.getElementById(ele).click();
  };

  /** haandle change **/
  const handleFranchisechange = (event) => {
    if (event.target.type == 'number') {
      const inputValue = event.target.value;
      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
        if (
          event.target.name != "aadhar" &&
          event.target.name != "pan" &&
          event.target.name != "profile"
        ) {
          setFranchiseData({
            ...franchiseData,
            [event.target.name]: event.target.value,
          });
          setFranchiseDataError({
            ...franchiseDataError,
            [event.target.name]: null,
          });
        }

        if (event.target.name == "aadhar") {
          var url = URL.createObjectURL(event.target.files[0]);
          setAadharImage(url);
          setFranchiseData({
            ...franchiseData,
            [event.target.name]: event.target.files[0],
          });
          setFranchiseDataError({
            ...franchiseDataError,
            [event.target.name]: null,
          });
        }
        if (event.target.name == "pan") {
          var url = URL.createObjectURL(event.target.files[0]);
          setpanImage(url);
          setFranchiseData({
            ...franchiseData,
            [event.target.name]: event.target.files[0],
          });
          setFranchiseDataError({
            ...franchiseDataError,
            [event.target.name]: null,
          });
        }
        if (event.target.name == "profile") {
          var url = URL.createObjectURL(event.target.files[0]);
          setProfileImage(url);
          var url = URL.createObjectURL(event.target.files[0]);
          setFranchiseData({
            ...franchiseData,
            [event.target.name]: event.target.files[0],
          });
          setFranchiseDataError({
            ...franchiseDataError,
            [event.target.name]: null,
          });
        }
        if (event.target.name == "society") {
          const selectedSocietyName = event.target.value;
          const selectedSocietyObject = societyList.find((el) => el.societyName == selectedSocietyName);
          // console.log(selectedSocietyObject, "selectedSocietyObject");
          setFranchiseData({
            ...franchiseData,
            society: selectedSocietyObject ? selectedSocietyObject : []
          });
          setTowerList(selectedSocietyObject ? selectedSocietyObject?.tower : []);
          setVillaList(selectedSocietyObject ? selectedSocietyObject?.villa : []);
        }

        if (event.target.name == "tower") {
          const selectedTowerName = event.target.value;
          const selectedTowerObject = towerList?.find((el) => el.name == selectedTowerName);
          setFranchiseData({
            ...franchiseData,
            tower: selectedTowerObject ? selectedTowerObject : [],
            towerVillaType: 'TOWER',
            villa: [],
          });
          setFloorsList(selectedTowerObject ? selectedTowerObject?.floors : [])
          // setVillaList([])
          // setTimeout(() => {
          //   setVillaList(franchiseData?.society?.villa)
          // }, [1000])
        }
        if (event.target.name == "villa") {
          const selectedVillaName = event.target.value;
          const selectedVillaObject = VillaList.find((el) => el.name == selectedVillaName);
          setFranchiseData({
            ...franchiseData,
            villa: selectedVillaObject ? selectedVillaObject : [],
            towerVillaType: 'VILLA',
            tower: [],
            flat: "",
            floor: []
          });
          // setTowerList([])
          // setTimeout(() => {
          //   setTowerList(franchiseData?.society?.tower)
          // }, [1000])
        }

        if (event.target.name == "floor") {
          const selectedFloorName = event.target.value;
          const selectedFloorObject = floorsList.find((el) => el.floorName == selectedFloorName);
          setFranchiseData({
            ...franchiseData,
            floor: selectedFloorObject ? selectedFloorObject : []
          });
        }
      }
    } else {
      if (
        event.target.name != "aadhar" &&
        event.target.name != "pan" &&
        event.target.name != "profile"
      ) {
        setFranchiseData({
          ...franchiseData,
          [event.target.name]: event.target.value,
        });
        setFranchiseDataError({
          ...franchiseDataError,
          [event.target.name]: null,
        });
      }

      if (event.target.name == "aadhar") {
        var url = URL.createObjectURL(event.target.files[0]);
        setAadharImage(url);
        setFranchiseData({
          ...franchiseData,
          [event.target.name]: event.target.files[0],
        });
        setFranchiseDataError({
          ...franchiseDataError,
          [event.target.name]: null,
        });
      }
      if (event.target.name == "pan") {
        var url = URL.createObjectURL(event.target.files[0]);
        setpanImage(url);
        setFranchiseData({
          ...franchiseData,
          [event.target.name]: event.target.files[0],
        });
        setFranchiseDataError({
          ...franchiseDataError,
          [event.target.name]: null,
        });
      }
      if (event.target.name == "profile") {
        var url = URL.createObjectURL(event.target.files[0]);
        setProfileImage(url);
        var url = URL.createObjectURL(event.target.files[0]);
        setFranchiseData({
          ...franchiseData,
          [event.target.name]: event.target.files[0],
        });
        setFranchiseDataError({
          ...franchiseDataError,
          [event.target.name]: null,
        });
      }
      if (event.target.name == "society") {
        const selectedSocietyName = event.target.value;
        const selectedSocietyObject = societyList.find((el) => el.societyName == selectedSocietyName);
        setFranchiseData({
          ...franchiseData,
          society: selectedSocietyObject ? selectedSocietyObject : []
        });
        setTowerList(selectedSocietyObject ? selectedSocietyObject?.towers : []);
        setVillaList(selectedSocietyObject ? selectedSocietyObject?.villas : []);
      }

      if (event.target.name == "tower") {
        const selectedTowerName = event.target.value;
        const selectedTowerObject = towerList?.find((el) => el.name == selectedTowerName);
        setFranchiseData({
          ...franchiseData,
          tower: selectedTowerObject ? selectedTowerObject : [],
          towerVillaType: 'TOWER',
          villa: [],
        });
        setFloorsList(selectedTowerObject ? selectedTowerObject?.floors : [])
        // setVillaList([])
        // setTimeout(() => {
        //   setVillaList(franchiseData?.society?.villa)
        // }, [1000])
      }
      if (event.target.name == "villa") {
        const selectedVillaName = event.target.value;
        const selectedVillaObject = VillaList.find((el) => el?.name == selectedVillaName);
        setFranchiseData({
          ...franchiseData,
          villa: selectedVillaObject ? selectedVillaObject : [],
          towerVillaType: 'VILLA',
          tower: [],
          flat: "",
          floor: []
        });
        // setTowerList([])
        // setTimeout(() => {
        //   setTowerList(franchiseData?.society?.tower)
        // }, [1000])
      }

      if (event.target.name == "floor") {
        const selectedFloorName = event.target.value;
        const selectedFloorObject = floorsList.find((el) => el.floorName == selectedFloorName);
        setFranchiseData({
          ...franchiseData,
          floor: selectedFloorObject ? selectedFloorObject : []
        });
      }
    }

  };



  /**Handle Validation **/
  const handleValidation = () => {
    const newErrors = {};
    const {
      name,
      number,
      email,
      password,
      confirmPassword,
      towerVillaType,
      society,
      tower,
      villa,
      floor,
      flat,
      selectedSocietyObject,
      selectedTowerObject,
      selectedVillaObject,
      selectedFloorObject,
      selectedTowerOrVilla,
      aadhar,
      aadhar_number,
      pan,
      pan_number,
      cod_status,
      minimum_cod_amount,
      bankName,
      accountHolderName,
      accountNumber,
      ifscCode,
      branchName,
      accountType,
      referred_by,
      profile,
    } = franchiseData;

    // const numberic = /^\d+$/;
    const numberic = /^[0-9]+$/;
    const textType = /^[a-zA-Z ]+$/


    // if (code == '') {
    //   newErrors.code = 'Please enter unique code'
    // }
    if (name.trim() == "") {
      newErrors.name = "Please enter owner name";
    }

    if (number == "") {
      newErrors.number = "Please enter phone number";
    } else if (number && number.length != 10) {
      newErrors.number = "Phone number should be 10 number";
    } else if (number && !numberic.test(number)) {
      newErrors.number = "Please enter valid phone number";
    }

    if (email == "") {
      newErrors.email = "Please enter phone email id";
    } else if (email && !emailValidity(email)) {
      newErrors.email = "Enter valid email";
    }

    if (franchiseData?._id == '') {
      if (password == "") {
        newErrors.password = "Please enter password";
      } else if (password && !checkPasswordValidity(password)) {
        newErrors.password =
          "Password must contain min 8 letter, 1 special character, 1 upper and 1 lower case and a number";
      }
      if (confirmPassword == "") {
        newErrors.confirmPassword = "Please enter confirm Password";
      } else if (password !== confirmPassword) {
        newErrors.confirmPassword =
          "Password and confirm password both should be same";
      }
    }

    if (society.length == 0) {
      newErrors.society = "Please select society";
    }

    if (towerVillaType == "TOWER") {
      if (tower.length == 0) {
        newErrors.tower = "Please select tower";
      }
      if (floor.length == 0) {
        newErrors.floor = "Please select floor";
      }
      if (flat == '') {
        newErrors.flat = "Please enter flat number";
      }
    }

    if (towerVillaType == "VILLA") {
      if (villa.length == 0) {
        newErrors.villa = "Please select villa";
      }
    }

    if (aadhar == "") {
      newErrors.aadhar = "Please upload aadhar card";
    }
    if (aadhar_number == "") {
      newErrors.aadhar_number = "Please enter aadhar number";
    } else if (aadhar_number && !numberic.test(aadhar_number)) {
      newErrors.aadhar_number = "Please enter valid aadhar number";
    }

    if (pan == "") {
      newErrors.pan = "Please upload aadhar card";
    }
    if (pan_number.trim() == "") {
      newErrors.pan_number = "Please enter pan number";
    }

    // if (cod_status == false) {
    //   newErrors.cod_status = "Please select cod status";
    // }

    if (cod_status == true && minimum_cod_amount == "") {
      newErrors.minimum_cod_amount = "Please enter minimum cod ammount";
    } else if (cod_status == true && minimum_cod_amount && !numberic.test(minimum_cod_amount)) {
      newErrors.minimum_cod_amount = "Please enter valid minimum cod ammount";
    }

    if (bankName == "") {
      newErrors.bankName = "Please enter bank name";
    } else if (bankName && !textType.test(bankName)) {
      newErrors.bankName = "Please enter text";
    }

    if (accountHolderName.trim() == "") {
      newErrors.accountHolderName = "Please enter account holder name";
    }
    if (accountNumber == "") {
      newErrors.accountNumber = "Please enter account number";
    } else if (accountNumber && !numberic.test(accountNumber)) {
      newErrors.accountNumber = "Please enter valid account number";
    }

    if (ifscCode == "") {
      newErrors.ifscCode = "Please enter ifsc code";
    }

    // if (branchName == "") {
    //   newErrors.branchName = "Please enter branch name";
    // }

    if (accountType == "") {
      newErrors.accountType = "Please select account type";
    }

    // if (referred_by == "") {
    //   newErrors.referred_by = "Please enter referred_by";
    // }

    // if (profile == "") {
    //   newErrors.profile = "Please select profile image";
    // }

    return newErrors;
  };




  /** handle Submit **/
  const handleSubmitFranchise = async (ele) => {
    // console.log(franchiseData.towersAssigned, 'towersAssigned');

    // console.log(franchiseData.towersAssigned,'towersAssigned');

    var finalTowersAssigned = []
    franchiseData.towersAssigned.map((ele, ind) => {
      finalTowersAssigned.push(ele._id)
    })

    var finalVillasAssigned = []
    franchiseData.villasAssigned.map((ele, ind) => {
      finalVillasAssigned.push(ele._id)
    })


    // console.log(finalTowersAssigned, 'finalTowersAssigned');
    // console.log(finalVillasAssigned, 'finalVillasAssigned');


    // console.log(franchiseData.villasAssigned, 'villasAssigned');

    ele.preventDefault();
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setFranchiseDataError(handleValidationObject);
    } else {
      const {
        name,
        number,
        email,
        password,
        society,
        tower,
        villa,
        floor,
        towerVillaType,
        selectedSocietyObject,
        selectedTowerObject,
        selectedVillaObject,
        selectedTowerOrVilla,
        selectedFloorObject,
        flat,
        address1,
        address2,
        state,
        city,
        pincode,
        aadhar,
        aadhar_number,
        pan,
        pan_number,
        cod_status,
        minimum_cod_amount,
        bankName,
        accountHolderName,
        accountNumber,
        ifscCode,
        branchName,
        accountType,
        referred_by,
        profile,
        _id
      } = franchiseData;
      const formData = new FormData();
      // console.log("i am here nwo ??");
      formData.append("owner_name", name);
      formData.append("phone_number", number);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("society", society?._id);
      // formData.append("towerVillaType", towerVillaType); // VILLA
      formData.append("towersAssigned", selectedTowerIDs ? JSON.stringify(selectedTowerIDs) : "");
      formData.append("villasAssigned", selectedVillaIDs ? JSON.stringify(selectedVillaIDs) : "");
      formData.append("tower", towerVillaType == 'TOWER' ? JSON.stringify(tower) : '');
      formData.append("villa", towerVillaType == 'VILLA' ? JSON.stringify(villa) : '');
      formData.append("floor", towerVillaType == 'TOWER' ? JSON.stringify(floor) : '');
      formData.append("flatNumber", towerVillaType == 'TOWER' ? flat : '');
      // formData.append("towersAssigned", finalTowersAssigned ? JSON.stringify(finalTowersAssigned) : '');
      // formData.append("villasAssigned", finalVillasAssigned ? JSON.stringify(finalVillasAssigned) : '');


      // console.log("i am here nwo >>>>");
      formData.append("adhar_img", aadhar);
      formData.append("adhar_number", aadhar_number);
      formData.append("pan_img", pan);
      formData.append("pan_number", pan_number?.toLocaleUpperCase());
      formData.append("profile_img", profile);
      formData.append("cod_status", cod_status);

      if (cod_status == true) {
        formData.append("maximum_COD_amount", minimum_cod_amount);
      }
      // console.log("i am here nwo >>");
      formData.append("bank_name", bankName);
      formData.append("account_holder_name", accountHolderName);
      formData.append("account_number", accountNumber);
      formData.append("ifsc_code", ifscCode?.toLocaleUpperCase());
      // formData.append("bank_branch", branchName);
      formData.append("account_type", accountType);
      // formData.append("referred_by", referred_by);


      if (franchiseData?._id) formData.append("_id", _id);
      if (franchiseData?._id) {
        localStorage.setItem('franchiseStatus', true)
        localStorage.setItem('franchiseSort', 'name')
        setLoader(true);
        const res = await putTokenApi(HIBEE_PRODUCT_URL + UPDATE_FRANCHISE_API_PATH, formData, { "Content-Type": "multipart/form-data" })
        if (res.status == 200) {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 2000,
          });
          setLoader(false);
          setTimeout(() => {
            navigagation("/franchise-listing");
          }, 1000);
        } else {
          toast.error(res.data.message, {
            position: "top-center",
            autoClose: 2000,
          });
          setLoader(false);
          setTimeout(() => {
            navigagation("/franchise-listing");
          }, 1000);
        }
      } else {
        try {
          const res = await postTokenApi(HIBEE_PRODUCT_URL + CREATE_FRANCHISE_API_PATH, formData, { "Content-Type": "multipart/form-data" });
          if (res.status == 200) {
            toast.success(res.data.message, {
              position: "top-center",
              autoClose: 2000,
            });
            localStorage.setItem('franchiseStatus', true)
            localStorage.setItem('franchiseSort', 'name')
            setLoader(false);
            setTimeout(() => {
              navigagation("/franchise-listing");
            }, 1000);
          } else {
            toast.error(res.data.message, {
              position: "top-center",
              autoClose: 2000,
            });
            setLoader(false);
            setTimeout(() => {
              navigagation("/franchise-listing");
            }, 1000);
          }
        } catch (err) {
          const message = err?.response?.data?.message || "Something went wrong";
          toast.error(message, { position: "top-center", autoClose: 2000 });
          localStorage.setItem('franchiseStatus', true)
          localStorage.setItem('franchiseSort', 'name')
        }
      }
    }
  };

  const [selectedTowerOptions, setSelectedTowerOptions] = useState([]);


  useEffect(() => {
    if (editFranchise) {
      // console.log(editFranchise, 'editFranchise')
      setTowerList(editFranchise?.society?.towers)
      setVillaList(editFranchise?.society?.villas)
      setSelectedTowerIDs(editFranchise?.towersAssigned.map((tower)=> tower?._id))
      setSelectedVillaIDs(editFranchise?.villasAssigned.map((villa)=> villa?._id))
      setSelectedTowerOptions(editFranchise?.towersAssigned.map((tower)=> tower?.name))
      setSelectedVillaOptions(editFranchise?.villasAssigned.map((villa)=> villa?.name))
      const selectedFloor = editFranchise?.society?.towers?.find((el, ind) => el.name === editFranchise?.tower?.name)
      setFloorsList(selectedFloor?.floors)
      setFranchiseData({
        ...franchiseData,
        name: editFranchise?.owner_name,
        number: editFranchise?.phone_number,
        email: editFranchise?.email,
        password: "",
        society: editFranchise?.society,
        tower: editFranchise?.tower,
        villa: editFranchise?.villa,
        floor: editFranchise?.floor,
        flat: editFranchise?.flatNumber,
        selectedSocietyObject: editFranchise?.society,
        address1: editFranchise?.address?.street,
        address2: "",
        towersAssigned: editFranchise?.towersAssigned,
        villasAssigned: editFranchise?.villasAssigned,
        state: editFranchise?.address?.state,
        city: editFranchise?.address?.city,
        pincode: editFranchise?.address?.zip,
        aadhar: editFranchise?.adhar_img,
        aadhar_number: editFranchise?.adhar_number,
        pan: editFranchise?.pan_img,
        pan_number: editFranchise?.pan_number,
        cod_status: editFranchise?.cod_status,
        minimum_cod_amount: editFranchise?.maximum_COD_amount,
        bankName: editFranchise?.bank_name,
        accountHolderName: editFranchise?.account_holder_name,
        accountNumber: editFranchise?.account_number,
        ifscCode: editFranchise?.ifsc_code,
        branchName: editFranchise?.bank_branch,
        accountType: editFranchise?.account_type,
        towerVillaType: editFranchise?.tower ? 'TOWER' : "",
        referred_by: editFranchise?.referred_by,
        profile: editFranchise?.profile_img,
        _id: editFranchise?._id,
      });

    }
  }, [editFranchise])


  useEffect(() => {
    handleGetSocietyLists()
  }, [])


  const handleSelectSociety = (ele) => {
    if (ele.target.value != '') {
      const foundSociety = societyList?.filter((el => el.societyName == ele.target.value))
      // setFranchiseData({ ...franchiseData, society: foundSociety[0]?.societyName })
      setFranchiseData({ ...franchiseData, selectedSocietyObject: foundSociety[0] })
      setFranchiseDataError({ ...franchiseDataError, selectedSocietyObject: '' })
      handleListTowers(foundSociety)
    } else {
      setFranchiseData({ ...franchiseData, selectedSocietyObject: [] })
    }
  }

  const handleListTowers = async (foundSociety) => {
    const societyId = foundSociety[0]._id
    const towersResponce = await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + FLOORS_LIST + `?societyId=${societyId}`)
    if (towersResponce.status == 200) {
      // setTowerList1({
      //   ...towerList1,
      //   tower: towersResponce.data.data.tower,
      //   villa: towersResponce.data.data.villa
      // })
    } else {

    }
  }

  const handleSelectTowerOrVilla = (ele) => {
    if (ele.target.value != '') {
      const parsingSelectedTower = JSON.parse(ele.target.value)
      if (parsingSelectedTower?.hasOwnProperty("name")) {
        setFranchiseData({
          ...franchiseData,
          selectedTowerObject: parsingSelectedTower,
          selectedVillaObject: [],
          towerVillaType: "TOWER",
          selectedTowerOrVilla: 'data'
        })
      } else {
        setFranchiseData({
          ...franchiseData,
          selectedTowerObject: [],
          selectedVillaObject: parsingSelectedTower,
          selectedFloorObject: [],
          flat: '',
          towerVillaType: "VILLA",
          selectedTowerOrVilla: 'data'
        })
      }
      setFranchiseDataError({
        ...franchiseDataError,
        selectedTowerOrVilla: ''
      })
    } else {
      setFranchiseData({
        ...franchiseData,
        selectedTowerObject: [],
        selectedTowerOrVilla: ''
      })
    }
  }

  const handleSelectFloor = (ele) => {
    if (ele.target.value != '') {
      const parsingSelectedFloor = JSON.parse(ele.target.value)
      setFranchiseData({
        ...franchiseData,
        selectedFloorObject: parsingSelectedFloor
      })
      setFranchiseDataError({
        ...franchiseDataError,
        selectedFloorObject: ''
      })
    } else {
      setFranchiseData({
        ...franchiseData,
        selectedFloorObject: []
      })
    }
  }

  // const [selectedVillaOptions, setSelectedVillaOptions] = useState([]);

  const [isDropdownTowerOpen, setIsDropdownTowerOpen] = useState(false);
  const [isDropdownVillaOpen, setIsDropdownVillaOpen] = useState(false);
  const [searchTowerTerm, setSearchTowerTerm] = useState('');
  const [searchVillaTerm, setSearchVillaTerm] = useState('');


  console.log(selectedTowerOptions, 'selectedTowerOptions');
  const towerDropdownRef = useRef(null);
  const villaDropdownRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        towerDropdownRef.current &&
        !towerDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownTowerOpen(false);
      }

      if (
        villaDropdownRef.current &&
        !villaDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownVillaOpen(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  // console.log(selectedTowerOptions, 'khkhkhk');
  // Tower Dropdown
  const toggleTowerDropdown = () => {
    setIsDropdownTowerOpen(!isDropdownTowerOpen);
  };

  // const toggleTowerOption = (value) => {
  //   if (selectedTowerOptions.includes(value.name)) {
  //     setSelectedTowerOptions(selectedTowerOptions.filter((item) => item !== value.name));
  //   } else {
  //     setSelectedTowerOptions([...selectedTowerOptions, value.name]);
  //   }
  // };

  // const toggleVillaOption = (value) => {
  //   if (selectedVillaOptions.includes(value.name)) {
  //     setSelectedVillaOptions(selectedVillaOptions.filter((item) => item !== value.name));
  //   } else {
  //     setSelectedVillaOptions([...selectedVillaOptions, value.name]);
  //   }
  // };

  const filteredTowerOptions = towerList?.filter((option) =>
    option.name.toLowerCase().includes(searchTowerTerm.toLowerCase())
  );
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsDropdownTowerOpen(false);
  //     }
  //   };

  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  // Villa Dropdown
  const toggleVillaDropdown = () => {
    setIsDropdownVillaOpen(!isDropdownVillaOpen);
  };
  const toggleTowerOption = (option) => {
    if (selectedTowerOptions.includes(option.name)) {
      setSelectedTowerOptions(selectedTowerOptions.filter((item) => item !== option.name));
      setSelectedTowerIDs(selectedTowerIDs.filter((_id) => _id !== option._id)); // Remove the ID
    } else {
      setSelectedTowerOptions([...selectedTowerOptions, option.name]);
      setSelectedTowerIDs([...selectedTowerIDs, option._id]); // Add the ID
    }
  };

  const toggleVillaOption = (option) => {
    if (selectedVillaOptions.includes(option.name)) {
      setSelectedVillaOptions(selectedVillaOptions.filter((item) => item !== option.name));
      setSelectedVillaIDs(selectedVillaIDs.filter((_id) => _id !== option._id)); // Remove the ID
    } else {
      setSelectedVillaOptions([...selectedVillaOptions, option.name]);
      setSelectedVillaIDs([...selectedVillaIDs, option._id]); // Add the ID
    }
  };



  const filteredVillaOptions = VillaList?.filter((option) =>
    option?.name?.toLowerCase().includes(searchVillaTerm.toLowerCase())
  );

  // console.log(VillaList, 'VillaList');
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsDropdownVillaOpen(false);
  //     }
  //   };

  //   window.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     window.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);


  return (
    <MainTemplate
      categoryName="Add Franchise"
      categoryList={[`${editFranchise?.owner_name ? editFranchise?.owner_name : "Add Franchise"}`]}
    >
      <div>
        <div className="container containerbg overflow-auto rounded bg-white row w-100">
          <h5 className="text-center mt-4">Add Franchise Details</h5>
          <div className="row col-md-12 rowhight"> </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-5">
              <Form onSubmit={handleSubmitFranchise}>
                <Row>
                  <Form.Group className="mb-3" controlId="Owner Name">
                    <Form.Label>Owner Name <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Enter your name"
                      name="name"
                      isInvalid={franchiseDataError?.name}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                      value={franchiseData?.name}
                    />
                    <span className="text-danger">
                      {franchiseDataError.name}
                    </span>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="Phone Number">
                    <Form.Label>Phone Number  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar remove-spinner"
                      type="number"
                      placeholder="Enter your phone number hear"
                      name="number"
                      onChange={handleFranchisechange}
                      value={franchiseData?.number}
                      isInvalid={franchiseDataError?.number}
                      autoComplete="off"
                      maxLength={10}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength) {
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        }
                      }}
                      onWheelCapture={(e) => e.target.blur()}
                    />
                    <span className="text-danger">
                      {franchiseDataError.number}
                    </span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Email ID">
                    <Form.Label>Email ID  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar"
                      type=""
                      placeholder="Enter your email id hear"
                      name="email"
                      onChange={handleFranchisechange}
                      isInvalid={franchiseDataError?.email}
                      value={franchiseData?.email}
                      autoComplete="off"
                    />
                    <span className="text-danger">
                      {franchiseDataError.email}
                    </span>
                  </Form.Group>

                  {
                    franchiseData._id
                      ?
                      ""
                      :
                      <Form.Group className="mb-3" controlId="Email ID">
                        <Form.Label>Password  <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                          className="Textbar"
                          type="password"
                          placeholder="Enter your password hear"
                          name="password"
                          onChange={handleFranchisechange}
                          value={franchiseData?.password}
                          isInvalid={franchiseDataError?.password}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {franchiseDataError.password}
                        </span>
                      </Form.Group>
                  }
                  <div className="d-flex">
                    {
                      franchiseData._id
                        ?
                        ''
                        :
                        <Form.Group className="mb-3" controlId="Email ID">
                          <div className="col-12">
                            <Form.Label>Confirm Password  <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                              className="Textbar eye-icone"
                              placeholder="type your password hear"
                              name="confirmPassword"
                              onChange={handleFranchisechange}
                              isInvalid={franchiseDataError?.confirmPassword}
                              value={franchiseData?.confirmPassword}
                              autoComplete="off"
                              type={PasswordVisible == "yes" ? "text" : "password"}
                            />
                            <span className="text-danger">
                              {franchiseDataError.confirmPassword}
                            </span>
                          </div>
                        </Form.Group>
                    }
                    {
                      franchiseData._id
                        ?
                        ''
                        :
                        <div className="col">
                          {PasswordVisible == "no" && (
                            <div
                              onClick={handlePasswordNotVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "44px",
                              }}
                            >
                              <AiOutlineEyeInvisible
                                size={20}
                              ></AiOutlineEyeInvisible>
                            </div>
                          )}
                          {PasswordVisible == "yes" && (
                            <div
                              onClick={handlePasswordVisible}
                              style={{
                                position: "relative",
                                right: "30px",
                                top: "44px",
                              }}
                            >
                              <AiOutlineEye size={20}></AiOutlineEye>
                            </div>
                          )}
                        </div>

                    }
                  </div>
                  <Form.Group className="mb-3" controlId="Society/Community">
                    <Form.Label>Society/Community  <span className="text-danger">*</span></Form.Label>
                    <Form.Select
                      // className="Textbar"
                      controlId="Product category"
                      type="text"
                      placeholder="Enter select product category"
                      name="society"
                      className={`${franchiseDataError?.society ? "border-danger" : ""} Textbar`}
                      onChange={handleFranchisechange}
                      value={franchiseData?.society?.societyName || ''}
                      autoComplete="off"
                    >
                      <option value='' disabled> select society</option>
                      {
                        societyList?.map((el, index) => (
                          <option
                            key={index}
                            value={el?.societyName}
                            disabled={el?.frenchise_id ? true : false}
                          >
                            {el?.societyName}
                          </option>
                        ))
                      }
                    </Form.Select>
                    <span className="text-danger"> {franchiseDataError?.society} </span>
                  </Form.Group>

                  <div className="col mb-3">
                    <div>
                      <Form.Label>Select Tower or Villa{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                    <Form.Label>Tower
                      {/* <span className="text-danger">*</span> */}
                    </Form.Label>
                    <Form>
                      <Form.Select
                        controlId="Product category"
                        type="text"
                        placeholder="Enter select product category"
                        name="tower"
                        // className={`Textbar`}
                        className={`${franchiseDataError?.tower ? "border-danger" : ""} Textbar`}
                        onChange={handleFranchisechange}
                        autoComplete="off"
                        value={franchiseData?.tower?.towerName ? franchiseData?.tower?.towerName : franchiseData?.tower?.name || ''}
                      >
                        <option value='' disabled>Select tower</option>
                        {/* <optgroup value='Towers List' label="Towers List"> */}
                        {
                          towerList?.map((ele, ind) => {
                            return <option value={ele.name}  >{ele?.name}</option>
                          })
                        }
                        {/* </optgroup> */}
                      </Form.Select>
                    </Form>
                    <span className="text-danger"> {franchiseDataError?.tower} </span>
                  </div>


                  <div className="col mb-3">
                    OR
                  </div>

                  <div className="col mb-3">
                    <Form.Label>Villa
                      {/* <span className="text-danger">*</span> */}
                    </Form.Label>
                    <Form>
                      <Form.Select
                        controlId="Product category"
                        type="text"
                        placeholder="Enter select product category"
                        name="villa"
                        // className={`Textbar`}
                        className={`${franchiseDataError?.villa ? "border-danger" : ""} Textbar`}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        value={franchiseData.villa.name || ''}
                      >
                        <option value='' disabled >Select villa</option>
                        {/* <optgroup value='Towers List' label="Towers List"> */}
                        {
                          VillaList?.map((ele, ind) => {
                            return <option value={ele.name}  >{ele?.name}</option>
                          })
                        }
                        {/* </optgroup> */}
                      </Form.Select>
                    </Form>
                    <span className="text-danger"> {franchiseDataError.villa} </span>
                  </div>
                  {
                    franchiseData?.tower?.length != 0 && franchiseData?.towerVillaType == 'TOWER'
                      ?
                      <div>
                        <div className="col mb-3">
                          <Form.Label>Floor <span className="text-danger">*</span></Form.Label>
                          <Form.Select
                            // className="Textbar"
                            controlId="Product category"
                            type="text"
                            placeholder="Enter select product category"
                            name="floor"
                            className={`${franchiseDataError.floor ? "border-danger" : ""} Textbar`}
                            onChange={handleFranchisechange}
                            value={franchiseData.floor?.floorName}
                            autoComplete="off"
                          >
                            <option value='' > Select Floor</option>
                            {
                              floorsList?.map((el, index) => (
                                <option
                                  key={index}
                                  value={el.floorName}
                                >
                                  {el?.floorName}
                                </option>
                              ))
                            }
                          </Form.Select>
                          <span className="text-danger"> {franchiseDataError.floor} </span>
                        </div>

                        <Form.Group className="mb-3" controlId="Society/Community">
                          <Form.Label>Flat Number <span className="text-danger">*</span></Form.Label>
                          <Form.Control
                            className={`${franchiseDataError.flat ? "border-danger" : ""} Textbar`}
                            type="number"
                            placeholder="type your Flat Number hear"
                            name="flat"
                            onChange={handleFranchisechange}
                            value={franchiseData?.flat}
                            autoComplete="off"
                            onWheelCapture={(e) => e.target.blur()}
                          />
                          <span className="text-danger">{franchiseDataError.flat}</span>
                        </Form.Group>
                      </div>
                      :
                      ''
                  }
                  {/* <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Type your Address"
                      name="address1"
                      isInvalid={franchiseDataError?.address1}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                    />
                    <span className="text-danger">
                      {franchiseDataError.address1}
                    </span>
                  </Form.Group> */}
                </Row>
                {/* <Row>
                  <Form.Group className="mb-3" controlId="Address 2">
                    <Form.Label>Address line 2</Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Type your Address"
                      name='address2'
                      onChange={handleFranchisechange}
                      isInvalid={franchiseDataError?.address2}
                      autoComplete='off'
                    />
                    <span className="text-danger" >{franchiseDataError.address2}</span>
                  </Form.Group>
                </Row> */}
                {/* <Row>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="State">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        className="smtext"
                        type="text"
                        placeholder="type your State hear"
                        name="state"
                        onChange={handleFranchisechange}
                        isInvalid={franchiseDataError?.state}
                        autoComplete="off"
                      />
                      <span className="text-danger">
                        {franchiseDataError.state}
                      </span>
                    </Form.Group>
                  </div>
                  <div className="col-md-6 ">
                    <Form.Group className="mb-3" controlId="City">
                      <Form.Label className="margintext">City</Form.Label>
                      <Form.Control
                        className="smtext "
                        type="text"
                        placeholder="type your City hear"
                        name="city"
                        onChange={handleFranchisechange}
                        isInvalid={franchiseDataError?.city}
                        autoComplete="off"
                      />
                      <span className="text-danger" >
                        {franchiseDataError.city}
                      </span>
                    </Form.Group>
                  </div>
                </Row> */}

                {/* <Row>
                  <div className="col-md-6">
                    <Form.Group className="mb-3" controlId="Pin code">
                      <Form.Label>Pin code</Form.Label>
                      <Form.Control
                        className="smtext"
                        type="number"
                        placeholder="type your Pin code hear"
                        name="pincode"
                        onChange={handleFranchisechange}
                        isInvalid={franchiseDataError?.pincode}
                        autoComplete="off"
                        maxLength={6}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                        onWheelCapture={(e) => e.target.blur()}
                      />
                      <span className="text-danger">
                        {franchiseDataError.pincode}
                      </span>
                    </Form.Group>
                  </div>
                  <div></div>
                </Row> */}



                <div>
                  <div className="dropdown" ref={towerDropdownRef}>
                    <Form.Label>Assigned Towers  <span className="text-danger">*</span></Form.Label>
                    <div className="dropdown-button" style={{ textAlign: "start" }} onClick={toggleTowerDropdown}>
                      {selectedTowerOptions.length === 0 ? "Select Towers" : selectedTowerOptions.join(", ")}
                    </div>

                    {isDropdownTowerOpen && (
                      <div className="dropdown-options">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchTowerTerm}
                          onChange={(e) => setSearchTowerTerm(e.target.value)}
                          style={{ width: "445px" }}
                        />
                        {filteredTowerOptions?.map((option, ind) => (
                          <label key={ind}>
                            <input
                              type="checkbox"
                              value={option.name}
                              checked={selectedTowerOptions.includes(option.name)}
                              onChange={() => toggleTowerOption(option)}
                            />
                            {option.name}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="dropdown" ref={villaDropdownRef}>
                    <Form.Label>Assigned Villa  <span className="text-danger">*</span></Form.Label>
                    <div className="dropdown-button" style={{ textAlign: "start" }} onClick={toggleVillaDropdown}>
                      {selectedVillaOptions.length === 0 ? "Select Villa" : selectedVillaOptions.join(", ")}
                    </div>
                    {isDropdownVillaOpen && (
                      <div className="dropdown-options">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={searchVillaTerm}
                          onChange={(e) => setSearchVillaTerm(e.target.value)}
                          style={{ width: "445px" }}
                        />
                        {filteredVillaOptions?.map((option, ind) => (
                          <label key={ind}>
                            <input
                              type="checkbox"
                              value={option.name}
                              checked={selectedVillaOptions.includes(option.name)}
                              onChange={() => toggleVillaOption(option)}
                            />
                            {option.name}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>

                {franchiseData?.aadhar == "" ? (
                  <Row>
                    <Form.Group className="mb-3" controlId="Aadhar card">
                      <Form.Label>Aadhar card  <span className="text-danger">*</span></Form.Label>
                      <div
                        className="form-group py-2 position-relative mb-3 g-0"
                        onClick={() => handleSelectFiled("aadhar")}
                      >
                        {/* <input
                          type="file"
                          name="aadhar"
                          id="aadhar"
                          onChange={handleFranchisechange}
                          className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                        /> */}
                        <div className={`${franchiseDataError.aadhar && "border-danger"} adhar-box text-center`}>
                          <div className="mt-5">
                            <img src={placeholder} />{" "}
                            <span style={{ color: "#A3A3A3" }}>
                              Upload your Aadhar card here...
                            </span>
                          </div>
                        </div>
                        <span className="text-danger">
                          {franchiseDataError.aadhar}
                        </span>
                      </div>
                      <input
                        type="file"
                        name="aadhar"
                        id="aadhar"
                        onChange={handleFranchisechange}
                        className="opacity0 w-75 placeholder-light form-control py-2 imgheight px-3"
                      />
                    </Form.Group>
                  </Row>
                ) : (
                  <Row>
                    <Form.Label>Addhar Card  <span className="text-danger">*</span></Form.Label>
                    <div
                      className="adhar-box mb-3 g-0"
                      onClick={() => handleSelectFiled("aadhar")}
                      style={{ marginLeft: "14px" }}
                    >
                      <img
                        // src={aadharImage || franchiseData?.aadhar}
                        src={typeof franchiseData?.aadhar == 'string' ? franchiseData?.aadhar : franchiseData?.aadhar && URL.createObjectURL(franchiseData?.aadhar)}
                        width="448px" height="154px" style={{ objectFit: "fill" }} />
                    </div>
                    <input
                      type="file"
                      name="aadhar"
                      onChange={handleFranchisechange}
                      className="opacity0"
                      id="aadhar"
                    />
                  </Row>
                )}

                <Form.Group className="mb-3" controlId="Address 1">
                  <Form.Label>Addhar Card Number  <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    className="Textbar remove-spinner"
                    type="number"
                    placeholder="Enter your Addar Number"
                    name="aadhar_number"
                    isInvalid={franchiseDataError?.aadhar_number}
                    value={franchiseData?.aadhar_number}
                    autoComplete="off"
                    onChange={handleFranchisechange}
                    maxLength={12}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">
                    {franchiseDataError.aadhar_number}
                  </span>
                </Form.Group>
                {franchiseData.pan == "" ? (
                  <Row>
                    <Form.Group className="mb-3" controlId="Pan Card">
                      <Form.Label>Pan Card  <span className="text-danger">*</span></Form.Label>
                      <div
                        className="form-group py-2 position-relative mb-3 g-0"
                        onClick={() => handleSelectFiled("pan")}
                      >
                        {/* <input
                          type="file"
                          id="pan"
                          name="pan"
                          onChange={handleFranchisechange}
                          className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                        /> */}
                        <div className={`${franchiseDataError.pan && "border-danger"} adhar-box text-center`}>
                          <div className="mt-5">
                            <img src={placeholder} />{" "}
                            <span style={{ color: "#A3A3A3" }}>
                              Upload your Pan card here...
                            </span>
                          </div>
                        </div>
                        <span className="text-danger">
                          {franchiseDataError.pan}
                        </span>
                      </div>
                      <input
                        type="file"
                        id="pan"
                        name="pan"
                        onChange={handleFranchisechange}
                        className="opacity0  w-75 placeholder-light form-control py-2 imgheight px-3"
                      />
                    </Form.Group>
                  </Row>
                ) : (
                  <Row>
                    <Form.Label>Pan Card  <span className="text-danger">*</span></Form.Label>
                    <div
                      className="adhar-box mb-3 g-0"
                      onClick={() => handleSelectFiled("pan")}
                      style={{ marginLeft: "14px" }}
                    >
                      <img
                        // src={panImage}
                        src={typeof franchiseData?.pan == 'string' ? franchiseData?.pan : franchiseData?.pan && URL.createObjectURL(franchiseData?.pan)}
                        width="448px" height="154px" style={{ objectFit: "fill" }} />
                    </div>
                    <input
                      type="file"
                      name="pan"
                      onChange={handleFranchisechange}
                      className="opacity0"
                      id="pan"
                    />
                  </Row>
                )}
                <Form.Group className="mb-3" controlId="Address 1">
                  <Form.Label>Pan Card Number  <span className="text-danger">*</span></Form.Label>
                  <Form.Control
                    className="Textbar"
                    type="text"
                    placeholder="Enter your pan number"
                    name="pan_number"
                    // isInvalid={franchiseDataError?.pan_number?.toUpperCase()}
                    autoComplete="off"
                    onChange={handleFranchisechange}
                    value={franchiseData.pan_number?.toUpperCase()}
                    maxLength={10}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      }
                    }}
                    onWheelCapture={(e) => e.target.blur()}
                  />
                  <span className="text-danger">
                    {franchiseDataError.pan_number}
                  </span>
                </Form.Group>

                <Row>
                  <Form.Label>COD</Form.Label>
                  <div className="col-4 p-2 justify-content-start">
                    <div className="col-6" >
                      <label
                        // className={`switch ${franchiseData.cod_status == false ? 'border border-danger' : ''}`}
                        className={`switch`}
                      >
                        <input className="switch-input" type="checkbox"
                          name='cod_status'
                          checked={franchiseData?.cod_status}
                          onChange={(e) => setFranchiseData({ ...franchiseData, cod_status: franchiseData?.cod_status == false ? true : false })}
                        />
                        <span className="switch-label"></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  {franchiseData?.cod_status
                    ?
                    <Form.Group className="mb-3" controlId="Address 1">
                      <Form.Label>Maximum COD Amount  <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        className="Textbar remove-spinner"
                        type="number"
                        placeholder="Enter COD amount"
                        name="minimum_cod_amount"
                        isInvalid={franchiseDataError?.minimum_cod_amount}
                        autoComplete="off"
                        onChange={handleFranchisechange}
                        value={franchiseData.minimum_cod_amount}
                        maxLength={10}
                        onInput={(e) => {
                          if (e.target.value.length > e.target.maxLength) {
                            e.target.value = e.target.value.slice(
                              0,
                              e.target.maxLength
                            );
                          }
                        }}
                        onWheelCapture={(e) => e.target.blur()}
                      />
                      <span className="text-danger">  {franchiseDataError.minimum_cod_amount}</span>
                    </Form.Group>
                    :
                    ''
                  }


                  {/* <div className="col mb-3">
                      <Form.Label>Bank Name</Form.Label>
                      <Form.Select
                        className="Textbar"
                        controlId="Product category"
                        type="text"
                        placeholder="Enter select product category"
                        name="bankName"
                        // onChange={handleInventorychange}
                        // isInvalid={inventoryDataError?.subcategory}
                        autoComplete="off"
                      // disabled={isEditing}
                      >
                        <option selected disabled>  Select Bank</option>
                        {
                          banksList?.data?.map((el, index) => (
                            <option
                              key={index}
                            // value={el?._id}
                            // selected={el?._id === inventoryData?.subcategory}
                            >
                              {el?.bankName}
                            </option>
                          ))
                        }
                      </Form.Select>
                      <span className="text-danger">{franchiseDataError.bankName}</span>
                    </div>
                     */}

                  <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>Bank Name  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Enter bank name"
                      name="bankName"
                      isInvalid={franchiseDataError?.bankName}
                      value={franchiseData.bankName}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                    />
                    <span className="text-danger">{franchiseDataError.bankName} </span>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>Account Holder Name  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Enter account holder name"
                      name="accountHolderName"
                      isInvalid={franchiseDataError?.accountHolderName}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                      value={franchiseData.accountHolderName}
                    />
                    <span className="text-danger">{franchiseDataError.accountHolderName} </span>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>Account numbers  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar remove-spinner"
                      type="number"
                      placeholder="Enter account number"
                      name="accountNumber"
                      isInvalid={franchiseDataError?.accountNumber}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                      value={franchiseData.accountNumber}
                      maxLength={15}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength) {
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        }
                      }}
                      onWheelCapture={(e) => e.target.blur()}
                    />
                    <span className="text-danger"> {franchiseDataError.accountNumber} </span>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>IFSC Code  <span className="text-danger">*</span></Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Enter IFSC Code"
                      name="ifscCode"
                      isInvalid={franchiseDataError?.ifscCode}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                      value={franchiseData?.ifscCode?.toLocaleUpperCase()}
                      maxLength={15}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength) {
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                        }
                      }}
                      onWheelCapture={(e) => e.target.blur()}
                    />
                    <span className="text-danger">{franchiseDataError.ifscCode} </span>
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="Address 1">
                    <Form.Label>Branch</Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Enter branch"
                      name="branchName"
                      isInvalid={franchiseDataError?.branchName}
                      autoComplete="off"
                      onChange={handleFranchisechange}
                    />
                    <span className="text-danger"> {franchiseDataError.branchName}</span>
                  </Form.Group> */}

                  <div className="col mb-3">
                    <Form.Label>Account Type  <span className="text-danger">*</span></Form.Label>
                    <Form.Select
                      // className="Textbar"
                      controlId="Product category"
                      type="text"
                      placeholder="Enter select product category"
                      name="accountType"
                      className={`${franchiseDataError.accountType ? "border-danger" : ""} Textbar`}
                      onChange={handleFranchisechange}
                      value={franchiseData?.accountType || ''}
                      autoComplete="off"
                    >
                      <option value="" disabled >Select account type</option>
                      {
                        accountType?.data?.map((el, index) => (
                          <option
                            key={index}
                            value={el?.value}
                          >
                            {el?.accountType}
                          </option>
                        ))
                      }
                    </Form.Select>
                    <span className="text-danger"> {franchiseDataError.accountType} </span>
                  </div>


                  {/* <Form.Group className="mb-3 Textbar" controlId="Reffered by">
                    <Form.Label>Reffered by</Form.Label>
                    <Form.Control
                      className="Textbar"
                      type="text"
                      placeholder="Reffered by"
                      name="referred_by"
                      onChange={handleFranchisechange}
                      isInvalid={franchiseDataError?.referred_by}
                      autoComplete="off"
                    />
                    <span className="text-danger">
                      {franchiseDataError.referred_by}
                    </span>
                  </Form.Group> */}

                  <div className="row mt-2 mb-2"></div>
                  <div className="mb-3 d-flex">
                    <button type="submit" className="login-button">
                      Save & Proceed{" "}
                      {
                        loader
                          ?
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                          :
                          ''
                      }
                    </button>
                    <Link to='/franchise-listing' >
                      <button type="" className=" btn btn-outline-dark afrnch" style={{ marginLeft: "10px", padding: "10px", width: "200px" }}>
                        Cancel
                      </button>
                    </Link>

                  </div>
                </Row>
              </Form>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  {franchiseData.profile == "" ? (
                    <Row>
                      {
                        franchiseData.profile == "" && franchiseData?._id
                          ?
                          <Form.Group className="mb-3" controlId="Pan Card">
                            <div
                              className="form-group py-2 position-relative mb-3 "
                              onClick={() => handleSelectFiled("profile")}
                            >
                              <input
                                type="file"
                                name="profile"
                                id="profile"
                                onChange={handleFranchisechange}
                                isInvalid={franchiseDataError?.profile}
                                className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                              />
                              <img
                                src={imagenotavailable}
                                className="rounded h-100 w-100 shadow"
                                style={{ objectFit: "cover" }}
                              />
                            </div>
                          </Form.Group>
                          :
                          <Form.Group className="mb-3" controlId="Pan Card">
                            <div
                              className="form-group py-2 position-relative mb-3 "
                              onClick={() => handleSelectFiled("profile")}
                            >
                              <div className={`${franchiseDataError?.profile && "border-danger"} profile-box  text-center`}>
                                <div style={{ marginTop: "80px" }}>
                                  <img src={placeholder} />{" "}
                                  <span style={{ color: "#A3A3A3" }}>
                                    Upload Photo
                                  </span>
                                </div>
                              </div>
                            </div>
                            <input
                              type="file"
                              name="profile"
                              id="profile"
                              onChange={handleFranchisechange}
                              isInvalid={franchiseDataError?.profile}
                              className="opacity0  w-75 placeholder-light form-control py-2 imgheight px-3"
                            />
                          </Form.Group>
                      }
                    </Row>
                  ) : (
                    <Row>
                      <div
                        className="profile-box mb-3 g-0"
                        onClick={() => handleSelectFiled("profile")}
                      >
                        <img
                          // src={profileImage}
                          src={typeof franchiseData?.profile == 'string' ? franchiseData?.profile : franchiseData?.profile && URL.createObjectURL(franchiseData?.profile)}
                          width="100%" height="100%" />
                      </div>
                      <input
                        type="file"
                        name="profile"
                        onChange={handleFranchisechange}
                        className="opacity0"
                        id="profile"
                      />
                    </Row>
                  )}
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </MainTemplate >
  );
}

export default NewFranchise;
