import React, { useState, useEffect } from "react";
// import "./NewOfferForm.css";
import MainTemplate from "../../components/MainTemplate";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { postApi, postTokenApi, getTokenApi, putTokenApi } from "../../api_interface/apis";
import { toast } from "react-toastify";
import { COD_AMOUNT, FRANCHISE_LIST_API_PATH, HIBEE_PRODUCT_URL, REACT_OFFERS_API_PATH } from "../../api_interface/apiURLs";
import { useSelector, useDispatch } from "react-redux";
import { HIBEE_ADMIN_URL } from "../../api_interface/apiURLs";
import { Spinner } from "react-bootstrap";
import { Row, Col, Form, Container, Button } from "react-bootstrap";

function FranchiseCodRequest() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [franchiseList, setFranchiseList] = useState([])
    const [codRequest, setCodRequest] = useState({
        selectedFranchiseObject: "",
        franchise: [],
        society: [],
        date: "",
        amount: "",
        paidTo: "",
        loader: false,
        franchiseLoader: false
    });
    const [codRequestErrors, setCodRequestErrors] = useState({
        franchise: "",
        selectedFranchiseObject: "",
        society: "",
        date: "",
        amount: "",
        paidTo: "",
        loader: false
    });

    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');


    /** handle change **/
    const handleChange = (e) => {
        if (e.target.name == 'franchise') {
            if (e.target.value != '') {
                setCodRequest({
                    ...codRequest,
                    franchise: JSON.parse(e.target.value).owner_name,
                    society: JSON.parse(e.target.value).society?.societyName,
                    selectedFranchiseObject: JSON.parse(e.target.value),
                    paidTo: JSON.parse(e.target.value).owner_name
                });
                if (e.target.value != '') {
                    setCodRequestErrors({
                        ...codRequestErrors,
                        franchise: null,
                        society: null,
                        paidTo: null
                    });
                }
            } else {
                setCodRequest({
                    ...codRequest,
                    franchise: e.target.value,
                    society: "",
                    selectedFranchiseObject: "",
                    paidTo: ""
                });
                setCodRequestErrors({
                    ...codRequestErrors,
                    franchise: null,
                    society: null,
                });
            }

        } else {
            setCodRequest({ ...codRequest, [e.target.name]: e.target.value });
            setCodRequestErrors({ ...codRequestErrors, [e.target.name]: null });
        }
    };

    const validation = () => {
        const errorObj = {};
        if (codRequest?.franchise.length == 0) {
            errorObj.franchise = "Please select franchise";
        }
        if (codRequest?.society?.length == 0) {
            errorObj.society = "Please selct society";
        }

        if (!codRequest?.date) {
            errorObj.date = "Select date";
        }
        if (!codRequest?.amount) {
            errorObj.amount = "Enter amount ";
        }
        if (!codRequest?.paidTo) {
            errorObj.paidTo = "Enter paid to";
        }
        return errorObj;
    };

    const submit = async (e) => {
        e.preventDefault();
        const validationObject = validation();
        if (Object.keys(validationObject)?.length > 0) {
            setCodRequestErrors(validationObject);
        } else {
            if (validation()) {
                setCodRequest({ ...codRequest, loader: true });
                try {
                    const payload = {
                        franchiseId: codRequest?.selectedFranchiseObject?._id,
                        societyId: codRequest?.selectedFranchiseObject?.society._id,
                        date: codRequest.date,
                        amount: codRequest.amount,
                        paidTo: codRequest.paidTo
                    }
                    const data = await postTokenApi(HIBEE_PRODUCT_URL + COD_AMOUNT, payload);
                    if (data.status == 200) {
                        setCodRequest({ ...codRequest, loader: false });
                        toast.success("Amount Credited", {
                            position: "top-center",
                            autoClose: 2000,
                        })
                        handleCleare()
                    }
                } catch (err) {
                    setCodRequest({ ...codRequest, loader: false });
                    const message =
                        err?.response?.data?.message || "Something went wrong";
                    toast.error(message, { position: "top-center", autoClose: 2000 });
                    handleCleare()
                }
            }
        }
    };




    useEffect(() => {
        if (true) {
            // setCodRequest({
            //     ...codRequest,
            //     couponCode: state?.data?.offer_code,
            //     couponValue: state.data.type == 'amount' ? state?.data?.flat_discount : state?.data?.discount_percentage,
            //     date: state.data?.expiry_date?.slice(0, 10),
            //     amount: state.data?.usage_limit,
            //     paidTo: state.data?.description,
            //     id: state.data?._id
            // })
        } else {
            if (codRequest?.id == '') {
                // ** Get the current date ** //
                const today = new Date();
                const year = today.getFullYear();
                let month = today.getMonth() + 1;
                if (month < 10) {
                    month = `0${month}`;
                }
                let day = today.getDate();
                if (day < 10) {
                    day = `0${day}`;
                }
                const formattedDate = `${year}-${month}-${day}`;
                setCodRequest({
                    ...codRequest,
                    date: formattedDate
                });
            }
        }

        /*** Restricting three months timing ***/
        const today = new Date();
        const maxDate = new Date();
        maxDate.setMonth(today.getMonth() + 3);
        const todayFormatted = today.toISOString().split("T")[0];
        const maxDateFormatted = maxDate.toISOString().split("T")[0];
        setMinDate(todayFormatted);
        setMaxDate(maxDateFormatted)

    }, [])

    /** Franchise Listing(GET API) **/
    const handleGetFranchiseLists = async () => {
        setCodRequest({
            ...codRequest,
            franchiseLoader: true
        })
        await getTokenApi(HIBEE_PRODUCT_URL + FRANCHISE_LIST_API_PATH + `?status=true`)
            .then((ele, ind) => {
                if (ele.status == 200) {
                    const data = ele.data.data
                    const revData = data.reverse()
                    setFranchiseList(revData)
                    setCodRequest({
                        ...codRequest,
                        franchiseLoader: false
                    })
                }
            })
            .catch((error) => {
                setCodRequest({
                    ...codRequest,
                    franchiseLoader: false
                })
            });
    };


    useEffect(() => {
        handleGetFranchiseLists()
    }, [])

    const handleCleare = () => {
        setCodRequest({
            ...codRequest,
            franchise: [],
            society: [],
            date: "",
            amount: "",
            paidTo: "",
            id: "",
            loader: false
        })
    }

    return (
        <MainTemplate
            categoryName="Franchise Payment Form"
            categoryList={["Franchise", 'Franchise Payment Form']}
        >
            <div className="row mb-4 rounded bg-white homepageModifierPadding">
                <form onSubmit={submit}>
                    <div className="col-6 d-flex form-group py-2">
                        <div className="col-12" >
                            <Form.Label>Franchise Owner Name  <span className="text-danger">*</span></Form.Label>
                            <span className="">{" "}
                                {
                                    codRequest?.franchiseLoader == true
                                        ?
                                        <div class="spinner-border spinner-border-sm" role="status">
                                        </div>
                                        :
                                        " "
                                }
                            </span>
                            <div>
                                <div>
                                    <Form.Select
                                        // className="Textbar"
                                        controlId="Product category"
                                        type="text"
                                        placeholder="Enter select product category"
                                        name="franchise"
                                        className={`${codRequestErrors?.franchise ? "border-danger" : ""} Textbar w-75`}
                                        onChange={handleChange}
                                        // value={codRequest?.franchise?.owner_name}
                                        autoComplete="off"

                                    >
                                        <option value=''> select franchise</option>
                                        {
                                            franchiseList?.map((el, index) => (
                                                <option
                                                    key={index}
                                                    value={JSON.stringify(el)}
                                                >
                                                    {el?.owner_name}
                                                </option>
                                            ))
                                        }
                                    </Form.Select>
                                </div>
                            </div>
                            {codRequestErrors?.franchise && <p className="text-danger">{codRequestErrors?.franchise}</p>}
                        </div>
                    </div>

                    {/* <div className="col-6  form-group py-2">
                        <Form.Label>Society  <span className="text-danger">*</span></Form.Label>
                        <Form.Select
                            // className="Textbar"
                            controlId="Product category"
                            type="text"
                            placeholder="Enter select product category"
                            name="society"
                            className={`${codRequestErrors.society ? "border-danger" : ""} Textbar w-75`}
                            onChange={handleChange}
                            value={societyList}
                            autoComplete="off"
                        >
                            <option value=''> select society</option>
                            {
                                societyList?.map((el, index) => (
                                    <option
                                        key={index}
                                        value={JSON.stringify(el)}
                                    >
                                        {el?.societyName}
                                    </option>
                                ))
                            }
                        </Form.Select>
                        {codRequestErrors?.society && <p className="text-danger">{codRequestErrors?.society}</p>}
                    </div> */}

                    <div className="col-6 form-group py-2">
                        <label>Society <span className="text-danger" >*</span></label>
                        <input
                            type="text"
                            disabled={true}
                            className={`w-75 placeholder-light form-control py-2 px-3 form-control ${codRequestErrors?.society && "border-danger"}`}
                            placeholder="Enter society"
                            onChange={handleChange}
                            // value={codRequest?.franchise?.length != 0 ? JSON.parse(codRequest?.franchise).society?.societyName : ''}
                            name="society"
                            isInvalid={codRequestErrors?.society}
                            autoComplete="off"
                            value={codRequest.society}
                        />
                        {codRequestErrors?.society && <p className="text-danger">{codRequestErrors.society}</p>}
                    </div>

                    <div className="col-6  form-group py-2">
                        <label>Date <span className="text-danger" >*</span></label>
                        <input
                            type="date"
                            className={`w-75 placeholder-light form-control py-2 px-3 form-control ${codRequestErrors?.date && "border-danger"}`}
                            placeholder="Select the Expiry Date "
                            onChange={handleChange}
                            name="date"
                            autoComplete="off"
                            id="date" min={minDate} max={maxDate}
                            value={codRequest?.date}
                        />
                        {codRequestErrors?.date && <p className="text-danger">{codRequestErrors.date}</p>}
                    </div>

                    <div className="col-6 form-group py-2">
                        <label>Amount paid <span className="text-danger" >*</span></label>
                        <input
                            type="number"
                            className={`w-75 placeholder-light form-control py-2 px-3 form-control remove-spinner ${codRequestErrors?.amount && "border-danger"}`}
                            // placeholder="₹ 3,840.00"
                            placeholder="Enter paid amount"
                            onChange={handleChange}
                            value={codRequest.amount}
                            onWheelCapture={(e)=>e.target.blur()}
min={0}
                            name="amount"
                            isInvalid={codRequestErrors?.amount}
                            autoComplete="off"
                        />
                        {codRequestErrors?.amount && <p className="text-danger">{codRequestErrors.amount}</p>}
                    </div>
                    <div className="col-6  form-group py-2">
                        <label>Paid to <span className="text-danger" >*</span></label>
                        <input
                            className={`w-75 placeholder-light form-control py-2 px-3 form-control ${codRequestErrors?.paidTo && "border-danger"}`}
                            placeholder="Paid To"
                            onChange={handleChange}
                            value={codRequest.paidTo}
                            isInvalid={codRequestErrors?.paidTo}
                            name="paidTo"
                            autoComplete="off"
                            type='tex'
                            disabled
                        />
                        {codRequestErrors?.paidTo && <p className="text-danger">{codRequestErrors.paidTo}</p>}
                    </div>

                    <div className="d-flex  form-group py-2 mt-5">
                        <div className="">
                            <button type="reset" onClick={handleCleare} className="btn btn-dark text-warning px-5">Clear</button>
                        </div>
                        <div className="" style={{ marginLeft: "10px" }} >
                            <button type="submit" className="btn btn-dark text-warning px-5">
                                Log Payment{" "}
                                {
                                    codRequest?.loader == true
                                        ?
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                        />
                                        :
                                        " "
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div >
        </MainTemplate >
    );
}

export default FranchiseCodRequest;
