import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { addCategory } from "../redux/reducers/sidebarSlice";
import Logo from "../assets/images/logo.png";
import User from "../assets/images/user.png";
import "../App.css";
import Modal from "react-modal";
import { customActionModalStyles } from "../../src/utils/helperFunctin";
import AdminForm from "../pages/AdminForm/AdminForm";
import { addSidebarModules } from "../redux/reducers/sidebardModuleSlice";
import imagenotavailable from '../../src/assets/images/imagenotavailable.png'
import { getTokenApi } from "../api_interface/apis";
import { FRANCHISE_ORDER, HIBEE_ORDER_URL } from "../api_interface/apiURLs";


function Sidebar({ containerRef }) {
  const sidebarRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation();
  const currentPath = location.pathname;
  const dispatch = useDispatch()
  const selectedCategory = useSelector((state) => state?.sidebarReducer);
  const [sidebar, showSidebar] = useState(true);
  const [sidebarMobile, showSidebarMobile] = useState(false);
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [categories, setCategories] = useState([])

  const user = useSelector((state) => state.userReducer);

  const getData = async () => {
    try {
      const res = await getTokenApi(
        HIBEE_ORDER_URL + FRANCHISE_ORDER + `?export=${true}&orderType=${'processing'}`
      );
      if (res?.data) {
        const orders = res.data.processingCount;
        setOrdersCount(orders);
        await updateOrdersCountFromLocalStorage();

        localStorage.setItem('sidebarordersCount', orders); // Store in local storage
      }
    } catch (err) {
      console.log(err);
    }
  };

  const maincount = localStorage.getItem('sidebarordersCount');
  const [ordersCount, setOrdersCount] = useState(maincount); // Initialize ordersCount with 0

  const updateOrdersCountFromLocalStorage = () => {
    const storedOrdersCount = localStorage.getItem('sidebarordersCount');
    if (storedOrdersCount !== null) {
      setOrdersCount(parseInt(storedOrdersCount, 10));
    }
  };


  
  useEffect(() => {
    const storedOrdersCount = localStorage.getItem('sidebarordersCount');
    if (storedOrdersCount !== null) {
      setOrdersCount(parseInt(storedOrdersCount, 10));
    }

    getData();

    const interval = setInterval(getData, 5000);
    return () => clearInterval(interval);
  }, []);

  // console.log(ordersCount, 'jhjb');

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const categories = [
      /* Module Access */
      /* Categories */
      // { id: "1", name: "Categories & Products", link: "/categories" },

      {
        id: "1", name: "Inventory", value: "Inventories", type: "DROPDOWN", subCategory: [
          { id: "1.1", name: "Inventory Dashboard", link: "/" },
          // { id: "1.2", name: "Add Inventory", link: "/addinventory" },
          { id: "1.3", name: "Custom Inventory", link: "/custominventory" },
          { id: "1.4", name: "Custom Inventory History", link: "/custominventoryhistory" },
          { id: "1.5", name: "Inventory List", link: "/custominventorylist" },


        ]
      },
      {
        id: "15", name: "Product", value: "Products", type: "DROPDOWN", subCategory: [
          { id: "15.1", name: "Product List", link: "/products" },
          { id: "15.2", name: "Product Image Upload", link: "/productimageupload" },

        ]
      },
      {
        id: "8", name: "Categories", value: "Categories", type: "DROPDOWN", subCategory: [
          { id: "3.1", name: "Categories List", link: "/categories" },
          // { id: "3.2", name: "Upload Image", link: "/upload-image-list" },
          { id: "3.2", name: "Categories Upload Image", link: "/upload-category-image-list" },
          // { id: "3.3", name: "Sucategory Upload Image", link: "/upload-sub-image-list" },
        ]
      },



      // Franchise
      {
        id: "5", name: "Franchise", value: 'Franchises', type: "DROPDOWN", subCategory: [
          // { id: "5.1", name: "Franchise Details", link: "/franchise-details" },
          { id: "5.3", name: "Franchise List", link: "/franchise-listing" },
          { id: "5.1", name: "Franchise Payment Form", link: "/franchise-cod-request" },
          // { id: "5.2", name: "Franchise Request Details", link: "/franchise-request-details" },
        ]
      },
      { id: "7", name: "Society", value: 'Societies', link: "/society-listing" },
      {
        id: "6", name: "Admin", value: 'Admins', type: "DROPDOWN", subCategory: [
          { id: "6.0", name: "Admin List", link: "/admin-list" },
          // { id: "6.1", name: "Admin Form",link: "/admin-form" },
        ]
      },

      // { id: "9", name: "New Offer Form", link: "/new-offer-form" },
      { id: "12", name: "Coupons ", value: 'Offer', link: "/offer-listing" },
      // { id: "7", name: "Notification", link: "/notification" },
      // { id: "13", name: "Notifications", value: 'Notifications', link: "/notification-listing" },


      // Users
      { id: "10", name: "User Listing", value: "Users", link: "/user-listing" },

      // Orders

      {
        id: "2",
        name: ordersCount >= 0 ? `Orders (${ordersCount})` : "Orders",
        value: 'Orders',
        link: "/orders"
      },


      // Invetories
      // {
      //   id: "3", name: "Inventory", type: "DROPDOWN", subCategory: [
      //     { id: "3.1", name: "Inventory Dashboard", link: "/inventory" },
      //     { id: "3.2", name: "Inventory List", link: "/inventory-list" },
      //   ]
      // },
      {
        id: "4", name: "Homepage Modifier", value: 'HomepageModifiers', type: "DROPDOWN", subCategory: [
          { id: "4.0", name: "Sections", link: "/homepage-modifier/sections" },
          { id: "4.2", name: "Category list", link: "/homepage-modifier/category" },
          { id: "4.1", name: "Carousel", link: "/carousellistingpage" },
          { id: "4.5", name: "Groups List", link: "/homepage-modifer-groups-list" },
          { id: "4.4", name: "Static Banners", link: "/homepage-modifier/advertisement" },
          { id: "4.3", name: "Products List", link: "/homepagemodifierproductslisting" },
        ]
      },
      // { id: "5", name: "Offer Section", link: "/offer-section"},
      // { id: "8.1", name: "Product Price", link: "/product-price" },
      // { id: "11", name: "Feedback ", value: "Feedbacks", link: "/feedback-list" },
      // { id: "15", name: "Banner List", link: "/bannerlist" },
      // { id: "16", name: "Account", value: 'AdminAccounts', link: "/admin-accounts" },
    ];

    if (user?.type == "admin") {
      const matchedObjects = categories?.filter(obj => user?.access?.includes(obj?.value));
      setCategories(matchedObjects)
      dispatch(addSidebarModules(matchedObjects));
    } else {
      setCategories(categories)
      dispatch(addSidebarModules(categories));
    }
  }, [])

  useEffect(() => {
    if (sidebar) {
      sidebarRef.current.style.width = "25%";
      containerRef.current.style.width = "75%";
    } else {
      sidebarRef.current.style.width = "5%";
      containerRef.current.style.width = "95%";
    }
    // dispatch(addSidebarModules(categories));
    if (categories?.length != 0) {
      dispatch(addSidebarModules(categories));
    }
  }, [sidebar]);

  const togglePage = (category, subCategory, type) => {
    dispatch(addCategory(category));
    if (type !== "subCategory") {
      navigate(category?.link);
    } else {
      navigate(subCategory?.link);
    }
  };

  useEffect(() => {
    if (currentPath == '/') {
      dispatch(addCategory({
        id: '1',
        name: 'Inventory',
        value: 'Inventories',
        type: 'DROPDOWN',
        subCategory: [
          { id: '1.1', name: 'Inventory Dashboard', link: '/' },
          { id: '1.3', name: 'Custom Inventory', link: '/custominventory' },
          { id: '1.4', name: 'Custom Inventory History', link: '/custominventoryhistory' },
          { id: '1.5', name: 'Inventory List', link: '/custominventorylist' },
        ],
      }))
    }
  }, [])

  return (
    <>
      {/* sidebar */}
      {/* <AdminForm></AdminForm> */}
      <div
        className={`${sidebar ? "p-4" : "p-0"} border-right d-none d-md-block`}
        ref={sidebarRef}
        id="sidebar"
        style={{ width: "25%" }}
      >
        {sidebar ? (
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebar(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/">
                  <img src={Logo} className="w-100 categoryImageSidebar" />
                </Link>
              </div>
              {
                user?.type == 'admin'
                  ?
                  <div className="text-uppercase">Admin</div>
                  :
                  <div className="text-uppercase">Super Admin</div>
              }
            </div>
            {user.type == 'admin'
              ?
              <div>
                <Link to='/admin-accounts' className="text-decoration-none text-dark" >
                  <div className="mb-3 bg-light rounded row py-3">
                    <div className="col-md-3 d-flex align-items-center"><img src={user?.image ? user?.image : imagenotavailable} className="userLogo w-100 h-100 rounded-circle" /></div>
                    <div className="col-md-9 d-flex flex-row align-items-center">
                      <div className="p-1 text-wrap">
                        <div className="font-weight-bold">Hello, {user && user?.name}</div>
                        <div className="t-12">{user && user?.email}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              :
              <div className="mb-3 bg-light rounded row py-3">
                <div className="col-md-3 d-flex align-items-center">
                  <img src={User} className="userLogo w-100 h-100" />
                </div>
                <div className="col-md-9 d-flex flex-row align-items-center">
                  <div className="p-1 text-wrap">
                    <div className="font-weight-bold">Super Administrator</div>
                    {/* <div className="t-12">{user && user?.email}</div> */}
                  </div>
                </div>
              </div>
            }

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {/* Desk View */}
              {
                categories?.map((el) =>
                  el?.type !== "DROPDOWN" ? (
                    <div onClick={() => togglePage(el)} key={el?.id}>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="position-relative "
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordian-${el?.id}`}
                      key={el?.id}
                      onClick={() => togglePage(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                        style={{ top: "13px", right: "12px" }}
                        className="position-absolute cursorPointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                      <div
                        id={`accordian-${el?.id}`}
                        className={`${selectedCategory?.id === el?.id && "show"
                          }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                      >
                        {el?.subCategory?.map((ele) => (
                          <div
                            className={`py-1 px-3 rounded dropdownSubMenus cursorPointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePage(el, ele, "subCategory");
                            }}
                          >
                            {ele?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )
              }
            </div>

            <div className="m-0 p-0">
              {/* <span className="mx-2">Help</span>
              <span className="mx-2">About</span>
              <span className="mx-2">Setting</span> */}
              <span className="mx-2"
                // onClick={() => dispatch({ type: "USER_LOGOUT" })}
                onClick={() => setActionModal({ show: true })}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2 logout">Log out</span>
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="mb-4">
              <div className="h2 d-flex justify-content-center mt-4">
                <svg
                  onClick={() => showSidebar(true)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="mt-2 bi bi-arrow-right position-absolute"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
      {/* sidebar mobile nav*/}
      <div
        className={`p-0 border-right d-block d-md-none`}
        style={{ width: "5%" }}
      >
        <>
          <div className="mb-4">
            <div className="h2 d-flex justify-content-center mt-4">
              <svg
                onClick={() => showSidebarMobile(true)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="mt-2 bi bi-arrow-right position-absolute"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </div>
          </div>
        </>
      </div>
      {/* sidebar mobile */}
      {sidebarMobile && (
        <div
          className={`px-4 border-right position-absolute d-block d-md-none bg-white`}
          style={{ width: "300px", zIndex: "999", height: "100vh" }}
        >
          <div className="">
            <div className="mb-4">
              <div className="h2">
                <svg
                  onClick={() => showSidebarMobile(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                  />
                </svg>
              </div>
            </div>

            <div className="mb-3">
              <div className="sidebar_logo">
                <Link to="/categories">
                  <img src={Logo} className="w-100 categoryImageSidebar" />
                </Link>
              </div>
              <div className="text-uppercase">Super Admin</div>
            </div>

            <div className="mb-3 bg-light rounded row py-3">
              <div className="col-md-3 d-flex align-items-center">
                <img src={User} className="userLogo w-100 h-100" />
              </div>
              <div className="col-md-9 d-flex flex-row align-items-center">
                <div className="p-1 text-wrap">
                  <div className="font-weight-bold">Super Administrator</div>
                  <div className="t-12">superadmin@hibee.com</div>
                </div>
              </div>
            </div>

            <div className="mb-5 overflow-auto sidebarCategoryHeight pe-2">
              {/* Mobile View */}
              {
                categories?.map((el) =>
                  el?.type !== "DROPDOWN" ? (
                    <div onClick={() => togglePage(el)} key={el?.id}>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="position-relative"
                      data-bs-toggle="collapse"
                      data-bs-target={`#accordian-${el?.id}`}
                      key={el?.id}
                      onClick={() => togglePage(el)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill={`${selectedCategory?.id !== el?.id && "grey"}`}
                        style={{ top: "13px", right: "12px" }}
                        className="position-absolute"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                      <div
                        className={`py-2 px-3 rounded cursorPointer ${selectedCategory?.id === el?.id &&
                          "selectedCategory font-weight-bold shadow-sm"
                          }`}
                        onClick={() => togglePage(el)}
                      >
                        {el?.name}
                      </div>
                      <div
                        id={`accordian-${el?.id}`}
                        className={`${selectedCategory?.id === el?.id && "show"
                          }  px-3 bg-light text-sidebar-dropdown py-2 rounded t-12 accordion-collapse collapse`}
                      >
                        {el?.subCategory?.map((ele) => (
                          <div
                            className={`py-1 px-3 rounded dropdownSubMenus cursorPointer`}
                            onClick={(e) => {
                              e.stopPropagation();
                              togglePage(el, ele, "subCategory");
                            }}
                          >
                            {ele?.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )
              }
            </div>

            <div className="m-0 p-0 ">
              {/* <span className="mx-2">Help</span>
              <span className="mx-2">About</span>
              <span className="mx-2">Setting</span> */}
              <span className="mx-2" onClick={() =>
              // dispatch({ type: "USER_LOGOUT" })
              {
                showSidebarMobile(false)
                setActionModal({ show: true })

              }}

              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>
                <span className="mx-2">Log out</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {/* Logout Modal */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3 modal_width">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to Logout ?
              {/* Super Admin? */}
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() => dispatch({ type: "USER_LOGOUT" })}
              style={{ backgroundColor: "#12352f" }}
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              No
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Sidebar;
