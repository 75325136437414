import React, { useEffect, useMemo, useState } from "react";
import "../../pages/Orders/Orders.css";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { BsArrowDownUp } from "react-icons/bs";
import {
  FRANCHISE_DELIVERY_STATUS,
  FRANCHISE_ORDER,
  HIBEE_ORDER_URL,
  HIBEE_PRODUCT_URL,
  SOCIETY_LIST,
} from "../../api_interface/apiURLs";
import { getTokenApi, patchTokenApi } from "../../api_interface/apis";
import { customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState({ data: [], loader: false, show: false });
  const [sort, setSort] = useState("");
  const [status, setStatus] = useState("");
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState("");
  const [formattedEndDate, setFormattedEndDate] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [type, setType] = useState("inventory");
  const [filteredList, setFilteredList] = useState([]);
  const [societyList, setSocietyList] = useState([]);
  const [pageCounts, setPageCounts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1)
  const [exportOption, setExportOption] = useState(false)
  const [societyQuery, setSocietyQuery] = useState("")
  const [selectedSocietyValues, setSelectedSocietyValues] = useState("");
  const [orderType, setOrderType] = useState("")



  const user = useSelector((state) => state?.userReducer);
  const getData = async (search) => {
    try {
      setLoader(true);
      const res = await getTokenApi(
        HIBEE_ORDER_URL +
        FRANCHISE_ORDER +
        `?sort=${sort}&search=${query}&dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}&status=${selectedValues}&type=${type}&page=${currentPage}&export=${exportOption}&orderType=${orderType}&society=${selectedSocietyValues}`
      );
      if (res?.data) {
        const count = res?.data.totalPages;
        const orders = res.data.data;
        setFilteredList(orders);
        setPageCounts(count)

      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  const getSociety = async (search) => {
    try {
      setLoader(true);
      const res = await getTokenApi(HIBEE_PRODUCT_URL + SOCIETY_LIST + `?search=${societyQuery}`);
      if (res?.data?.data) {
        const orders = res.data.data.reverse();
        setSocietyList(orders);
      }
      setLoader(false);
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  const getSearchedData = useMemo(
    () =>
      debounce(async (search) => {
        getData(search);
      }, 500),
    []
  );
  useEffect(() => {
    getSociety();
  }, [societyQuery]);
  useEffect(() => {
    getData();
  }, [sort, formattedStartDate, formattedEndDate, selectedValues, type, currentPage, exportOption, query, selectedSocietyValues, orderType]);

  // const [orderedByCustomer, setOrderedByCustomer] = useState([]);

  // useEffect(() => {
  //   if (type == "franchise") {
  //     const customerOrders = orders.filter(
  //       (order) => order.type === "inventory"
  //     );
  //     setFilteredList(customerOrders);
  //   } else {
  //     const customerOrders = orders.filter(
  //       (order) => order.type === "offline_user" && "users"
  //     );
  //     setFilteredList(customerOrders);
  //   }
  // }, [type]);

  useEffect(() => {
    getSearchedData(query);
  }, [query]);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);

  };

  const upateOrderStatus = async (e) => {
    try {
      setModal({ ...modal, loader: true });
      const orderId = modal?.data?.map((el) => el?._id);
      const res = await patchTokenApi(
        HIBEE_ORDER_URL + FRANCHISE_ORDER + FRANCHISE_DELIVERY_STATUS,
        {
          orderId,
          status: e.target?.value,
        }
      );
      setModal({});
      await getData();
    } catch (err) {
      setModal({ ...modal, loader: false });
    }
  };

  const selectOrders = (e, order) => {
    // const foundIndex = modal?.data?.findIndex(el => el?._id === order?._id)
    if (e.target.checked) {
      const data = modal?.data || [];
      data?.push(order);
      setModal({ ...modal, data });
    } else {
      const data = modal?.data?.filter((el) => el?._id !== order?._id);
      setModal({ ...modal, data });
    }
  };

  const actionClick = () => {
    if (modal?.data?.length) {
      setModal({ ...modal, show: true });
    } else {
      toast.error("Select orders first.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const updateSort = (e) => {
    setSort(e.target.value);
  };

  const [filterStatus, setFilterStatus] = useState(false);
  const [filterFranchise, setFilterFranchise] = useState(false);
  const [filterAmount, setFilterAmount] = useState(false);
  const [filterSociety, setFilterSociety] = useState(false);
  const [filterSuperFast, setFilterSuperFast] = useState(false);

  const [checkbox2IsChecked, setCheckbox2IsChecked] = useState();
  const [filter, setFilter] = useState("");

  // useEffect(() => {
  //   if (filter !== "") {
  //     // const filteredData = orders.filter((order) => orders.filter == filter);
  //   } else {
  //     if (type == "franchise") {
  //       const customerOrders = orders.filter(
  //         (order) => order.type === "inventory"
  //       );
  //       setFilteredList(customerOrders);
  //     } else {
  //       const customerOrders = orders.filter(
  //         (order) => order.type === "offline_user" && "users"
  //       );
  //       setFilteredList(customerOrders);
  //     }
  //   }
  // }, [filter]);

  const handleStartDateChange = (date) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = date
      ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
      : "";
    setFormattedStartDate(formattedDate);
    setFormattedEndDate("");
  };

  const handleEndDateChange = (date) => {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const formattedDate = date
      ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
      : "";
    setFormattedEndDate(formattedDate);
  };

  const updateStatus = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedValues((prevValues) =>
        prevValues ? `${prevValues},${selectedValue}` : selectedValue
      );
    } else {
      setSelectedValues((prevValues) =>
        prevValues
          .split(",")
          .filter((value) => value !== selectedValue)
          .join(",")
      );
    }
  };

  const updateSocietyStatus = (event) => {
    const selectedValue = event.target.value;
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedSocietyValues((prevValues) =>
        prevValues ? `${prevValues},${selectedValue}` : selectedValue
      );
    } else {
      setSelectedSocietyValues((prevValues) =>
        prevValues
          .split(",")
          .filter((value) => value !== selectedValue)
          .join(",")
      );
    }
  };
  console.log(societyQuery, selectedSocietyValues, "query");
  const handleOkClick = () => {
    // do something with the selected values
    setFilterStatus(false);
  };
  async function handleExportClick() {
    // Assuming you have the API JSON data stored in a variable called `jsonData`

    // Set the export option to true
    await setExportOption(true);

    // Get the API JSON data
    await getData();

    // Specify the headers you want to export
    const headersToExport = ['_id', 'username', 'createdAt', 'amount', 'status', 'soociety', 'ordertype'];

    // Convert JSON to CSV format with the specified headers
    const csvContent = convertToCSV(filteredList, headersToExport);

    // Create a temporary anchor element to initiate the download
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
    downloadLink.download = 'data.csv';

    // Trigger the download
    downloadLink.click();

    // Set the export option back to false
    await setExportOption(false);
  }


  function convertToCSV(jsonData, headersToExport) {
    // Filter the JSON data to include only the objects with the desired headers
    const filteredData = jsonData.map(obj => {
      const filteredObj = {};
      headersToExport.forEach(header => {
        filteredObj[header] = obj[header];
      });
      return filteredObj;
    });

    // Extract column headers from the first object in the filtered data
    const headers = Object.keys(filteredData[0]);

    // Convert each object to an array of values
    const rows = filteredData.map(obj => Object.values(obj));

    // Combine headers and rows
    const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');

    return csvContent;
  }

  const handleCheckboxChange = (value) => {
    if (orderType.includes(value)) {
      setOrderType(orderType.filter((val) => val !== value));
    } else {
      setOrderType([...orderType, value]);
    }
  };

  const today = new Date();
  // Set hours, minutes, seconds, and milliseconds to 0 to ensure only the date is considered
  today.setHours(0, 0, 0, 0);

  return (
    <MainTemplate categoryName="Orders" categoryList={[ "Orders"]}>
      {/* <div className="row mb-4 rounded p-2 bg-white">
				Orders
			</div> */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex mb-2">
          <button
            type="button"
            class={
              type === "inventory"
                ? "bulkuploadbutton1 actives "
                : "bulkuploadbutton1"
            }
            onClick={() => setType("inventory")}
          >
            Franchise
          </button>
          <button
            type="button"
            class={
              type != "inventory"
                ? "bulkuploadbutton2 actives "
                : "bulkuploadbutton2"
            }
            onClick={() => setType("user")}
          >
            Customer
          </button>
        </div>
        <div className="d-flex mx-0 align-items-center gap-2">
          <p className="font-weight-bold t-12 text-uppercase m-0 p-0">From:</p>
          <DatePicker
            className="datepicker-value"
            value={formattedStartDate ? formattedStartDate : "YYYY-MM-DD"}
            onChange={handleStartDateChange}
            dateFormat={"yyyy-MM-dd"}
            maxDate={today}
          />
          <p className="font-weight-bold t-12 text-uppercase m-0 p-0">To:</p>
          <DatePicker
            className="datepicker-value "
            value={formattedEndDate ? formattedEndDate : "YYYY-MM-DD"}
            onChange={handleEndDateChange}
            dateFormat={"yyyy-MM-dd"}
            disabled={formattedStartDate ? false : true}
            maxDate={today}

          />
        </div>
      </div>
      <div className="row mb-2 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">All Orders</div>
        <div className="col-sm-6 my-3 col-md-3">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={updateSort}
          >
            <option value="" selected>
              Sort by
            </option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>+
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-sm-6 my-3 col-md-4 ">
          <div class="dropdown">
            <button
              className="t-12 px-4 btn btn-dark text-warning dropdown-btn "
              data-menu-target={`drop-down-menu-multiple-order`}
            >
              Action
            </button>
            {/* <button class="dropbtn">Dropdown</button> */}
            <div
              id={`drop-down-menu-multiple-order`}
              className="dropdown-content p-2"
            >
              <div
                className="dropdown-menu-option rounded px-3 py-2 d-flex justify-content-start cursorPointer"
                onClick={actionClick}
              >
                <div className="font-weight-bold">Update status</div>
              </div>
              {/* <div
                className="dropdown-menu-option rounded px-3 py-2 d-flex justify-content-start cursorPointer"
                onClick={() => {
                  toast.info("To be discussed", {
                    position: "top-center",
                    autoClose: 2000,
                  });
                }}
              >
                <div className="font-weight-bold">Delete</div>
              </div> */}
            </div>
          </div>
          <button
            className="t-12 ms-2 px-4 btn btn-dark text-warning "
            onClick={handleExportClick}
          >
            Export
          </button>
        </div>
      </div>

      <table className="table rounded  text-center">
        <thead className="thead-light">
          <tr>
            <th className="font-weight-bold t-12 text-uppercase" style={{ paddingRight: 15 }}>
              <Form.Check
                type="checkbox"
                label=""
                onChange={(e) =>
                  setModal(
                    e.target.checked ? { ...modal, data: filteredList } : {}
                  )
                }
              />
            </th>
            <th className="font-weight-bold t-12 text-uppercase">Order Id</th>
            <th className="font-weight-bold t-12 text-uppercase">
              {type === "inventory" ? "Franchise" : "Name"}
            </th>
            {/* {type == "inventory" ? (
        <th
          className={filterFranchise ? "dropdownoptions" : "visually-hidden"}
        >
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
          />
          <div className="overflow-auto caterogyPageScrolls">
            {franchiseList.map((el) => (
              <div className="dropdownselectoption ">
                <input
                  className="form-check-input mt-0"
                  type="checkbox"
                  onChange={updateStatus}
                  value="processing"
                  aria-label="Checkbox for following text input"
                />
                {el.owner_name}
              </div>
            ))}
          </div>
          <div className="dropdownselectbutton">
            <button
              className="t-12 px-4 btn btn-dark text-warning dropdown-btn"
              onClick={() => setFilterFranchise(false)}
            >
              Ok
            </button>
            <button
              className="t-12 px-4 btn text-success dropdown-btn"
              onClick={() => setFilterFranchise(false)}
            >
              Cancel
            </button>
          </div>
        </th>
      ) : (
        ""
      )} */}
            <th className="font-weight-bold t-12 text-uppercase">Date</th>
            <th className="font-weight-bold t-12 text-uppercase text-center">
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                onClick={() => setFilterAmount(!filterAmount)}
              >
                <p className="p-0 m-0">Amount</p>
                <BsArrowDownUp className="fs-9" />
              </div>

              <div className={filterAmount ? "dropdownoption" : "visually-hidden"}>
                <div className="dropdownselectoption">
                  <label className="d-flex align-items-center gap-2">
                    <input
                      type="radio"
                      name="sort"
                      onChange={updateSort}
                      value="cashlow"
                      aria-label="Radio button for sorting by low to high"
                    />
                    Low - High
                  </label>
                </div>
                <div className="dropdownselectoption">
                  <label className="d-flex align-items-center gap-2">
                    <input
                      type="radio"
                      name="sort"
                      onChange={updateSort}
                      value="cashhigh"
                      aria-label="Radio button for sorting by high to low"
                    />
                    High - Low
                  </label>
                </div>
                <div className="dropdownselectbutton">
                  <button
                    className="t-12 px-4 btn btn-dark text-warning dropdown-btn"
                    onClick={() => setFilterAmount(false)}
                  >
                    Ok
                  </button>
                  <button
                    className="t-12 px-4 btn text-success dropdown-btn"
                    onClick={() => setFilterAmount(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </th>
            <th className="font-weight-bold t-12 text-uppercase">
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                onClick={() => setFilterStatus(!filterStatus)}
              >
                <p className="m-0 p-0">Status</p>
                <BsArrowDownUp className="fs-9" />
              </div>

              <div className={filterStatus ? "dropdownoption" : "visually-hidden"}>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    onChange={updateStatus}
                    value="processing"
                    aria-label="Checkbox for following text input"
                  />
                  Processing
                </div>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    onChange={updateStatus}
                    value="delivered"
                    aria-label="Checkbox for following text input"
                  />
                  Delivered
                </div>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    onChange={updateStatus}
                    value="returned"
                    aria-label="Checkbox for following text input"
                  />
                  Returned
                </div>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    onChange={updateStatus}
                    value="cancelled"
                    aria-label="Checkbox for following text input"
                  />
                  Cancelled
                </div>
                <div className="dropdownselectbutton">
                  <button
                    className="t-12 px-4 btn btn-dark text-warning dropdown-btn"
                    onClick={handleOkClick}
                  >
                    Ok
                  </button>
                  <button
                    className="t-12 px-4 btn text-success dropdown-btn"
                    onClick={() => setFilterStatus(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </th>
            <th className="font-weight-bold t-12 text-uppercase">
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                onClick={() => setFilterSociety(!filterSociety)}
              >
                <p className="m-0 p-0">Society</p>
                <BsArrowDownUp className="fs-9" />
              </div>
              <div className={filterSociety ? "dropdownoptions" : "visually-hidden"}>
                <input
                  type="text"
                  name="query"
                  className="t-12 form-control"
                  placeholder="Search"
                  value={societyQuery}
                  onChange={(e) => setSocietyQuery(e.target.value)}
                />
                <div className="overflow-auto caterogyPageScrolls">
                  {societyList.map((el) => (
                    <div className="dropdownselectoption ">
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        onChange={updateSocietyStatus}
                        value={el._id}
                        aria-label="Checkbox for following text input"
                      />
                      {el.societyName}
                    </div>
                  ))}
                </div>
                <div className="dropdownselectbutton">
                  <button
                    className="t-12 px-4 btn btn-dark text-warning dropdown-btn"
                    onClick={() => setFilterSociety(false)}
                  >
                    Ok
                  </button>
                  <button
                    className="t-12 px-4 btn text-success dropdown-btn"
                    onClick={() => setFilterSociety(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </th>
            <th className="font-weight-bold t-12 text-uppercase">
              <div
                className="d-flex align-items-center justify-content-center gap-2"
                onClick={() => setFilterSuperFast(!filterSuperFast)}
              >
                <p className="m-0 p-0">Order Type</p>
                <BsArrowDownUp className="fs-9" />
              </div>
              <div className={filterSuperFast ? "dropdownoption" : "visually-hidden"}>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    value="normal"
                    aria-label="Checkbox for following text input"
                    onChange={() => {
                      handleCheckboxChange("normal");
                      setType('user');
                    }}
                  />
                  Normal
                </div>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    value="offline_user"
                    aria-label="Checkbox for following text input"
                    onChange={() => {
                      handleCheckboxChange("offline_user");
                      setType("user");
                    }}
                  />
                  Offline
                </div>
                <div className="dropdownselectoption">
                  <input
                    className="form-check-input mt-0"
                    type="checkbox"
                    value="super_fast"
                    aria-label="Checkbox for following text input"
                    onChange={() => {
                      handleCheckboxChange("super_fast");
                      setType('user');
                    }}
                  />
                  SuperFast
                </div>
                <div className="dropdownselectbutton">
                  <button
                    className="t-12 px-4 btn btn-dark text-warning dropdown-btn"
                    onClick={() => {
                      // Perform additional actions if needed
                      setFilterSuperFast(false);
                    }}
                  >
                    Ok
                  </button>
                  <button
                    className="t-12 px-4 btn text-success dropdown-btn"
                    onClick={() => setFilterSuperFast(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </th>
            <th className="font-weight-bold t-12 text-uppercase"></th>
          </tr>
        </thead>
        <tbody>
          {loader ? (
            <tr>
              <td className="text-center" colSpan="8">
                <button
                  className="btn"
                  style={{ backgroundColor: "#FFEDC2" }}
                  type="button"
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Loading...
                </button>
              </td>
            </tr>
          ) : (
            filteredList.map((el) => (
              <>
                <tr className=" bg-white text-center" style={{ borderWidth: 0, borderStyle: "hidden", borderSpacing: "separate", borderRadius: 12 }}>
                  <td className="t-12" style={{ padding: 15 }}>
                    <Form.Check
                      type="checkbox"
                      label=""
                      checked={modal?.data?.some((o) => o?._id === el?._id)}
                      onChange={(e) => selectOrders(e, el)}
                    />
                  </td>
                  <td className="t-12 overflow-hidden" style={{ padding: 15 }}>
                    <Link className="orderidlink" to={`/order-details/${el?._id}`}>
                      {el?._id.slice(0, 5)}
                    </Link>
                  </td>
                  <td className="t-12" style={{ padding: 15, textTransform: "capitalize" }}>{el?.type === "inventory" ? el?.franchise_owner_name : el?.userDetails?.username}</td>
                  <td className="t-12" style={{ padding: 15, textTransform: "capitalize" }}>{moment(el?.createdAt).format("D MMM, YYYY")}</td>
                  <td className="t-12" style={{ padding: 15, textTransform: "capitalize" }}>{`₹ ${el?.amount}`}</td>
                  <td className="t-12" style={{ padding: 15, textTransform: "capitalize" }}>
                    <div
                      className="text-white p-1 text-center rounded text-capitalize"
                      style={{
                        backgroundColor: `${(el.status == "processing" && "#FEBF22") ||
                          (el.status == "dispatched" && "#E96511") ||
                          (el.status == "cancelled" && "#E93737") ||
                          (el.status == "returned" && "#000000") ||
                          (el.status == "franchise_recieved" && "#65c") ||
                          (el.status == "delivered" && "#29AE13")
                          }`,

                      }}
                    >
                      {el?.status}
                    </div>
                  </td>
                  <td className="t-12 " style={{ padding: 15, textTransform: "capitalize" }}>{el?.societyDeatils?.societyName}</td>
                  <td className="t-12 text-capitalize " style={{ padding: 15, textTransform: "capitalize" }}>{el?.orderType}</td>
                  <td className="t-12 text-center " style={{ padding: 15 }}>
                    <div className="dropdown">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        data-menu-target={`drop-down-menu-${el?._id}`}
                        className="bi bi-three-dots dropdown-btn cursorPointer"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                      </svg>
                      {/* <button class="dropbtn">Dropdown</button> */}
                      <div
                        id={`drop-down-menu-${el?._id}`}
                        className="dropdown-content p-2 mobile-dropdown6"
                      >
                        <div
                          className="dropdown-menu-option rounded px-3 py-2 d-flex cursorPointer"
                          onClick={() =>
                            setModal({ show: true, data: [el], loader: false })
                          }
                        >
                          <div className="font-weight-bold">Update status</div>
                        </div>
                        {/* <div className="dropdown-menu-option rounded px-3 py-2 d-flex cursorPointer" onClick={() => { toast.info("To be discussed", { position: "top-center", autoClose: 2000, }) }}>
                      <div className="font-weight-bold">Delete</div>
                    </div> */}
                        {/* <a href="#home">Home</a>
                  <a href="#about">About</a>
                  <a href="#contact">Contact</a> */}
                      </div>
                    </div>
                  </td>
                </tr>
                <div style={{ height: "12px" }}>

                </div>
              </>


            ))
          )}
        </tbody>
      </table>

      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCounts}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>

      <ReactModal
        isOpen={modal?.show}
        onRequestClose={() => setModal({})}
        style={{
          ...customModalStyles,
          content: { ...customModalStyles?.content, width: "40vw" },
        }}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row d-flex justify-content-center">
            <h4 className="text-danger d-flex mb-3 justify-content-center">
              Select order status?
            </h4>
            <select
              disabled={modal?.loader}
              className="w-50 mb-3 form-select"
              aria-label="Default select example"
              outline="none"
              onChange={upateOrderStatus}
            >
              <option selected disabled>
                Select Status
              </option>
              <option value="processing">Processing</option>
              <option value="dispatched">Dispatched</option>
              {type == 'inventory' && <> <option value="delivered">Delivered</option>
              <option value="cancelled">Cancelled</option>
              <option value="returned">Returned</option> </> }
            </select>
          </div>
          {modal?.loader ? (
            <div className="d-flex align-items-center justify-content-center">
              <div
                class="spinner-border text-warning spinner-border-sm"
                role="status"
              >
                {" "}
                <span class="sr-only"></span>{" "}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </ReactModal>
    </MainTemplate>
  );
}

export default Orders;
