import React, { useState, useEffect } from "react";
import "./NewOfferForm.css";
import MainTemplate from "../../components/MainTemplate";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { postApi, postTokenApi, getTokenApi, putTokenApi } from "../../api_interface/apis";
import { toast } from "react-toastify";
import { REACT_OFFERS_API_PATH } from "../../api_interface/apiURLs";
import { useSelector, useDispatch } from "react-redux";
import { addUser } from "../../redux/reducers/userSlice";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { HIBEE_ADMIN_URL } from "../../api_interface/apiURLs";
import { Spinner } from "react-bootstrap";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEDC2",
    border: "1px solid #ced4da",
    fontSize: 20,
    fontWeight: 700,
    color: "#12352f",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      color: "#12352f",
      fontFamily: "Inter",
      backgroundColor: "#FFEDC2",
      fontWeight: 700,
      fontSize: 20,
    },
  },
}));

function NewOfferForm(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [couponData, setCouponData] = useState({
    couponCode: "",
    couponValue: "",
    minCartValue: "",
    maxCouponDiscount: "",
    expiryDate: "",
    usageLimit: "",
    couponDescription: "",
    id: ""
  });

  const [couponDataErrors, setCouponDataErrors] = useState({
    couponValue: "",
    couponCode: "",
    minCartValue: "",
    maxCouponDiscount: "",
    expiryDate: "",
    usageLimit: "",
    couponDescription: "",
    id: ""
  });
  const [loader, setLoader] = useState(false);
  const [checkBox, setCheckBox] = useState(false)
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');


  const handleChange = (e) => {
    if (e.target.type == 'number') {
      const inputValue = e.target.value;
      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
        if (e.target.name !== "image") {
          setCouponData({ ...couponData, [e.target.name]: e.target.value });
          setCouponDataErrors({ ...couponDataErrors, [e.target.name]: null });
        }

        if (e.target.name == "image") {
          var url = URL.createObjectURL(e.target.files[0]);
          setCouponData({ ...couponData, [e.target.name]: e.target.files[0] });
          setCouponDataErrors({ ...couponDataErrors, [e.target.name]: null });
        }
      }
    }
    else {
      if (e.target.name !== "image") {
        setCouponData({ ...couponData, [e.target.name]: e.target.value });
        setCouponDataErrors({ ...couponDataErrors, [e.target.name]: null });
      }

      if (e.target.name == "image") {
        var url = URL.createObjectURL(e.target.files[0]);
        setCouponData({ ...couponData, [e.target.name]: e.target.files[0] });
        setCouponDataErrors({ ...couponDataErrors, [e.target.name]: null });
      }
    }


  };

  const validation = () => {
    const validateZero = /^[1-9][0-9]*$/
    const errorObj = {};
    if (!couponData?.couponCode) {
      errorObj.couponCode = "Enter the coupon code";
    }
    
    if (checkBox == false) {
      if (!couponData?.couponValue) {
        errorObj.couponValue = "Enter the coupon value in rupees";
      } else if (checkBox == false && couponData?.couponValue && couponData?.minCartValue && +couponData?.couponValue >= +couponData?.minCartValue) {
        errorObj.couponValue = "Coupon value should be below minimum cart value";
      }
    } else {
      if (!couponData?.couponValue) {
        errorObj.couponValue = "Enter the coupon value in percentage";
      } else if (checkBox == true && couponData?.couponValue && +couponData?.couponValue >= 100) {
        errorObj.couponValue = "Coupon value should be below 100";
      }
    }

    if (!couponData?.minCartValue) {
      errorObj.minCartValue = "Enter the value in rupees";
    }

    if (checkBox == true) {
      if (!couponData?.maxCouponDiscount) {
        errorObj.maxCouponDiscount = "Enter the value in rupees";
      } else if(checkBox == true && couponData?.couponValue && couponData?.minCartValue && +couponData?.maxCouponDiscount >= +couponData?.minCartValue) {
        errorObj.maxCouponDiscount = "Maximum coupon discount should be below minimum cart value";
      }
    }

    if (!couponData?.expiryDate) {
      errorObj.expiryDate = "Select expairy date";
    }
    if (!couponData?.usageLimit) {
      errorObj.usageLimit = "Enter the limit of coupon usage";
    }
    if (!couponData?.couponDescription) {
      errorObj.couponDescription = "Enter the coupon description";
    }


    // if (!couponData?.couponValue) {
    //   errorObj.couponValue = "Enter the coupon code";
    // }

    // if (!couponData?.category_id) {
    //   errorObj.category_id = "Please select category";
    // }

    return errorObj;
  };

  const submit = async (e) => {
    e.preventDefault();
    const validationObject = validation();
    if (Object.keys(validationObject)?.length > 0) {
      setCouponDataErrors(validationObject);
    } else {
      setLoader(true);
      if (validation()) {
        try {
          const payload = {
            type: checkBox ? "percentage" : "amount",
            offer_code: couponData.couponCode.toLocaleUpperCase(),
            min_order_value: couponData.minCartValue,
            expiry_date: couponData.expiryDate,
            usage_limit: couponData.usageLimit,
            description: couponData.couponDescription
          }
          if (checkBox) {
            payload.max_discount_value = couponData.maxCouponDiscount
            payload.discount_percentage = couponData.couponValue
          } else {
            payload.flat_discount = couponData.couponValue
          }
          if (couponData?.id) {
            const data = await putTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH + `/${couponData?.id}`, payload);
            if (data.status == 200) {
              setLoader(false)
              toast.success("Offer created", {
                position: "top-center",
                autoClose: 2000,
              });
              handleCleare()
            }
          } else {
            const data = await postTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH, payload);
            if (data.status == 200) {
              handleCleare()
              setLoader(false)
              toast.success("Offer created", {
                position: "top-center",
                autoClose: 2000,
              });
            }
          }
        } catch (err) {
          handleCleare()
          setLoader(false)
          const message =
            err?.response?.data?.message || "Something went wrong";
          toast.couponDataErrors(message, { position: "top-center", autoClose: 2000 });
        }
      }
      setLoader(false);
      navigate("/offer-listing");
    }
  };


  useEffect(() => {
    setCouponDataErrors({
      ...couponDataErrors,
      maxCouponDiscount: ""
    })
  }, [checkBox])

  useEffect(() => {
    if (state?.data && state?.data?.offer_code != '') {
      setCouponData({
        ...couponData,
        couponCode: state?.data?.offer_code,
        couponValue: state.data.type == 'amount' ? state?.data?.flat_discount : state?.data?.discount_percentage,
        minCartValue: state?.data?.min_order_value,
        maxCouponDiscount: state.data?.max_discount_value,
        expiryDate: state.data?.expiry_date?.slice(0, 10),
        usageLimit: state.data?.usage_limit,
        couponDescription: state.data?.description,
        id: state.data?._id
      })

      if (state?.data?.type == 'percentage') {
        setCheckBox(true)
      } else {
        setCheckBox(false)
      }
    } else {
      if (couponData?.id == '') {
        // ** Get the current date ** //
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        if (month < 10) {
          month = `0${month}`;
        }
        let day = today.getDate();
        if (day < 10) {
          day = `0${day}`;
        }
        const formattedDate = `${year}-${month}-${day}`;
        setCouponData({
          ...couponData,
          expiryDate: formattedDate
        });
      }
    }

    /*** Restricting three months timing ***/
    const today = new Date();
    const maxDate = new Date();
    maxDate.setMonth(today.getMonth() + 3);
    const todayFormatted = today.toISOString().split("T")[0];
    const maxDateFormatted = maxDate.toISOString().split("T")[0];
    setMinDate(todayFormatted);
    setMaxDate(maxDateFormatted)

  }, [])


  const handleCleare = () => {
    setCouponData({
      ...couponData,
      couponValue: "",
      couponCode: "",
      minCartValue: "",
      maxCouponDiscount: "",
      expiryDate: "",
      usageLimit: "",
      couponDescription: "",
      id: ""
    })
  }

  return (
    <MainTemplate
      categoryName="Coupon form"
      categoryList={["Admin", "Coupons"]}
    >
      <div className="row mb-4 rounded bg-white homepageModifierPadding">
        <form onSubmit={submit}>
          <div className="d-flex" >
            <div className="col-7" >
              <div className="col-9  form-group py-2">
                <label>Coupon Code <span className="text-danger" >*</span></label>
                <input
                  type="text"
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.couponCode && "border-danger"}`}
                  placeholder="Enter the coupon code"
                  onChange={handleChange}
                  value={couponData.couponCode.toLocaleUpperCase()}
                  name="couponCode"
                  isInvalid={couponDataErrors?.couponCode}
                  autoComplete="off"
                />
                {couponDataErrors?.couponCode && <p className="text-danger">{couponDataErrors.couponCode}</p>}
              </div>

              <div className="d-flex form-group py-2 align-items-center">
                {
                  checkBox == false
                    ?
                    <div className="col-7" >
                      <div className="" >
                        <label >Coupon Value <span className="text-danger" >*</span></label>
                        <input
                          type="number"
                          className={`w-100 placeholder-light form-control py-2 px-3 form-control remove-spinner ${couponDataErrors?.couponValue && "border-danger"}`}
                          placeholder="Enter the value in rupees"
                          onChange={handleChange}
                          value={couponData.couponValue}
                          name="couponValue"
                          isInvalid={couponDataErrors?.couponValue}
                          autoComplete="off"
                          onWheelCapture={(e) => e.target.blur()}
                        />
                        {couponDataErrors?.couponValue && <p className="text-danger">{couponDataErrors.couponValue}</p>}
                      </div>
                    </div>
                    :
                    <div className="col-7" >
                      <div className="" >
                        <label >Coupon Value <span className="text-danger" >*</span></label>
                        <input
                          type="number"
                          className={`w-100 placeholder-light form-control py-2 px-3 form-control remove-spinner ${couponDataErrors?.couponValue && "border-danger"}`}
                          placeholder="Enter the value in percentage"
                          onChange={handleChange}
                          value={couponData.couponValue}
                          name="couponValue"
                          isInvalid={couponDataErrors?.couponValue}
                          onWheelCapture={(e)=>e.target.blur()}

                          autoComplete="off"
                        />
                        {couponDataErrors?.couponValue && <p className="text-danger">{couponDataErrors.couponValue}</p>}
                      </div>
                    </div>
                }
                <div className="col-5  d-flex mt-3" style={{ marginLeft: '5px' }} >
                  <div className="col-2" >
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" onChange={(e) => setCheckBox(!checkBox)} value="" id="flexCheckChecked" checked={checkBox} />
                    </div>
                  </div>
                  <div className="col" >
                    <p>Value in Percentage </p>
                  </div>
                </div>
              </div>
              <div className="col-9 form-group py-2">
                <label>Minimum Cart Value <span className="text-danger" >*</span></label>
                <input
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.minCartValue && "border-danger"}`}
                  placeholder="Enter the value in Rupees"
                  onChange={handleChange}
                  value={couponData.minCartValue}
                  name="minCartValue"
                  type='number'
                  isInvalid={couponDataErrors?.minCartValue}
                  autoComplete="off"
                />
                {couponDataErrors?.minCartValue && <p className="text-danger">{couponDataErrors.minCartValue}</p>}
              </div>

              {
                checkBox == true
                  ?
                  <div className="col-9 form-group py-2">
                    <label>Maximum Coupon Discount <span className="text-danger" >*</span></label>
                    <input
                      // type="text"
                      className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.maxCouponDiscount && "border-danger"}`}
                      placeholder="Enter the value in rupees"
                      onChange={handleChange}
                      value={couponData.maxCouponDiscount}
                      name="maxCouponDiscount"
                      type='number'
                      isInvalid={couponDataErrors?.maxCouponDiscount}
                      autoComplete="off"
                    />
                    {couponDataErrors?.maxCouponDiscount && <p className="text-danger">{couponDataErrors.maxCouponDiscount}</p>}
                  </div>
                  :
                  ''
              }

              <div className=" col-9  form-group py-2">
                <label>Expiry Date <span className="text-danger" >*</span></label>
                <input
                  type="date"
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.expiryDate && "border-danger"}`}
                  placeholder="Select the Expiry Date "
                  onChange={handleChange}
                  name="expiryDate"
                  autoComplete="off"
                  id="date" min={minDate} max={maxDate}
                  value={couponData?.expiryDate}
                />
                {couponDataErrors?.expiryDate && <p className="text-danger">{couponDataErrors.expiryDate}</p>}
              </div>

              <div className="col-9 form-group py-2">
                <label>Usage Limit <span className="text-danger" >*</span></label>
                <input
                  type="number"
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control remove-spinner ${couponDataErrors?.usageLimit && "border-danger"}`}
                  placeholder="Enter the limit of coupon usage"
                  onChange={handleChange}
                  value={couponData.usageLimit}
                  name="usageLimit"
                  isInvalid={couponDataErrors?.usageLimit}
                  onWheelCapture={(e)=>e.target.blur()}

                  autoComplete="off"
                />
                {couponDataErrors?.usageLimit && <p className="text-danger">{couponDataErrors.usageLimit}</p>}
              </div>
              <div className="col-9  form-group py-2">
                <label>Coupon description <span className="text-danger" >*</span></label>
                <textarea
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${couponDataErrors?.couponDescription && "border-danger"}`}
                  placeholder="Enter the coupon description"
                  onChange={handleChange}
                  value={couponData.couponDescription}
                  isInvalid={couponDataErrors?.couponDescription}
                  name="couponDescription"
                  autoComplete="off"
                />
                {couponDataErrors?.couponDescription && <p className="text-danger">{couponDataErrors.couponDescription}</p>}
              </div>
            </div>
            <div className="col-5" >
            </div>
          </div>
          <div className="d-flex form-group py-2">
            <div className="col-3" >
              <button type="submit" className="btn text-warning px-5"
                style={{ backgroundColor: "#12352f" }}
              >
                Create Coupon {
                  loader == true &&
                  <Spinner
                    animation="border"
                    variant="light"
                    size="sm"
                  />
                }
              </button>
            </div>
            <div className="col-3">
              <button type="reset" onClick={handleCleare} className="btn btn-outline-dark text-dark px-5">Clear</button>
            </div>
          </div>
        </form>
      </div >
    </MainTemplate >
  );
}

export default NewOfferForm;
