export const checkPasswordValidity = str => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
}
export const validateEmail = email => String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const emailValidity = str => {
  var re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(str);
}

export const customModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: "60vw",
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden'
    },
    overlay: {
        background: 'rgba(0,0,0,0.2)',
        zIndex: '9'
    }
};

export const custombulkModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: "581px",
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  overlay: {
      background: 'rgba(0,0,0,0.2)',
      zIndex: '9'
  }
};

export const customActionModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: "500px",
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden'
  },
  overlay: {
      background: 'rgba(0,0,0,0.2)',
      zIndex: '9'
  }
};

export const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}