import React, { useEffect, useState, useNavigate, useMemo } from "react";
import "./OffersListing.css";
import ReactPaginate from "react-paginate";
import { Link, Navigate } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { IoMdArrowDropdown } from "react-icons/io";
import { deleteTokenApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { CATEGORY_PATH, HIBEE_ADMIN_URL, HIBEE_PRODUCT_URL, REACT_OFFERS_API_PATH } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import MainTemplate from "../../components/MainTemplate";
function OfferListing() {
  const [offersList, setOffersList] = useState({
    list: []
  });
  const [img1, setImg1] = useState('')
  const [offer, setOffer] = useState({
    title: "",
    min_order_value: "",
    max_discount: "",
    offer_code: "",
    type: "",
    image: "",
  });
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [itemOffset, setItemOffset] = useState(0);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [offorListLoader, setOfferListLoader] = useState(false)
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(offersList.list.length / itemsPerPage);
  const currentItems = offersList.list.slice(itemOffset, endOffset);
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const [updateType, setUpdateType] = useState(true)


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % offersList.list.length;
    setItemOffset(newOffset);
  };

  const getOffersList = async (search) => {
    setOfferListLoader(true);
    const res = await getTokenApi(
      HIBEE_ADMIN_URL +
      REACT_OFFERS_API_PATH
      +
      // `?sort=${sort}&query=${search || query || ""}`
      `?activated=${updateType}`
    );
    if (res.status == 200) {
      setOfferListLoader(false)
      setOffersList({
        ...offersList,
        list: res?.data?.data.reverse()
      });
    }
  };

  const Submit = async (e) => {
    e.preventDefault();
    setLoader(true);
    const { offer_code, min_discount, _id, min_order_value, title, image } = offer;
    const fd = new FormData();
    if (_id) {
      fd.append("_id", _id);
    }
    fd.append("min_order_value", min_order_value);
    fd.append("min_discount", min_discount);
    fd.append("title", title);
    fd.append("offer_code", offer_code);
    fd.append("image", image);
    const res = await postTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH, fd, { "Content-Type": "multipart/form-data" });
    if (res.status == 200) {
      setModal(false);
      getOffersList();
      setLoader(false);
      getOffersList();
    }
  };

  const deleteOffer = async (id) => {
    const res = await deleteTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH + `/${id}`);
    if (res.status == 200) {
      setActionModal({});
      getOffersList();
    }
  };

  const archiveOffer = (cat) => {
    setActionModal({ ...actionModal, loader: true });

    setActionModal({});
    getOffersList();
    // setActionModal({...actionModal, show:true})
  };

  const [isChecked, setIsChecked] = useState(false);
  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: !ele, index: id })
    // Path Params
    const userId = id
    try {
      const payload = {
        activated: ele
      }
      const res = await putTokenApi(HIBEE_ADMIN_URL + REACT_OFFERS_API_PATH + `/${userId}`, payload);
      if (res.status == 200) {
        if (res?.data?.data?.activated == true) {
          toast.success("Offer Activated", {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.data?.activated == false) {
          toast.error("Offer Diactivated", {
            position: "top-center",
            autoClose: 2000,
          });
        }
      }
      getOffersList();
    } catch (err) {
      toast.error("Something went wrong", { position: "top-center", autoClose: 2000 });
      getOffersList();
    }
  };


  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getOffersList(search)
  }, 500), [])

  useEffect(() => {
    getOffersList()
  }, [updateType]);

  useEffect(() => {
    getOffersList()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const [categories, setCategories] = useState([]);

  const getCategories = async () => {
    const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH);
    setCategories(res?.data?.data);
  };
  useEffect(() => {
    getCategories();
  }, []);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.value;
    if (event.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((value) => value !== checkboxValue)
      );
    }
  };

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <MainTemplate
      categoryName="Coupons"
      categoryList={["Coupons"]}
    >
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>

        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example"
            onChange={e => setUpdateType(e.target.value)}
          >
            <option value={true} selected>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        {/* <div className="col-sm-6 my-3 col-md-3"> */}
        {/* <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} /> */}
        {/* </div> */}
        <div className="d-none d-md-block col-md-3"></div>
        <div className="col-sm-6 my-3 col-md-2">
          <Link
            to="/new-offer-form/01"
            state={{ data: offer }}
            className="text-dark px-3 py-1 rounded"
            style={{ textDecoration: "none" }}
          >
            <button
              type="button"
              className="t-12 w-100 btn text-warning"
              style={{ backgroundColor: "#12352f" }}
            >
              Add New Coupons
            </button>
          </Link>
        </div>
      </div>

      {/* table */}
      <div className="row flex-column align-items-center">
        <div className="row rounded text-center bg-white">
          {/* header */}
          <div className="row m-0 ">
            <div className="font-weight-bold t-12 text-uppercase my-3 col-md-2">
              COUPON CODE
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              COUPON VALUE
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Minimum Cart Value
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Expiry Date
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
              Usage Limit
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
              Edit
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Active / Inactive
            </div>
            {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-1"></div> */}
          </div>

          {/* body*/}
          <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
            {offorListLoader == false && currentItems?.map((el) => (
              <div
                className="row border rounded m-0 mb-2 d-flex align-items-center"
                key={el?._id}
              >
                <div className="col-md-2 my-4">
                  {el?.offer_code}
                </div>
                <div className="col-md-2 my-4">
                  {el.type == 'amount' ? el?.flat_discount : el?.discount_percentage}
                </div>
                <div className="col-md-2 my-4">
                  {el?.min_order_value}
                </div>
                <div className="col-md-2 my-4">
                  {el?.expiry_date?.slice(0, 10)}
                </div>
                <div className="col-md-1 my-4">
                  {el?.usage_limit}
                </div>
                <div className="col my-4  mr-4">
                  <Link to={`/new-offer-form/${el._id}`} state={{ data: el }} >
                    {" "}
                    <svg
                      className="bi bi-pencil border-bottom border-dark"
                      // onClick={() => { setModal(true); setOffer(el); }}
                      // onClick={() => { setModal(true); setOffer(el); }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>
                  </Link>
                </div>
                <div className="t-12 text-uppercase my-3 col-md-2">
                  <label className="switch">
                    <input className="switch-input" type="checkbox"
                      checked={el.activated}
                      onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}
                    />
                    <span className="switch-label"></span>
                    <span className="switch-handle"></span>
                  </label>
                </div>
              </div>
            ))}

            {offorListLoader == true &&
              <div className="text-center mt-5" >
                <button class="btn mt-5" style={{ backgroundColor: '#FFEDC2' }} type="button">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                  Loading...
                </button>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>

      <Modal
        isOpen={modal}
        onRequestClose={() => {
          setModal(false);
          setOffer({});
          setLoader(false);
        }}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="container containerbg overflow-auto rounded bg-white row w-100 ">
          <div className="px-3 py-3">
            <div className="row">
              <span className="d-flex justify-content-end cursorPointer">
                <svg
                  onClick={() => {
                    setModal(false);
                    setOffer({});
                    setLoader(false);
                  }}
                  className="d-inline"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg>
              </span>
            </div>
            <h5 className=" mt-4 text-center">Update Offer</h5>
            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Minimum Order Value</label>
                <input
                  type="text"
                  value={offer?.min_order_value}
                  onChange={(e) => setOffer({ ...offer, min_order_value: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Products</label>
                <div className="position-relative">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Recipient's username"
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                      value={selectedCheckboxes.join(", ")}

                    />
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      id="button-addon2"
                      onClick={handleInputClick}
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                  {
                    showDropdown && (
                      <div className="position-absolute  bg-white border rounded mt-1 w-100 p-2">
                        {categories.map(
                          (product) => (
                            <div key={product} class="input-group mb-3 z-1">
                              <div class="input-group-text">
                                <input
                                  class="form-check-input mt-0"
                                  type="checkbox"
                                  value={product.name}
                                  aria-label="Checkbox for following text input"
                                  onChange={handleCheckboxChange}

                                />
                              </div>
                              <input
                                type="text"
                                class="form-control"
                                value={product.name}
                                aria-label="Text input with checkbox"
                                onChange={handleCheckboxChange}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Offer Name</label>
                <input
                  value={offer?.title}
                  onChange={(e) => setOffer({ ...offer, title: e.target.value })}
                  type="text"
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: OFFER25"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Offer Code</label>
                <input
                  type="text"

                  value={offer?.offer_code}
                  onChange={(e) => setOffer({ ...offer, offer_code: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group my-3">
                <label className="my-2">Maximum discount</label>
                <input
                  type="text"
                  value={offer?.max_discount}
                  onChange={(e) => setOffer({ ...offer, max_discount: e.target.value })}
                  className="placeholder-light form-control my-2 py-2 px-3"
                  placeholder="eg: 20"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group py-2  mb-4 position-relative">
                <label>Upload Image</label>
                <input
                  type="file"
                  name="image"
                  className="homepageModifierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3"
                  placeholder="eg: https://www.apple.com/in/watch"
                  onChange={(e) => {
                    setOffer({ ...offer, image: e?.target?.files?.[0] })
                    setImg1(e?.target?.files?.[0])
                  }}
                />
                <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75 }`}  >
                  {offer?.image ? (
                    <img src={img1 == '' ? offer && offer?.image : URL.createObjectURL(img1)} className="rounded h-100 w-100 shadow" alt="img" />
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-cloud-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                        />
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                      </svg>
                      <span className="px-2">Upload Photo</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group my-3">
              <button
                disabled={loader}
                className="btn btn-dark text-warning px-5"
                onClick={Submit}
              >
                {loader ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/* Delete Offer */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Offer?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "DELETE"
                  ? deleteOffer(actionModal?.data?._id)
                  : archiveOffer(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>


    </MainTemplate>
  );
}

export default OfferListing;
