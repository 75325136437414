import React, { useState, useEffect, useRef } from "react";
import "./Notification.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { postTokenApi } from "../../api_interface/apis";
import { HIBEE_ADMIN_URL, REACT_NOTIFICATIONS_PATH } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFEDC2",
    border: "1px solid #ced4da",
    fontSize: 20,
    fontWeight: 700,
    color: "#12352f",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      color: "#12352f",
      fontFamily: "Inter",
      backgroundColor: "#FFEDC2",
      fontWeight: 700,
      fontSize: 20,
    },
  },
}));

function disableDates() {
  var dtToday = new Date();
  var month = dtToday.getMonth() + 1;
  var day = dtToday.getDate();
  var year = dtToday.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();
  var maxDate = year + "-" + month + "-" + day;
  return maxDate;
}

function Notification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    title: "",
    push_date: "",
    push_time: "",
    description: "",
    image: "",
  });
  const [error, setError] = useState({
    title: "",
    push_datetime: "",
    push_date: "",
    push_time: "",
    description: "",
    image: "",
  });
  const [img1, setImg1] = useState('')

  console.log(img1, 'img1');
  console.log(userData, 'userData');
  console.log(error, 'error');

  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    if (e.target.name !== "image") {
      setUserData({ ...userData, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: null });
    }
    if (e.target.name == "image") {
      setUserData({ ...userData, [e.target.name]: e.target.files[0] });
      setError({ ...error, [e.target.name]: null })
      setImg1(e?.target?.files?.[0])
    }
  };

  const validation = () => {
    const errorObj = {};
    if (!userData?.title) {
      errorObj.title = "Please enter title";
    }
    if (!userData?.description) {
      errorObj.description = "Please enter description";
    }
    if (!(userData?.image)) {
      errorObj.image = "Please upload image";
    }
    if (!userData?.push_date) {
      errorObj.push_date = "Please select correct date";
    }
    if (!userData?.push_time) {
      errorObj.push_time = "Please select time";
    }
    return errorObj;
  };

  const submit = async (e) => {
    e.preventDefault();
    const validationObject = validation();
    if (Object.keys(validationObject)?.length > 0) {
      setError(validationObject);
    } else {
      setLoader(true);
      if (validation()) {
        try {
          const formData = new FormData();
          formData.append("title", userData.title);
          formData.append("image", userData.image);
          formData.append("push_date", userData.push_date);
          formData.append("push_time", userData.push_time);
          formData.append("description", userData.description);
          const data = await postTokenApi(HIBEE_ADMIN_URL + REACT_NOTIFICATIONS_PATH, formData, { "Content-Type": "multipart/form-data" });
          if (data.status == 200) {
            toast.success("Notification posted", {
              position: "top-center",
              autoClose: 2000,
            });
            navigate("/notification-listing");
            setLoader(false);
          }
        } catch (err) {
          const message =
            err?.response?.data?.message || "Something went wrong";
          toast.error(message, { position: "top-center", autoClose: 2000 });
          navigate("/notification-listing");
          setLoader(false);
        }
      }

    }
  };

  return (
    <MainTemplate
      categoryName="Categories"
      categoryList={["Admin", "Notification"]}
    >
      <div className="row mb-4 rounded bg-white homepageModifierPadding">
        <form onSubmit={submit}>
          <div className="form-group py-2">
            <label>Notification Title</label>
            <input
              type="text"
              className={`w-75 placeholder-light form-control py-2 px-3 form-control ${error?.title && "border-danger"
                }`}
              placeholder="Notification title"
              onChange={handleChange}
              value={userData.title}
              name="title"
              isInvalid={error?.title}
              autoComplete="off"
            />
            {Boolean(error?.title) && (
              <p className="text-danger">{error.title}</p>
            )}
          </div>

          <div className="form-group py-2">
            <label>Notification Description</label>
            <textarea
              className={`w-75 placeholder-light form-control py-2 px-3 form-control ${error?.description && "border-danger"
                }`}
              placeholder="Notification Description"
              rows="6"
              onChange={handleChange}
              value={userData.description}
              name="description"
              isInvalid={error?.description}
              autoComplete="off"
            ></textarea>
            {Boolean(error?.description) && (
              <p className="text-danger border-danger">{error.description}</p>
            )}
          </div>

          <div className="form-group w-75">
            <div className="row d-flex px-2">
              <div className="col-lg-6 form-group p-0 py-2 pb-3 px-1">
                <label>Date</label>
                <input
                  type="date"
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${error?.push_date && "border-danger"
                    }`}
                  placeholder="DD-MM-YYYY"
                  onChange={handleChange}
                  value={userData.push_date}
                  name="push_date"
                  autoComplete="off"
                  isInvalid={error?.push_date}
                  min={disableDates()}
                />
                {Boolean(error?.push_date) && (
                  <p className="text-danger">{error.push_date}</p>
                )}
              </div>

              <div className="col-lg-6 form-group p-0 py-2 pb-3 px-1 pe-0">
                <label>Time</label>
                <input
                  type="time"
                  className={`w-75 placeholder-light form-control py-2 px-3 form-control ${error?.push_time && "border-danger"
                    }`}
                  placeholder="Enter batch name"
                  onChange={handleChange}
                  value={userData.push_time}
                  name="push_time"
                  autoComplete="off"
                  isInvalid={error?.push_time}
                />
                {Boolean(error?.push_time) && (
                  <p className="text-danger">{error.push_time}</p>
                )}
              </div>
            </div>
          </div>

          <div className="form-group py-2  mb-4 position-relative">
            <label>Upload Image</label>
            <input
              type="file"
              name="image"
              className="homepageModifhandleChangeierInput opacity0 position-absolute w-75 placeholder-light form-control py-2 px-3 g-0"
              placeholder="eg: https://www.apple.com/in/watch"
              onChange={handleChange}
            />

            <div className={`homepageModifierInput select-text-light d-flex align-items-center justify-content-center border rounded w-75 ${error?.image && "border-danger"}`} >
              {userData?.image ? (
                <img src={img1 == '' ? userData?.image : URL.createObjectURL(img1)} className="rounded h-100 w-100 shadow" alt="img" />
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cloud-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  <span className="px-2">Upload Photo</span>
                </>
              )}
            </div>
            <span className="text-danger">{error?.image}</span>
          </div>

          <div className="form-group py-2">
            <button className="btn btn-dark text-warning px-5">
              Submit
              {
                loader == true &&
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                />
              }
            </button>
          </div>
        </form>
      </div>
    </MainTemplate>
  );
}

export default Notification;
