import React from "react";
import "./FeedbackInternal.css";
import MainTemplate from "../../components/MainTemplate";
import StarRating from "../FeedbackList/StarRating";
import { useState, useEffect } from "react";
import { HIBEE_ORDER_URL, FEDBACK_GET_API } from "../../api_interface/apiURLs";
import { getTokenApi } from "../../api_interface/apis";
import { useParams } from "react-router-dom"

function FeedbackInternal() {
  const [feedbackDetails, setfeedbackDetails] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [feedbackListLoader, setfeedbackloader] = useState(false)


  const { feedbackId } = useParams()
  const itemsPerPage = 10;
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(feedbackDetails.length / itemsPerPage);
  const feedbackItems = feedbackDetails.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % feedbackDetails.length;
    setItemOffset(newOffset);
  };


  const getfeedback = async () => {
    setfeedbackloader(true)
    const res = await getTokenApi(HIBEE_ORDER_URL + FEDBACK_GET_API + `/${feedbackId}`)
    if (res.status == 200) {
      setfeedbackloader(false)
      setfeedbackDetails([res?.data?.data]);
    }

  };


  useEffect(() => {
    if (feedbackId) {
      getfeedback()
    }
  }, []);


  return (
    <MainTemplate categoryName="Feedback #78574"
    //  categoryList={["Admin", "feedback listing"]}
    >
      <div className="container">
        {
          feedbackListLoader == false && feedbackItems && feedbackItems?.map((el, ind) => {
            return <div className="row mt-5">
              <div className="col-md-1"></div>
              <div className="col-md-3 row justify-content-center">
                <div className="md-3 mt-2">
                  <div className="md-3 mb-3">
                    <div className="fntdark">Name

                    </div>
                    <div className="fnyclss underli">  {el?.username}</div>
                  </div>

                  <div className="md-3 mb-3">
                    <div className="fntdark">Assigned Franchise No.</div>
                    <div className="fnyclss underli">#783746635562</div>
                  </div>

                  <div className="md-3 mb-3">
                    <div className="fntdark">Phone Number</div>
                    <div className="fntfant underli">
                      +91-{el?.phone}
                    </div>
                  </div>

                  <div className="md-3 mb-3">
                    <div className="fntdark">Address</div>
                    <div className="fntfant boox2">
                      #152 Ram Sang Market, 123 Main Street, Om mandir, Jalandhar,
                      Punjab, 560078.
                    </div>
                  </div>

                  <div className="md-3 mb-3">
                    <div className="fntdark">Order Number</div>
                    <div className="fntfant underli">
                      #{el?.order_id}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-1 py-0 horriLiness"></div>
              <div className="col-md-5 md-3 px-0">
                <div className=" md-3 mt-3 mb-3">
                  <div className="fntdark">Feedback ID</div>
                  <div className="fnyclss">
                    #{el?.feedback_id}
                  </div>
                </div>

                <div className=" md-3 mb-3">
                  <div className="fntdark">User Rating</div>
                  <div>
                    <StarRating rating={el?.rating} />
                  </div>
                </div>

                <div className="md-3 mb-3 boxmes">
                  <div className="fntdark">Message</div>
                  <div className="fntfant boox">
                    {el.message}
                  </div>
                </div>
                <div className="md-3"></div>
                <div className="md-3 mt-5">
                  <button className="btn btn-success feedbtn">
                    Forward email to franchise
                  </button>
                </div>
              </div>
            </div>
          })
        }
        {feedbackListLoader == true && (
          <div className="text-center mt-5">
            <button
              class="btn mt-5"
              style={{ backgroundColor: "#FFEDC2" }}
              type="button"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Loading...
            </button>
          </div>
        )}
      </div>
    </MainTemplate>
  );
}

export default FeedbackInternal;
