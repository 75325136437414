import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import "./CategoryDetail.css";
import Modal from "react-modal";
import { toast } from "react-toastify";
import BulkSubCategoryCsv from "../../assets/BulkSubCatagory.csv";
import Papa from "papaparse";
import excell from '../../assets/images/excell.jpg'
import { deleteTokenApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { BULK_SUBCATEGORY_PATH, BULK_SUB_CATEGORY_PATH, CATEGORY_PATH, CATEGORY_SUB_LIST_PATH, CATEGORY_SUB_PATH, HIBEE_PRODUCT_URL } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import ReactPaginate from "react-paginate";
import { Button, Form } from "react-bootstrap";
import imagenotavailable from '../../assets/images/imagenotavailable.png'


const allowedExtensions = ["csv"];

function CategoryDetail() {
  const { categoryId } = useParams();
  const [categories, setCategories] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [modal, setModal] = useState(false);
  const [query, setQuery] = useState("");
  const [subCategory, setSubCategory] = useState({ show: false, data: { name: "", commission: "", discount: "", image: "" }, loader: false });
  const [subCategoryError, setSubCategoryError] = useState({ show: false, data: { name: "", commission: "", discount: "", image: "" }, loader: false });
  const [error, setError] = useState({});
  const [actionModal, setActionModal] = useState({ loader: false, show: false, type: "", data: {} });
  const [category, setCategory] = useState({});
  const [bulkCategories, setBulkCategories] = useState({ bulkData: [], show: false, error: '' })

  // const [bulkCategories, setBulkCategories] = useState({
  //   bulkData: [
  //     {
  //       CatagoryId: '',
  //       CateogoryName: '',
  //       Commission: '',
  //       Discount: '',
  //       CatagoryId: '',
  //       sid: "",
  //       selectedCateName: ""
  //     }
  //   ], show: false, error: ''
  // })


  const [file, setFile] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sort, setSort] = useState("");
  const [aprove, setAprove] = useState({
    status: "",
    index: ""
  })
  const [updateType, setUpdateType] = useState(false)
  const [mainCheck, setMainCheck] = useState(false)



  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(category?.SubCategorys?.length / itemsPerPage);
  const currentItems = category?.SubCategorys?.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % category?.SubCategorys?.length;
    setItemOffset(newOffset);
  };

  const getData = async (search, updateType, sort) => {
    const one = localStorage.getItem('subCategoryStatus')
    const two = localStorage.getItem('subCategorySort')
    const res = await postTokenApi(HIBEE_PRODUCT_URL + CATEGORY_SUB_LIST_PATH + `/${categoryId}?sort=${two ? two : 'name'}&search=${search || query || ""}&status=${one}`);
    setCategory(res?.data?.message?.[0]);
  };

  const getCategories = async () => {
    const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH);
    setCategories(res?.data?.data);
  };


  const validation = () => {
    const { name, image, discount, commission, imageFile, _id, csvfile } = subCategory?.data;
    const discountPattern = /^(100(\.0{1,2})?|\d{0,2}(\.\d{1,2})?)$/;
    let error = {};
    if (!name) {
      error.name = "Name required";
    }
    // if (!discount) {
    //   error.discount = "Discount Required";
    // }
    // if (!commission) {
    //   error.commission = "Commission Required";
    // }

    if (discount) {
      if (discountPattern.test(discount)) {
        if (discount <= 100) {
          // error.discount = 'Discount is valid.';
        } else {
          error.discount = 'Discount cannot be greater than 100%.';
        }
      } else {
        error.discount = 'Discount cannot be greater than 100%.';
      }
    }

    if (commission) {
      if (discountPattern.test(commission)) {
        if (commission <= 100) {
          // error.discount = 'Commission is valid.';
        } else {
          error.commission = 'Commission cannot be greater than 100%.';
        }
      } else {
        error.commission = 'Commission cannot be greater than 100%.';
      }
    }

    if (!(image || imageFile)) {
      error.image = "Image required";
    }
    // if (Object.keys(error)?.length) {
    //   setError(error);
    //   return false;
    // } else {
    //   setError({});
    //   return true;
    // }
    return error
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const validationObject = validation()
      if (bulkCategories.bulkData.length == 0) {
        if (Object.keys(validationObject).length > 0) {
          setError(validationObject)
          setSubCategoryError({
            ...subCategoryError,
            data: {
              ...subCategoryError?.data,
              name: validationObject?.name,
              commission: validationObject?.commission,
              discount: validationObject?.discount,
              image: validationObject?.image
            }
          }
          )
        } else {
          const fd = new FormData();
          // fd.append("activated", true);
          if (subCategory?.data?.name) {
            fd.append("name", subCategory?.data?.name);
          }
          if (subCategory?.data?.commission) {
            fd.append("commission", subCategory?.data?.commission);
          }
          if (subCategory?.data?.discount) {
            fd.append("discount", subCategory?.data?.discount);
          }
          if (subCategory?.data?.imageFile) {
            fd.append("image", subCategory?.data?.imageFile);
          }
          if (subCategory?.data?._id) {
            fd.append("_id", subCategory?.data?._id);
          }
          setSubCategory({ ...subCategory, loader: true });
          const res = await postTokenApi(HIBEE_PRODUCT_URL + CATEGORY_SUB_PATH + `/?categoryId=${category?._id}`, fd, { "Content-Type": "multipart/form-data" });
          if (res.status == 200) {
            localStorage.setItem('subCategoryStatus', true)
            localStorage.setItem('subCategorySort', 'name')
            setSubCategory({ ...subCategory, loader: false, show: false });
            getData();
          }
        }
      } else {
        const updatedPayload = bulkCategories?.bulkData.map((ele, ind) => {
          return {
            name: ele?.CateogoryName,
            activated: false,
            commission: ele?.Commission,
            discount: ele?.Discount,
            // image: ele.Image,
            category_id: category?._id
          }
        })

        const payload = {
          data: updatedPayload
        }
        setSubCategory({ ...subCategory, loader: true })
        // setBulkCategories({ ...bulkCategories, error: "please select file" })
        // + `/${category?._id}`

        const res = await postTokenApi(HIBEE_PRODUCT_URL + BULK_SUB_CATEGORY_PATH, payload);
        if (res.status == 200) {
          handleClearSubCategory()
          setBulkCategories({ ...bulkCategories, bulkData: [] })
          getData();
        }
      }

    } catch (err) {
      localStorage.setItem('subCategoryStatus', true)
      localStorage.setItem('subCategorySort', 'name')
      const message = err?.response?.data?.message || "Something went wrong";
      toast.error(message, { position: "top-center", autoClose: 2000 });
    }
  };

  const deleteSubCategory = async (id) => {
    setActionModal({ ...actionModal, loader: true });
    const res = await deleteTokenApi(HIBEE_PRODUCT_URL + CATEGORY_SUB_PATH + `/${id}`);
    setActionModal({});
    getData();
  };
  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const archieveSubCategory = async (id) => {
    setActionModal({ ...actionModal, loader: true });
    setActionModal({});
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const updateSort = (e) => {
    setSort(e.target.value);
  };

  const getSearchedData = useMemo(() => debounce(async (search, updateType, sort) => {
    getData(search, updateType, sort);
  }, 500), []);

  // useEffect(() => {
  //   getData();
  // }, [sort]);

  // useEffect(() => {
  //   getData();
  // }, [updateType]);

  useEffect(() => {
    getSearchedData(query, updateType, sort);
  }, [query, updateType, sort]);

  useEffect(() => {
    if (Object.keys(error)?.length) {
      validation();
    }
  }, [error]);

  useEffect(() => {
    getCategories();
  }, []);

  /** Bulk Data **/
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setBulkCategories({ ...bulkCategories, error: '', ele: '' })
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        // setError("Please input a csv file");
        setBulkCategories({ ...bulkCategories, error: 'Please input a csv file' })
        return;
      }
      // setFile(inputFile);
      // if (!file) return setBulkCategories({ ...bulkCategories, error: "Enter a valid file" });
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        const one = csv.data.slice(0, csv.data.length - 1)
        const two = one.map((ele, ind) => {
          return {
            // Sl.N :
            CateogoryName: ele.SubCateogoryName,
            Commission: ele.Commission,
            Discount: ele.Discount,
          }
        })

        setBulkCategories({ ...bulkCategories, bulkData: two, error: '' })


        // setBulkCategories({ ...bulkCategories, bulkData: csv.data.slice(0, csv.data.length - 1), error: '' })
      };
      reader.readAsText(inputFile);
    }
  };

  const handleClearSubCategory = () => {
    setSubCategory({
      ...subCategory,
      show: false,
      loader: false,
      data: {
        ...subCategory.data,
        name: "",
        commission: "",
        discount: "",
        image: ""
      }
    })
    setSubCategoryError({
      ...subCategoryError,
      show: false,
      loader: false,
      data: {
        ...subCategoryError.data,
        name: "",
        commission: "",
        discount: "",
        image: ""
      }
    })
  }

  useEffect(() => {
    if (bulkCategories.bulkData.length != 0) {
      setError({})
      setSubCategoryError({
        ...subCategoryError,
        show: false,
        loader: false,
        data: {
          ...subCategoryError.data,
          name: "",
          commission: "",
          discount: "",
          image: ""
        }
      })
    }
  }, [bulkCategories])

  const approveAdmin = async (ele, id) => {
    setAprove({ ...aprove, status: ele, index: id })
    // Path Params
    const subcategoryId = id
    const status = ele
    localStorage.setItem('subCategoryStatus', status)
    try {
      const res = await putTokenApi(HIBEE_PRODUCT_URL + `/category/subvisiblity/${subcategoryId}?activated=${status}`)
      if (res.status == 200) {
        const newStatus = localStorage.getItem('subCategoryStatus')
        localStorage.setItem('subCategoryStatus', newStatus == 'true' ? false : true)
        if (res?.data?.status == 'activated') {
          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        if (res?.data?.status == 'disactivated') {
          toast.error(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
        }
        getData()
      }
    } catch (err) {
      const newStatus = localStorage.getItem('subCategoryStatus')
      localStorage.setItem('subCategoryStatus', newStatus == 'true' ? false : true)
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      })
      getData()
    }
  }

  // const handleTowerNameChange = (towerIndex, newName) => {
  //   const updatedTowers = [...towersList];
  //   updatedTowers[towerIndex].towerName = newName;
  //   setTowersList(updatedTowers);
  // };

  const handleCheckSubcategory = (ele, ind) => {
    const updatedTowers = [...bulkCategories.bulkData];
    // console.log(updatedTowers[ind]);
    if (updatedTowers[ind].CatagoryId == '') {
      updatedTowers[ind].sid = ind + 1;
      updatedTowers[ind].CatagoryId = '';
      updatedTowers[ind].selectedCateName = '';
      updatedTowers[ind]['checkbox'] = !ele.checkbox
      setBulkCategories((prevState) => ({
        ...prevState,
        bulkData: updatedTowers
      }))
    }

  }
  const handleSelectChategory = (event, type) => {
    const parseData = JSON.parse(event.target.value)
    console.log(parseData.name, 'event');
    const updatedPayload = bulkCategories?.bulkData.map((ele, ind) => {
      if (ele.sid !== "" && ele.selectedCateName == "") {
        return {
          ...ele,
          CateogoryName: ele.SubCateogoryName,
          activated: true,
          Commission: ele.Commission,
          Discount: ele.Discount,
          CatagoryId: ele.sid ? parseData._id : '',
          CatagoryId: ele.sid ? event.target.value : '',
          selectedCateName: parseData.name,
          sid: ele.sid
        };
      }
      return ele;
    })

    setBulkCategories((prevState) => ({
      ...prevState,
      bulkData: updatedPayload
    }))
  }

  const handleRemoveCategory = (el, ind) => {
    const updatedTowers = [...bulkCategories.bulkData];
    updatedTowers[ind].sid = ind + 1;
    updatedTowers[ind].CatagoryId = "";
    updatedTowers[ind].selectedCateName = ""
    setBulkCategories((prevState) => ({
      ...prevState,
      bulkData: updatedTowers
    }))
  }

  const handleClearSelection = () => {
    const newData = bulkCategories.bulkData.map(item => ({
      ...item,
      checkbox: false,
    }));
    setBulkCategories((prevState) => ({
      ...prevState,
      bulkData: newData
    }))
    setMainCheck(false)
  }

  const handleResetCategory = () => {
    const newData = bulkCategories.bulkData.map(item => ({
      ...item,
      sid: "",
      CatagoryId: "",
      selectedCateName: "",
      checkbox: false
    }));
    setBulkCategories((prevState) => ({
      ...prevState,
      bulkData: newData
    }))
    setMainCheck(false)
  }

  const handleCheckHeaderCkeckBox = (ele) => {
    if (mainCheck) {
      const newData = bulkCategories.bulkData.map((item, ind) => ({
        ...item,
        sid: '',
        checkbox: false,
      }));
      setBulkCategories((prevState) => ({
        ...prevState,
        bulkData: newData
      }))
      setMainCheck(!mainCheck)
    } else {
      const newData = bulkCategories.bulkData.map((item, ind) => ({
        ...item,
        sid: ind + 1,
        checkbox: true,
      }));
      setBulkCategories((prevState) => ({
        ...prevState,
        bulkData: newData
      }))
      setMainCheck(!mainCheck)
    }
  }

  const handlePreview = () => {
    // setBulkCategories({ ...bulkCategories, show: true })

    var requiredKeys = ["CateogoryName", "Commission", "Discount"];
    var hasAllKeysAndValues = true;

    for (var i = 0; i < bulkCategories.bulkData.length; i++) {
      var obj = bulkCategories.bulkData[i];

      if (!obj.hasOwnProperty("CateogoryName") || !obj.CateogoryName) {
        hasAllKeysAndValues = false;
        break;
      }

      for (var j = 0; j < requiredKeys.length; j++) {
        if (!obj.hasOwnProperty(requiredKeys[j])) {
          hasAllKeysAndValues = false;
          break;
        }
      }

      // Check for any additional keys in the object
      var extraKeys = Object.keys(obj).filter(function (key) {
        return !requiredKeys.includes(key);
      });

      if (extraKeys.length > 0) {
        hasAllKeysAndValues = false;
        break;
      }
    }

    if (!hasAllKeysAndValues) {
      // console.log("Error: Some objects are missing required keys, have empty values, or contain extra keys.");
      toast.error("Error: Some objects are missing required keys, have empty values, or contain extra keys.", {
        position: "top-center",
        autoClose: 2000,
      });
    } else {
      setBulkCategories({ ...bulkCategories, show: true })
    }
  }

  /** filter **/
  useEffect(() => {
    localStorage.setItem('subCategoryStatus', true)
  }, [])

  useEffect(() => {
    const data = localStorage.getItem('subCategoryStatus')
    setUpdateType(data)
  }, [updateType])


  return (
    <MainTemplate
      categoryName={category && category?.name}
      categoryList={["Admin", "Categories", category && category?.name]}
    >
      <div className="row mb-4 rounded p-0  bg-white">

        <div className="m-3 p-4 mx-0 mt-0 rounded bg-white ">
          <div className="row border p-4 rounded">
            <div className="col-lg-3 pe-2 p-2 bg-light-blue d-flex justify-content-center categoryDetailImageConatiner-sub">
              <img
                src={category?.image}
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "fill" }}
              />
            </div>
            <div className="col-lg-6  pe-3">
              <div className="mt-2 font-weight-bold t-16">
                {category?.name}
              </div>
              <div className="t-16">
                Total Sub Category:{" "}
                <span className="font-weight-bold">
                  {category?.SubCategorys?.length} sub categories
                </span>
              </div>
              <div className="t-16">
                Total Products:{" "}
                <span className="font-weight-bold">
                  {" "}
                  {category?.ProductCount} products
                </span>
              </div>
            </div>
            <div className="col-lg-3 p-0 pe-3 d-flex justify-content-between align-items-center t-12">
              <span
                className="d-flex align-items-center cursorPointer"
              // onClick={() => setModal(true)}
              >
                {/* <svg
                    className="bi bi-pencil border-bottom border-dark"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                  </svg> */}
                {/* <span className="mx-1">Edit</span> */}
              </span>
              <button
                type="button"
                // className="btn btn-outline-dark"
                className="btn text-warning"
                style={{ backgroundColor: "#12352f" }}
                onClick={() => {
                  setBulkCategories({ ...bulkCategories, bulkData: [], error: '', ele: '' })
                  setSubCategory({ show: true, data: { categoryId }, loader: false })
                }
                }
              >
                Add Sub Category
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-4 rounded p-2  bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={updateSort}
          >
            <option value="" selected>
              Sort by
            </option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select
            className="t-12 form-select"
            aria-label="Default select example"
            onChange={(e) => setItemsPerPage(e.target.value)}
          >
            <option value="10" selected>
              10 per page
            </option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example"
            onChange={e => {
              setUpdateType(e.target.value === "true" ? true : false)
              localStorage.setItem('subCategoryStatus', e.target.value === "true" ? true : false)
            }}

          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input
            type="text"
            name="query"
            className="t-12 form-control"
            placeholder="Search"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="d-none d-md-block col-md-1"></div>
        {/* <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn btn-outline-dark"
            onClick={() => {
              setModal(true);
              setCategory({});
            }}
          >
            Add Category
          </button>
        </div> */}
        {/* <div className="col-sm-6 my-3 col-md-2">
          <button
            type="button"
            className="t-12 w-100 btn btn-outline-dark"
            onClick={() => {
              setModal1(true);
              setcategory({});
            }}
          >
            Add Images
          </button>
        </div> */}
      </div>

      {/* <div className="row mb-4 rounded p-2  bg-white">
        <div className="col-lg-12 p-0 pe-3">
          <div className="row font-weight-bold t-12 mb-3 px-3"> */}

      <div className="row flex-column align-items-center">
        <div className="row  rounded text-center bg-white">
          {/* header */}
          <div className="row m-0">
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 px-3 col-md-1">
              Photo
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Sub Category Name
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
              Discount
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Commission
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Product Count
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Edit
            </div>
            <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
              Active{" "}/{" "}Inactive
            </div>
          </div>

          {/* body */}
          <div className="justify-content-center align-items-center overflow-auto caterogyPageScroll">
            {
              currentItems && currentItems?.length != 0
                ?
                currentItems?.map((el) => (
                  <div
                    className="row border rounded m-0 mb-2 d-flex align-items-center"
                    key={el?._id}
                  >
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      <div className="categoryImageDiv shadow-sm  rounded p-1">
                        <Link to={`/products/${el?._id}`} state={{ data: categoryId, edit: true }}>
                          <img src={el?.image ? el?.image : imagenotavailable} alt="loading" className="categoryImage h-100 rounded" />
                        </Link>
                      </div>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <Link
                        to={`/products/${el?._id}`}
                        state={{ data: categoryId, edit: true }}
                        // className="text-dark "
                        style={{ textDecoration: "none" }}
                      >
                        {el?.name}
                      </Link>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-1">
                      {el?.discount}
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      {el?.commission}
                    </div>

                    <div className="t-16 text-uppercase my-3 col-md-2">
                      {el?.ProductCount} Products
                    </div>

                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <svg
                        className="bi bi-pencil border-bottom border-dark"
                        // onClick={() => {
                        //   setModal(true);
                        //   setCategory(el);
                        // }}
                        onClick={() =>
                          setSubCategory({
                            ...subCategory,
                            show: true,
                            data: el,
                          })
                        }
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                      </svg>
                    </div>
                    <div className="t-12 text-uppercase my-3 col-md-2">
                      <div className="m-auto" >
                        <label className="switch">
                          <input className="switch-input" type="checkbox"
                            checked={el.activated}
                            onChange={e => e.target.checked ? approveAdmin(e.target.checked, el?._id) : approveAdmin(e.target.checked, el?._id)}

                          />
                          <span className="switch-label"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>
                    {/* <div className="t-16 my-3 col-md-1">
                  <div class="dropdown">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      data-menu-target={`drop-down-menu-${el?._id}`}
                      className="bi bi-three-dots dropdown-btn cursorPointer"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                    <div
                      id={`drop-down-menu-${el?._id}`}
                      className="dropdown-content p-2"
                    >
                      <div
                        className="actionMenu rounded px-3 py-2 d-flex cursorPointer"
                        onClick={() =>
                          setSubCategory({
                            ...subCategory,
                            show: true,
                            data: el,
                          })
                        }
                      >
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </span>
                        <span className="mx-2">
                          <div className="font-weight-bold">Edit</div>
                          <div className="">Edit this product</div>
                        </span>
                      </div>
                      <div
                        className="actionMenu rounded px-3 py-2 d-flex cursorPointer"
                        onClick={() =>
                          setActionModal({
                            ...actionModal,
                            show: true,
                            data: el,
                            type: "delete",
                          })
                        }
                      >
                        <span className="mx-2 d-flex align-items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash3"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                        </span>
                        <span className="mx-2">
                          <div className="font-weight-bold">Delete</div>
                          <div className="">Delete this product</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </div>
                )) :
                <div className="text-center p-5 homepageModifierCategoryScroll">
                  <div className="" style={{ marginTop: "100px" }} >
                    <b className="empty-product-list" >No Sub Category have been added to this Category</b>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>

      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>

      {/* Sub Catgory model */}
      <Modal
        isOpen={subCategory?.show}
        onRequestClose={() => setSubCategory({ data: {} })}
        style={customModalStyles}
        contentLabel="SubCategory Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            {
              subCategory?.data?._id
                ?
                <h4>Update Sub Category Form</h4>
                :
                <h4>Add Sub Category Form</h4>
            }
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={handleClearSubCategory}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Sub Category Name <span className="text-danger" >*</span></label>
              <input
                name="name"
                value={subCategory?.data?.name}
                onChange={(e) => {
                  setSubCategory({
                    ...subCategory,
                    data: { ...subCategory?.data, name: e.target.value },
                  })
                  setSubCategoryError({ ...subCategoryError, data: { ...subCategoryError.data, name: "" } })
                }
                }
                type="text"
                className={`placeholder-light form-control my-2 py-2 px-3 ${subCategoryError.data?.name && "border-danger"
                  }`}
                placeholder="Enter sub category name here..."
                autoComplete='off'
              />
              <span className="text-danger">{subCategoryError?.data.name}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group my-2">
              <label className="">Discount</label>
              <input
                name="discount"
                value={subCategory?.data?.discount}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                    setSubCategory({
                      ...subCategory,
                      data: { ...subCategory?.data, discount: e.target.value },
                    })
                    setSubCategoryError({ ...subCategoryError, data: { ...subCategoryError.data, discount: "" } })
                  }
                }
                }
                maxLength={3}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
                type="number"
                autoComplete="off"
                className={`placeholder-light form-control my-2 py-2 px-3 remove-spinner ${subCategoryError?.data?.discount && "border-danger"}`}
                placeholder="Enter discount here..."
                onWheelCapture={(e) => e.target.blur()}
              />
              <span className="text-danger">{subCategoryError?.data?.discount}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Commission</label>
              <input
                name="commission"
                type='number'
                value={subCategory?.data?.commission}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                    setSubCategory({
                      ...subCategory,
                      data: { ...subCategory?.data, commission: e.target.value },
                    })
                    setSubCategoryError({ ...subCategoryError, data: { ...subCategoryError.data, commission: "" } })
                  }
                }
                }
                maxLength={3}
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength) {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }
                }}
                className={`placeholder-light form-control my-2 py-2 px-3 remove-spinner ${subCategoryError?.data?.commission && "border-danger"}`}
                placeholder="Enter commission here..."
                onWheelCapture={(e) => e.target.blur()}
                autoComplete='off'
              />
              <span className="text-danger">{subCategoryError?.data?.commission}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group my-2">
              <label className="">Upload Image <span className="text-danger" >*</span></label>
              <input
                name="image"
                type="file"
                onChange={(e) => {
                  setSubCategory({
                    ...subCategory,
                    data: {
                      ...subCategory?.data,
                      image: "",
                      imageFile: e.target?.files?.[0],
                    },
                  })
                  setSubCategoryError({ ...subCategoryError, data: { ...subCategoryError.data, image: "" } })
                }
                }
                accept="image/png, image/gif, image/jpeg"
                className="py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3"
                placeholder="eg: https://www.apple.com/in/watch"
              />
              <div
                className={`my-2 py-2 select-text-light d-flex align-items-center px-3 border rounded ${subCategoryError?.data?.image && "border-danger"}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cloud-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                  />
                  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                </svg>
                <span className="px-2">Upload image here...</span>
              </div>
              <span className="text-danger">{error?.image}</span>
              {subCategory?.data?.image ||
                (subCategory?.data?.imageFile &&
                  URL.createObjectURL(subCategory?.data?.imageFile)) ? (
                <div className="d-flex" >
                  <div className="d-flex col-2" >
                    <img
                      id="categoryPageModalImage"
                      src={
                        subCategory?.data?.image ||
                        URL.createObjectURL(subCategory?.data?.imageFile)
                      }
                      alt=""
                      className="rounded shadow-sm mt-2"
                      style={{
                        width: "100%",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: '5%'
                      }}
                    />
                    <div
                      className="image-containerr-sub-category"
                      onClick={() => setSubCategory({ ...subCategory, data: { ...subCategory.data, imageFile: "", imagePreview: "", image: "" } })}
                    >
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <span className="text-danger">{subCategoryError?.data?.image}</span>
            </div>
          </div>
          <div>


            <div>
              {
                subCategory?.data?._id
                  ?
                  ""
                  :
                  <div>
                    <p className="d-flex justify-content-center">or</p>
                    <h6>Uploading many categories at once?</h6>
                    <p className="select-text-light">
                      Upload a file in a .csv format{" "}
                      <span
                        className="text-success fw-bold"
                        role="button"
                        onClick={() => downloadFile(BulkSubCategoryCsv)}
                      >
                        Download sample format here
                      </span>
                    </p>

                    {/* Bulk Upload  */}
                    <input
                      name="file"
                      type="file"
                      autoComplete="off"
                      accept=".csv"
                      onChange={handleFileChange}
                      value={bulkCategories.ele}
                      className={`py-2 opacity0 position-absolute  w-25 placeholder-light form-control py-2 px-3 border`}
                      placeholder="eg: https://www.apple.com/in/watch"
                    />

                    <div className="d-flex" >
                      <div className={`my-2 col-5 py-2 select-text-light w-25 d-flex align-items-center px-3 border rounded ${bulkCategories?.error != '' ? "border-danger" : ''}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cloud-arrow-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                          />
                          <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                        </svg>
                        <span className="px-2">Upload</span>
                      </div>
                      <div className="col-1" >
                      </div>
                      <div className="col-6 form-group my-2" >
                        <button className="btn btn-dark text-warning px-5"
                          style={{ backgroundColor: "#12352f" }}
                          onClick={() => {
                            bulkCategories.bulkData.length != 0
                              ?
                              handlePreview()
                              :
                              setBulkCategories({ ...bulkCategories, error: 'select' })
                          }}
                        > Preview</button>
                      </div>
                    </div>
                  </div>

              }
              <div className="row justify-content-md-start" >
                <div className="col-3" >
                  {
                    bulkCategories.bulkData.length != 0
                      ?
                      <div className="bg-danger rounded categoryImageDiv shadow-sm " style={{ width: '80px', height: '50px' }} >
                        <div className="image-containerr-excell" onClick={() => setBulkCategories({ ...bulkCategories, bulkData: [], error: '', ele: '' })} >
                        </div>
                        <img src={excell} className='h-100 rounded' />
                      </div>
                      :
                      ''
                  }
                </div>
              </div>

              {/* bulk model */}
              <Modal
                isOpen={bulkCategories.show}
                onRequestClose={() => setBulkCategories({ ...bulkCategories, show: false })}
                style={customModalStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
              >
                <div className="px-3 py-3">
                  <div className="row ">
                    <h4 className="font-weight-bold t-16 text-uppercase" >Bulk Sub Categorys preview</h4>
                    <span className="d-flex justify-content-end cursorPointer">
                      <svg
                        onClick={() => {
                          setBulkCategories({
                            ...bulkCategories,
                            show: false,
                            bulkData: [],
                            error: "",
                            ele: ""
                          })
                        }
                        }
                        className="d-inline"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                      </svg>
                    </span>
                  </div>
                  {/* <div className="row  justify-content-end" style={{ marginRight: '20px' }} >
                    <div className="col-3" >
                      <Form.Select
                        controlId="Product category"
                        type="text"
                        placeholder="Enter select product category"
                        name="category"
                        onChange={handleSelectChategory}
                        autoComplete="off"
                      >
                        <option value=''> Select category </option>
                        {categories?.map((el, index) => (
                          <option
                            key={index}
                            value={JSON.stringify(el)}
                          >
                            {el?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div> */}


                  <div className="row">
                    <div>
                      <div className="row rounded px-3 bg-white">
                        {/* header */}
                        <div className="row m-0  text-center">
                          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Sl.No
                          </div>
                          {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-1">
                            <input type='checkbox' style={{ marginLeft: '10px' }} checked={mainCheck} onChange={handleCheckHeaderCkeckBox} />
                          </div> */}
                          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Sub Category Name
                          </div>
                          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Discount
                          </div>
                          <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-3">
                            Commission
                          </div>
                          {/* <div className="font-weight-bold t-12 text-uppercase p-0 my-3 col-md-2">
                            Selected Category
                          </div> */}
                        </div>

                        {/* body */}
                        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll  text-center">
                          {
                            bulkCategories?.bulkData?.map((el, ind) => (
                              <div
                                className="row border rounded m-0 px-2  mb-2 d-flex align-items-center"
                                key={el?._id}
                              >
                                {/* <div className="t-12 text-uppercase my-3 col-md-3">
                                  <div className="categoryImageDiv shadow-sm  rounded p-1">
                                    <img
                                      src={el?.Image}
                                      className="categoryImage h-100 rounded"
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="t-12 text-uppercase my-3 col-md-1">
                                  <input name={`checkbox${ind}`} checked={el?.checkbox} onChange={() => handleCheckSubcategory(el, ind)} type='checkbox' />
                                </div> */}
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                  {ind + 1}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                  {el?.CateogoryName}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                  {el?.Discount}
                                </div>
                                <div className="t-12 text-uppercase my-3 col-md-3">
                                  {el?.Commission}
                                </div>
                                {/* <div className="d-flex t-12 text-uppercase my-3 col-md-2">
                                  {
                                    el.selectedCateName
                                      ?
                                      <div className="d-flex">
                                        <p>{el?.selectedCateName}</p>
                                        <div >
                                          <svg onClick={() => handleRemoveCategory(el, ind)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                          </svg>
                                        </div>
                                      </div>
                                      :
                                      'not assigned category'
                                  }
                                </div> */}
                              </div>
                            ))
                          }
                        </div>

                        <div className="row" >
                          <div className="d-flex " >
                            <button
                              // disabled={subCategory?.loader}
                              className="btn text-warning px-5"
                              style={{ backgroundColor: "#12352f" }}
                              onClick={submit}
                            >
                              Submit{" "}
                              {
                                subCategory?.loader ?
                                  <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                  :
                                  ''
                              }
                            </button>
                            <button
                              className="btn btn-white text-dark shadow-lg  px-5"
                              style={{ marginLeft: "10px", backgroundColor: "#FFFFFF", color: '#000000', boxShadow: '0 23.486127853393555px 0 rgba(0, 0, 0, 0.25)' }}
                              onClick={() =>
                                setBulkCategories({
                                  ...bulkCategories,
                                  show: false,
                                  bulkData: [],
                                  error: "",
                                  ele: ""
                                })
                              }
                            >
                              Cancel
                            </button>
                            {/* <div className="form-group my-3"
                              style={{ marginLeft: '10px' }}
                            >
                              <button
                                disabled={subCategory?.loader}
                                className="btn btn-dark text-warning px-5"
                                onClick={handleClearSelection}
                              >
                                Clear Selection
                              </button>
                            </div> */}
                            {/* <div
                              className="form-group my-3"
                              style={{ marginLeft: '10px' }}
                            >
                              <button
                                disabled={subCategory?.loader}
                                className="btn btn-dark text-warning px-5"
                                onClick={handleResetCategory}
                              >
                                Reset Categories
                              </button>
                            </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

            </div>
          </div>
          {
            bulkCategories.bulkData.length == 0
              ?
              <div className="form-group my-3">
                <button
                  className="btn  text-warning px-5"
                  onClick={submit}
                  style={{ backgroundColor: "#12352f" }}
                >
                  Submit{" "}
                  {
                    subCategory?.loader ?
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                      </div>
                      :
                      ''
                  }
                </button>
              </div>
              :
              ''
          }
        </div>
      </Modal>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div className="px-5 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setModal(false)}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="modalMaxHeight">
            <div className="row">
              <div className="form-group my-2">
                <label className="my-1">Upload Image</label>
                <input
                  type="file"
                  className="py-2 opacity0 position-absolute placeholder-light form-control py-2 px-3"
                  placeholder="eg: https://www.apple.com/in/watch"
                />
                <div className="my-1 py-2 select-text-light d-flex align-items-center px-3 border rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cloud-arrow-up"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"
                    />
                    <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z" />
                  </svg>
                  <span className="px-2">Upload your image file</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>ID:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="text"
                  placeholder="#654"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Price:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="₹"
                  onWheelCapture={(e) => e.target.blur()}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Cutout Price:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="₹"
                  onWheelCapture={(e) => e.target.blur()}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Size:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Select in gm</option>
                  <option value="1">100gm</option>
                  <option value="2">150gm</option>
                  <option value="3">500gm</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Size:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="Qty"
                  onWheelCapture={(e) => e.target.blur()}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Shelf Life:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control my-1 py-2 px-3"
                  type="number"
                  placeholder="Days"
                  onWheelCapture={(e) => e.target.blur()}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Commisions:</span>
              </div>
              <div className="col-6">
                <input
                  className="placeholder-light form-control  my-1 py-2 px-3"
                  type="number"
                  placeholder="In %"
                  onWheelCapture={(e) => e.target.blur()}

                />
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Category:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Fruit & Vegetable</option>
                  <option value="1">Dairy</option>
                  <option value="2">Chips</option>
                  <option value="3">Beverage</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-6 d-flex align-items-center">
                <span>Sub Category:</span>
              </div>
              <div className="col-6">
                <select
                  className="p-2 px-3 my-1 form-select select-text-light"
                  aria-label="Default select example"
                  outline="none"
                >
                  <option selected>Fruits</option>
                  <option value="1">Vegetable</option>
                  <option value="2">Fruits</option>
                  <option value="3">Root vegetables</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="form-group my-2">
                <label className="font-weight-bold my-1">Description</label>
                <textarea
                  className="placeholder-light form-control py-2 px-3"
                  placeholder="Notification Description"
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="form-group my-3">
            <button
              className="btn  text-warning px-5"
              style={{ backgroundColor: "#12352f" }}
            >Submit</button>
          </div>
        </div>
      </Modal>

      {/* Action Model */}
      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} Category?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() =>
                actionModal?.type === "delete"
                  ? deleteSubCategory(actionModal?.data?._id)
                  : archieveSubCategory(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </MainTemplate >
  );
}

export default CategoryDetail;
