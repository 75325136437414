import React, { useEffect } from "react";
import Row from "react-bootstrap/Row";
import "./InventoryProductAddForm.css";
import placeholder from "../../assets/images/placeholder.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import Barcode from "react-jsbarcode";
import Modal from "react-modal";
import { BsUpload } from "react-icons/bs";
import MainTemplate from "../../components/MainTemplate";
import { Button, Form } from "react-bootstrap";
import { getApi, getTokenApi, postTokenApi, putTokenApi } from "../../api_interface/apis";
import { CATEGORY_PATH, CATEGORY_SUB_LIST_PATH, HIBEE_PRODUCT_URL, Nativity, PRODUCT_IMAGE_UPDATE, PRODUCT_PATH } from "../../api_interface/apiURLs";
import { custombulkModalStyles } from "../../utils/helperFunctin";
import BulkProductCsv from "../../assets/BulkProduct.csv";
import excell from "../../assets/images/excell.jpg";
import Papa from "papaparse";
import { Spinner } from "react-bootstrap";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import { RiImageAddLine } from "react-icons/ri"

function InventoryProductAddForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location?.state?.data;
  const dataid = location?.state?.id;

  const isEditing = location?.state?.edit;
  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [nativity, setNativity] = useState([]);

  const [subCategories, setSubCategories] = useState([]);
  const [product, setProduct] = useState()
  const [status, setStatus] = useState("")
  const [sizeList, setSizeList] = useState(
    {
      size: "",
      unit: "kg",
    })
  const getData = async () => {
    if (dataid) {

      const res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?productId=${dataid}`)
      if (res?.data?.data) {
        setProduct(res?.data?.data)
        // setSelectedImage(res.data.data.image1)
      }
    }
  }
  useEffect(() => {
    if (dataid) {
      getData()
    }
  }, [])


  const [inventoryData, setInventoryData] = useState(
    data?.comingdata ?
      {
        hsn_code: "",
        product_codes: "",
        product_name: "",
        // product_variant: "",
        // manufacture_name: "",
        // manufacturing_date: "",
        // expiration_date: "",
        // qty: "",
        sizeList: "",
        images: "",
        category: data?.comingdata ? data?.comingdata : "",
        subcategory: data?.subCategoryId ? data?.subCategoryId : "",
        type: "",
        batch: "",
        image: "",
        discount: "",
        franchisediscount: "",
        superfastdiscount: "",
        tax: "",
        price: "",
        commission: "",
        description: "",
        viewImage: "",
        cess: "",
        nativity: "",
      }
      : {
        product_codessssssss: "",
        hsn_code: "",
        product_name: "",
        // product_variant: "",
        // manufacture_name: "",
        // manufacturing_date: "",
        // expiration_date: "",
        // qty: "",
        sizeList: "",
        images: "",
        category: "",
        subcategory: "",
        type: "",
        batch: "",
        image: "",
        discount: "",
        franchisediscount: "",
        superfastdiscount: "",
        tax: "",
        price: "",
        commission: "",
        description: "",
        viewImage: "",
        cess: "",
        nativity: "",
      }
  )
  const [comingImage, setComingImage] = useState();
  useEffect(() => {
    if (product) {
      setInventoryData({
        product_code: product?.product_code || "656",
        hsn_code: product?.hsn_code || "",
        product_name: product?.product_name || "",
        // sizeList: product?.sizeList || [], // Assuming sizeList should be an empty array if undefined
        category: product?.category?._id || "",
        subcategory: product?.subcategory?._id || "",
        type: product?.type || "",
        batch: product?.batch || "",
        images: product?.images || "",
        discount: product?.discount || "",
        franchisediscount: product?.franchiseDiscount || "",
        superfastdiscount: product?.superfastDiscount || "",
        tax: product?.cgst * 2 || "",
        price: product?.price || "",
        commission: product?.commission || "",
        description: product?.description || "",
        viewImage: product?.viewImage || "",
        cess: product?.cess || "",
        nativity: product?.nativity?._id || ""
      });
      setComingImage(product?.images || "");
      setQuery(product?.nativity?.name)
      setSizeList(product?.sizeList)
    }
  }, [product]);

  const [productImage, setProductImage] = useState([]);


  console.log(sizeList, "jhjh");
  const [inventoryDataError, setInventoryDataError] = useState({});

  /**Handle Validation **/
  const handleValidation = () => {
    const newErrors = {};

    if (!inventoryData?.product_code) {
      newErrors.product_code = "Please enter unique code";
    }

    if (!inventoryData?.product_name) {
      newErrors.product_name = "Please enter product name";
    }
    if (!inventoryData?.hsn_code) {
      newErrors.hsn_code = "Please enter HSN Code";
    }
    // if (!inventoryData?.product_variant) {
    //   newErrors.product_variant = "Please enter product variant";
    // }
    // if (!inventoryData?.manufacture_name) {
    //   newErrors.manufacture_name = "Please enter manufacture name";
    // }
    // if (!inventoryData?.manufacturing_date) {
    //   newErrors.manufacturing_date = "Please select product MFG date";
    // }
    // if (!inventoryData?.expiration_date) {
    //   newErrors.expiration_date = "Please select product expiry date";
    // }
    // if (!inventoryData?.qty) {
    //   newErrors.qty = "Please enter qty name";
    // }
    // if (!inventoryData?.warehouse) {
    //   newErrors.warehouse = "Please select warehouse";
    // }
    if (!sizeList?.size) {
      newErrors.size = "Please select size";
    }
    if (!inventoryData?.category) {
      newErrors.category = "Please select category";
    }
    if (!inventoryData?.subcategory) {
      newErrors.subcategory = "Please select sub category";
    }
    // if (!inventoryData?.type) {
    //   newErrors.type = "Please enter storage type";
    // }
    // if (!inventoryData?.batch) {
    //   newErrors.batch = "Please enter product batch";
    // }
    if (!product) {
      if (productImage.length == 0) {
        newErrors.image = "Please upload product Image";
      }
    }
    // if (!inventoryData?.price) {
    //   newErrors.price = "Please enter price";
    // }
    if (!inventoryData?.discount) {
      newErrors.discount = "Please Add Discount";
    }
    if (inventoryData?.discount > 100) {
      newErrors.discount = "";
    }
    if (!inventoryData?.franchisediscount) {
      newErrors.franchisediscount = "Please Add Franchise Discount";
    }
    if (inventoryData?.franchisediscount > 100) {
      newErrors.franchisediscount = "";
    }
    if (!inventoryData?.superfastdiscount) {
      newErrors.superfastdiscount = "Please Add Superfast Discount";
    }
    if (inventoryData?.superfastdiscount > 100) {
      newErrors.superfastdiscount = "";
    }
    if (!inventoryData?.tax) {
      newErrors.tax = "Please enter Tax Amount";
    }
    if (!inventoryData?.price) {
      newErrors.price = "Please enter MRP";
    }
    if (!inventoryData?.commission) {
      newErrors.commission = "Please enter commission";
    }

    // if (!inventoryData?.description) {
    //   newErrors.description = "Please enter description";
    // }
    if (!inventoryData?.cess) {
      newErrors.cess = "Please enter cess";
    }

    if (Object.keys(newErrors)?.length) {
      setInventoryDataError(newErrors);
      return false;
    } else {
      setInventoryDataError({});
      return true;
    }
  };

  /** haandle change **/
  const handleInventorychange = (event, type) => {
    if (type !== "image") {
      setInventoryData({
        ...inventoryData,
        [event.target.name]: event.target.value,
      });
      setInventoryDataError({
        ...inventoryDataError,
        [event.target.name]: null,
      });
    } else {
      const files = Array.from(event.target.files);
      const urls = files.map((file) => URL.createObjectURL(file));

      setProductImage([...productImage, ...files]);
      setInventoryData({
        ...inventoryData,
        [event.target.name]: urls,
      });
      setInventoryDataError({
        ...inventoryDataError,
        [event.target.name]: null,
      });
    }
  };

  // const [productImages, setProductImages] = useState([]);
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // setProductImages([...productImages, file]);
  };
  const handleImageDelete = (ele, index) => {
    // setInventoryData({ ...inventoryData, viewImage: index })
    const newImages = [...productImage];
    newImages.splice(index, 1);
    setProductImage(newImages);
    if (productImage.length == 0) {
      setInventoryData({ ...inventoryData, image: "" });
    }
  };


  const handleBulkSubmitInventory = async (e) => {
    e.preventDefault();
    try {
      if (bulkProducts.bulkData.length === 0) {
        // Handle the case when bulkData is empty
      } else {
        setLoader(true);

        const updatedPayload = bulkProducts.bulkData.map((ele) => {
          return {
            product_code: ele.uniqueCode,
            product_name: ele.productName,
            hsn_code: ele.hsnCode,
            discount: ele.discount,
            size: JSON.stringify({ size: ele.size, unit: ele.units }),
            category: data.comingdata,
            subcategory: data.subCategoryId,
            cess: ele.cess,
            tax: ele.Tax,
            price: ele.MRP,
            commission: ele.commision,
            description: ele.description,
          };
        });
        const formData = new FormData();
        formData.append('bulk', 'true');
        formData.append('product', JSON.stringify(updatedPayload));

        const res = await postTokenApi(
          HIBEE_PRODUCT_URL + PRODUCT_PATH,
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        if (res.error) {
          throw new Error(res.message);
        }

        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setLoader(false);
        navigate("/products");
        setBulkProducts({ ...bulkProducts, bulkData: [] });
      }
    } catch (err) {
      console.error(err); // Log the error to the console for debugging
      toast.error("An error occurred while processing the request.", {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
    }
  };

  const [replacementImageIds, setReplacementImageIds] = useState([]);


  const handleImageUpdates = (index) => {
    // Create an input element dynamically
    const input = document.createElement('input');
    input.type = 'file';

    // Add an event listener to handle file selection
    input.addEventListener('change', (event) => {
      const file = event.target.files[0];

      // Create a new FileReader to read the file
      const reader = new FileReader();

      // Define the onLoad event handler to handle file reading completion
      reader.onload = () => {
        // Create a new image object with the file content and the ID of the existing image
        const newImage = {
          image: reader.result,
          _id: comingImage[index]._id
        };

        // Replace the existing image with the new image in the `comingImage` array
        setComingImage(prevImages => {
          const updatedImages = [...prevImages];
          updatedImages[index] = newImage;
          return updatedImages;
        });
      };

      // Read the file content as a data URL
      reader.readAsDataURL(file);
    });

    // Trigger the file input selection
    input.click();
  };



  /** handle validation **/
  const handleSubmitInventory = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      setSubmittedOnce(true);

      if (handleValidation()) { // If there's a condition here, you should specify it.
        const fd = new FormData();
        if (dataid) {
          fd.append("productId", dataid);
        }
        fd.append("product_code", inventoryData?.product_code);
        fd.append("product_name", inventoryData?.product_name);
        fd.append("hsn_code", inventoryData?.hsn_code);
        fd.append("discount", inventoryData?.discount);
        fd.append("franchiseDiscount", inventoryData?.franchisediscount);
        fd.append("superfastDiscount", inventoryData?.superfastdiscount);
        fd.append("category", inventoryData?.category);
        fd.append("subcategory", inventoryData?.subcategory);

        if (product?.sizeList) {

        } else {
          fd.append("sizeList", JSON.stringify(sizeList));
        }
        productImage.forEach((image, index) => {
          fd.append(`images`, image, image.name); // Append the image as a file with its original name
        });
        fd.append("cess", inventoryData?.cess);
        fd.append("tax", inventoryData?.tax);
        fd.append("price", inventoryData?.price);
        fd.append("commission", inventoryData?.commission);
        if (inventoryData?.description != '') {
          fd.append("description", inventoryData?.description);
        }
        if (inventoryData?.nativity != '') {
          fd.append("nativity", inventoryData?.nativity);
        }
        // Check if nativity is defined and then append it to the FormData
        // if (typeof inventoryData?.nativity == '') {
        //   fd.append("nativity", inventoryData.nativity);
        // }

        const res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH, fd, {
          "Content-Type": "multipart/form-data",
        });

        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        navigate("/products");
      }
      setLoader(false);
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: "top-center",
        autoClose: 2000,
      });
      setLoader(false);
    }
  };


  const handleImageUpdate = async (e, index, action) => {
    e.preventDefault();
    try {
      if (action === 'replace') {
        // Prompt the user to enter a new image file
        const input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('change', async (event) => {
          const file = event.target.files[0];

          // Create a new FormData object
          const formData = new FormData();
          if (product?._id) {
            formData.append("productId", product?._id);
          }
          formData.append('status', 'update');
          formData.append('imageId', comingImage[index]._id);
          formData.append('image', file);

          // Handle the response
          const res = await putTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_IMAGE_UPDATE, formData, {
            "Content-Type": "multipart/form-data",
          });

          toast.success(res?.data?.message, {
            position: "top-center",
            autoClose: 2000,
          });
          if (res?.data?.message) {
            getData();
          }
        });

        // Trigger the file input selection
        input.click();
      } else if (action === 'delete') {
        // Handle the delete action
        const formData = new FormData();
        if (product?._id) {
          formData.append("productId", product?._id);
        }
        formData.append('status', 'delete');
        formData.append('imageId', comingImage[index]._id);
        // Handle the response
        const res = await putTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_IMAGE_UPDATE, formData, {
          "Content-Type": "multipart/form-data",
        });

        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        if (res?.data?.message) {
          getData();
        }
      }
    } catch (err) {
      // Handle the error
      console.error(err);
    }
  };
  const handleImageCreate = async (e) => {
    e.preventDefault();
    try {
      // Prompt the user to enter a new image file
      const input = document.createElement('input');
      input.type = 'file';
      input.addEventListener('change', async (event) => {
        const file = event.target.files[0];

        // Create a new FormData object
        const formData = new FormData();
        if (product?._id) {
          formData.append("productId", product?._id);
        }
        formData.append('status', 'create');
        formData.append('image', file);

        // Handle the response
        const res = await putTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_IMAGE_UPDATE, formData, {
          "Content-Type": "multipart/form-data",
        });

        toast.success(res?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });

        // Call getData function if the response is successful
        if (res?.data?.message) {
          getData();
        }
      });

      // Trigger the file input selection
      input.click();
    } catch (err) {
      // Handle the error
      console.error(err);
    }
  };

  const [query, setQuery] = useState('');
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  console.log(query, "query");
  const getNativePlaces = async (inputText) => {
    try {
      const res = await getTokenApi(HIBEE_PRODUCT_URL + Nativity + `?search=${inputText}`);
      setFilteredCities(res?.data?.cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const handleInputChangedropdown = (event) => {
    const inputText = event.target.value.trim();
    setQuery(inputText);

    // Fetch cities based on the input query (call your API with the input query)
    if (inputText.length >= 3) {
      // If the input has at least 3 characters, call the API to get filtered cities
      getNativePlaces(inputText);
    } else {
      // If the input has fewer than 3 characters, reset the filteredCities
      setFilteredCities([]);
    }

    // Clear the selected city when the user types in the input
    setSelectedCity(null);
  };

  const handleCitySelect = (selectedCity) => {
    console.log(selectedCity, 'selected city');
    setSelectedCity(selectedCity);
    setQuery(selectedCity.name);
    setFilteredCities([]); // Hide the dropdown

    // Update inventoryData.nativity when a city is selected
    handleInventorychange({
      target: { name: 'nativity', value: selectedCity._id },
    });
  };

  useEffect(() => {
    // Set the selected city when inventoryData.nativity has a value
    if (inventoryData?.nativity && filteredCities.length > 0) {
      const selectedCity = filteredCities.find((city) => city._id === inventoryData.nativity);
      if (selectedCity) {
        setSelectedCity(selectedCity);
        setQuery(selectedCity.name);
      }
    }
  }, [filteredCities, inventoryData.nativity]);
  const getCategories = async () => {
    const res = await getTokenApi(HIBEE_PRODUCT_URL + CATEGORY_PATH + `?status=${true}`);
    setCategories(res?.data?.data);
  };

  const getSubCtegories = async () => {
    const res = await postTokenApi(
      HIBEE_PRODUCT_URL + CATEGORY_SUB_LIST_PATH + `/${inventoryData?.category}` + `?status=${true}`
    );
    setSubCategories(res?.data?.message?.[0]?.SubCategorys);
  };

  useEffect(() => {
    if (inventoryData?.category) getSubCtegories();
  }, [inventoryData?.category]);

  useEffect(() => {
    if (Object.keys(inventoryData)?.length && submittedOnce) {
      handleValidation();
    }
  }, [inventoryData]);

  useEffect(() => {
    getCategories();
  }, []);


  const [modal, setModal] = useState(false);
  const closeCategoryModal = () => {
    setModal(false);
    setLoader(false);
  };


  //Bulk Upload Functions

  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const [bulkProducts, setBulkProducts] = useState({
    bulkData: [],
    show: false,
    error: "",
  });
  const allowedExtensions = ["csv"];

  /** Bulk Data **/
  const handleFileChange = (e) => {
    if (e.target.files.length) {
      setBulkProducts({ ...bulkProducts, error: "", ele: "" });
      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        // setError("Please input a csv file");
        setBulkProducts({
          ...bulkProducts,
          error: "Please input a csv file",
        });
        return;
      }
      // setFile(inputFile);
      // if (!file) return setBulkProducts({ ...bulkProducts, error: "Enter a valid file" });
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        setBulkProducts({
          ...bulkProducts,
          bulkData: csv.data.slice(0, csv.data.length - 1),
          error: "",
        });
      };
      reader.readAsText(inputFile);
    }
  };


  const handleInventoryChange = (e) => {
    const { name, value } = e.target;
    setInventoryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  return (
    <MainTemplate
      categoryName="Add Product"
      categoryList={[ "Add Product"]}
    >
      <Form onSubmit={handleSubmitInventory}>
        <Row>
          <div className="row mb-4 rounded bg-white homepageModifierPadding mb-4">
            {data?.comingdata ? (<div className="d-flex align-items-baseline">
              <p className="bulkupload">Uploading many products at once?</p>
              <div class="bulkuploadbutton-container">
                <button
                  type="button"
                  class="bulkuploadbutton ms-2"
                  onClick={() => {
                    setModal(!modal);
                  }}
                >
                  Bulk upload
                </button>

              </div>
            </div>) : ""}
            <Modal
              isOpen={modal}
              onRequestClose={closeCategoryModal}
              style={custombulkModalStyles}
              contentLabel="Example Modal"
              ariaHideApp={false}
            >
              <div>
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="bulkuploadmodelheader">
                      Adding multiple categories at once?.
                    </p>
                    <p className="bulkuploadmodelsubheader">
                      Upload a file in a .csv format
                    </p>
                  </div>

                </div>

                <div>
                  <span
                    className="text-success fw-bold"
                    role="button"
                    onClick={() => downloadFile(BulkProductCsv)}
                  >
                    Download sample format here
                  </span>
                  <div className="d-flex justify-content-center align-items-center bulkuploadoption">
                    <div>
                      <div className="bulkuploadsinput">
                        <BsUpload className=""></BsUpload>
                        <input
                          name="file"
                          type="file"
                          autoComplete="off"
                          accept=".csv"
                          onChange={handleFileChange}
                          value={bulkProducts.ele}
                          className={`py-2 opacity0 position-absolute  w-25 placeholder-light form-control py-2 px-3 border`}
                          placeholder="eg: https://www.apple.com/in/watch"
                        />
                      </div>
                      <p className="bulkuploadinputtag d-flex justify-content-center align-items-center">Upload File</p>
                    </div>
                  </div>
                  {bulkProducts.bulkData.length != 0 ? (<div className="d-flex justify-content-around align-items-center mt-4">
                    <div>
                      {bulkProducts.bulkData.length != 0 ? (
                        <div
                          className="bg-danger rounded categoryImageDiv shadow-sm "
                          style={{ width: "80px", height: "50px" }}
                        >
                          <div
                            className="image-containerr-excell"
                            onClick={() =>
                              setBulkProducts({
                                ...bulkProducts,
                                bulkData: [],
                                error: "",
                                ele: "",
                              })
                            }
                          ></div>
                          <img src={excell} className="h-100 rounded" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-6 form-group my-2">
                      <button
                        className="btn btn-dark text-warning px-5"
                        onClick={() => {
                          bulkProducts.bulkData.length != 0
                            ? setBulkProducts({ ...bulkProducts, show: true })
                            : setBulkProducts({
                              ...bulkProducts,
                              error: "select",
                            });

                        }}
                      >
                        {" "}
                        Preview
                      </button>
                    </div>
                  </div>) : ""}
                </div>
              </div>

              {/* bulk model */}
              <Modal
                isOpen={bulkProducts.show}
                onRequestClose={() =>
                  setBulkProducts({ ...bulkProducts, show: false })
                }
                style={{
                  ...customModalStyles,
                  content: { ...customModalStyles?.content, width: "90vw", height: "100vh" },
                }}
                contentLabel="Example Modal"
                ariaHideApp={false}
              >
                <div className="px-3 py-3">
                  <div className="row ">
                    <h4>Bulk Categorys List</h4>

                    <span className="d-flex justify-content-end cursorPointer">
                      <svg
                        onClick={() =>
                          setBulkProducts({ ...bulkProducts, show: false })
                        }
                        className="d-inline"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                      </svg>
                    </span>
                  </div>
                  <div className="row">
                    <div>
                      <div className=" rounded px-3 bg-white overflow-auto bulkuploadPageScroll">
                        {/* header */}
                        <table className="   w-100">
                          <thead className="">
                            <tr className="">
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Unique Code</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">HSN Code</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Product Name</p></div></th>
                              {/* <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Product category</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Product sub-category</p></div></th> */}
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Cess</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Size</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Units</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Discount %</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Tax % </p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">MRP</p></div></th>
                              <th className="orderstableheading "><div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0">Commission %</p></div></th>
                              <th className="orderstableheading ">Description<div className="d-flex justify-content-center align-items-center p-1">
                                <p className="m-0"></p></div></th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {bulkProducts?.bulkData?.map((el, index) => {
                              return (
                                <tr key={index} className="border ">
                                  <td className="orderstabledetails  "><div className="d-flex justify-content-center align-items-center py-3 my-2">
                                    <p className="p-1 m-0">{el.uniqueCode}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.hsnCode}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.productName}</p></div></td>
                                  {/* <td className="orderstabledetails">
                                    <div className="d-flex justify-content-center align-items-center">
                                      <select value={inventoryData?.categories}>
                                        <option selected disabled>
                                          Select category
                                        </option>
                                        {categories?.map((el, index) => (
                                          <option
                                            key={index}
                                            value={el?._id}
                                            selected={el?._id === inventoryData?.category}
                                          >
                                            {el?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </td>

                                  <td className="orderstabledetails">
                                    <div className="d-flex justify-content-center align-items-center py-3 my-2">
                                      <select
                                        value={inventoryData?.subcategory}
                                      // onChange={(e) => handleSubcategoryChange(e.target.value)}
                                      >
                                        <option disabled>Select sub-category</option>
                                        {subCategories?.map((el, index) => (
                                          <option className="" key={index} value={el?._id}>
                                            {el?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </td> */}
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.cess}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.size}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.units}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.discount}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.Tax}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.MRP}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center py-3 my-2" >
                                    <p className="p-1 m-0">{el.commision}</p></div></td>
                                  <td className="orderstabledetails "><div className="d-flex justify-content-center align-items-center w-50 py-3 my-2" >
                                    <textarea
                                      className="textarea-style"
                                      readOnly
                                      disabled
                                      value={el.Description}
                                    />
                                  </div></td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <button

                          className="btn btn-dark text-warning px-5"
                          onClick={handleBulkSubmitInventory}
                        >
                          Submit{
                            loader
                              ?
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                              :
                              ''
                          }
                        </button>
                        <button
                          className="btn btn-dark text-warning px-5"
                          style={{ marginLeft: "10px" }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

              </Modal>
            </Modal>
            <div className="col-lg-6 mb-4">


              <Form.Group
                className="form-group py-2 pb-3"
                controlId="Unique Code"
              >
                <Form.Label>Unique Code<span className='text-danger'>*</span></Form.Label>
                <div className="col-4">
                  <Form.Control
                    type="number"
                    value={inventoryData?.product_code}
                    placeholder="#66778"
                    name="product_code"
                    className="form-control"
                    onChange={handleInventorychange}
                    isInvalid={inventoryDataError?.product_code}
                    autoComplete="off"
                    onWheelCapture={(e) => e.target.blur()}
                    maxLength={7}
                    onInput={(e) => {
                      if (e.target.value.length > e.target.maxLength) {
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.maxLength
                        );
                      }
                    }}
                    disabled={product?.product_code ? true : false}
                  />
                </div>
                <span className="text-danger">
                  {inventoryDataError.product_code}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="Price">
                <Form.Label>HSN Code<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="text"
                  placeholder="Enter HSN Code"
                  name="hsn_code"
                  value={inventoryData?.hsn_code}
                  isInvalid={inventoryDataError?.hsn_code}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                  disabled={product?.hsn_code ? true : false}
                />
                <span className="text-danger">
                  {inventoryDataError.hsn_code}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="Product Name">
                <Form.Label>Product Name<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="text"
                  placeholder="Product name"
                  value={inventoryData?.product_name}
                  name="product_name"
                  isInvalid={inventoryDataError?.product_name}
                  autoComplete="off"
                  onChange={handleInventorychange}
                />
                <span className="text-danger">
                  {inventoryDataError.product_name}
                </span>
              </Form.Group>
              {/* 
              <Form.Group className="mb-3" controlId="Manufacture Name">
                <Form.Label>Manufacture Name</Form.Label>
                <Form.Control
                  className="Textbar"
                  type="text"
                  placeholder="Enter manufacture name"
                  name="manufacture_name"
                  value={inventoryData?.manufacture_name}
                  isInvalid={inventoryDataError?.manufacture_name}
                  autoComplete="off"
                  onChange={handleInventorychange}
                />
                <span className="text-danger">
                  {inventoryDataError.manufacture_name}
                </span>
              </Form.Group>

              <div className="mb-3">
                <div style={{ width: "448px" }}>
                  <Row>
                    <div className="col-6">
                      <Form.Group className="mb-3" controlId="Product MFG Date">
                        <Form.Label>Product MFG Date</Form.Label>
                        <Form.Control
                          className="smtext"
                          type="date"
                          placeholder="Select MFG date"
                          name="manufacturing_date"
                          value={inventoryData?.manufacturing_date}
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.manufacturing_date}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {inventoryDataError.manufacturing_date}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group
                        className="mb-3"
                        controlId="Product Expiry Date"
                      >
                        <Form.Label>Best Before/Expiry</Form.Label>
                        <Form.Control
                          className="smtext"
                          type="date"
                          placeholder="Select Expiry date"
                          name="expiration_date"
                          value={inventoryData?.expiration_date}
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.expiration_date}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {inventoryDataError.expiration_date}
                        </span>
                      </Form.Group>
                    </div>
                  </Row>
                </div>
              </div>

              <div className="mb-3">
                <div>
                  <Row>
                    <div className="col-4">
                      <Form.Group className="mb-3" controlId="Product Qty">
                        <Form.Label>Product Qty</Form.Label>
                        <Form.Control
                          className="smtext w-100"
                          type="number"
                          placeholder="01"
                          name="qty"
                          value={inventoryData?.qty}
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.qty}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {inventoryDataError.qty}
                        </span>
                      </Form.Group>
                    </div>
                    
                    <div className="col-4">
                      <Form.Group className="mb-3" controlId="Product Qty">
                        <Form.Label>Select Warehouse</Form.Label>
                        <Form.Select
                          className="mb-3 smtext w-auto"
                          controlId="Select Warehouse"
                          type="text"
                          placeholder="Enter Select Warehouse"
                          name="warehouse"
                          onChange={handleInventorychange}
                          value={inventoryData?.warehouse}
                          isInvalid={inventoryDataError?.warehouse}
                          autoComplete="off"
                        >
                          <option value="Whitefield">Whitefield</option>
                          <option value="London">London</option>
                          <option value="Delhi">Delhi</option>
                        </Form.Select>
                      </Form.Group>
                      <span className="text-danger">
                        {inventoryDataError.warehouse}
                      </span>
                    </div>
                  </Row>
                </div>
              </div> */}
              <div className="d-flex justify-content-between gap-2">
                <Form.Group className="mb-3" controlId="size">
                  <Form.Label>Size<span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    className="Textbar"
                    style={{ width: 200 }}
                    type="number"
                    placeholder="Enter Size"
                    name="size"
                    value={sizeList?.size}
                    onWheelCapture={(e) => e.target.blur()}
                    isInvalid={inventoryDataError?.size}
                    autoComplete="off"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (!isNaN(inputValue) && Number(inputValue) >= 0) {
                        setSizeList({ ...sizeList, size: inputValue });
                      }
                    }}
                    disabled={product?.sizeList?.size ? true : false}
                  />
                  <span className="text-danger">
                    {inventoryDataError?.size}
                  </span>
                </Form.Group>

                <Form.Group className="mb-3" controlId="unit">
                  <Form.Label>Units<span className='text-danger'>*</span></Form.Label>
                  <Form.Select
                    className="Textbar"
                    style={{ width: 200 }}
                    controlId="unit"
                    type="text"
                    placeholder="gms"
                    name="unit"
                    // value={inventoryData?.sizeList.unit}
                    disabled={product?.sizeList?.unit ? true : false}
                    autoComplete="off"
                    onChange={(e) => setSizeList({ ...sizeList, unit: e.target.value })}
                  >
                    <option value="kg">KG</option>
                    <option value="gm">GM</option>
                    <option value="ml">ML</option>
                    <option value="l">L</option>
                  </Form.Select>

                </Form.Group>
              </div>


              <Row>
                <div className="col mb-3">
                  <Form.Label>Product category<span className='text-danger'>*</span></Form.Label>
                  <Form.Select
                    className="Textbar"
                    controlId="Product category"
                    type="text"
                    placeholder="Enter select product category"
                    name="category"
                    onChange={handleInventorychange}
                    isInvalid={inventoryDataError?.category}
                    autoComplete="off"
                    disabled={product?.category || data?.comingdata ? true : false}

                  // disabled={inventoryData?.category ? true : false}
                  >
                    <option selected disabled>
                      Select category
                    </option>
                    {categories?.map((el, index) => (
                      <option
                        key={index}
                        value={el?._id}
                        selected={el?._id === inventoryData?.category}
                      >
                        {el?.name}
                      </option>
                    ))}
                  </Form.Select>
                  <span className="text-danger">
                    {inventoryDataError.category}
                  </span>
                </div>
              </Row>

              <Row>
                <div className="col mb-3">
                  <Form.Label>Product sub-category<span className='text-danger'>*</span></Form.Label>
                  <Form.Select
                    className="Textbar"
                    controlId="Product category"
                    type="text"
                    placeholder="Enter select product category"
                    name="subcategory"
                    onChange={handleInventorychange}
                    isInvalid={inventoryDataError?.subcategory}
                    autoComplete="off"
                    // disabled={isEditing}

                    disabled={product?.subcategory || data?.subCategoryId ? true : false}
                  >
                    <option selected disabled>
                      Select sub-category
                    </option>
                    {subCategories?.map((el, index) => (
                      <option
                        key={index}
                        value={el?._id}
                        selected={el?._id === inventoryData?.subcategory}
                      >
                        {el?.name}
                      </option>
                    ))}
                  </Form.Select>
                  <span className="text-danger">
                    {inventoryDataError.subcategory}
                  </span>
                </div>
              </Row>
              {/* <div className="mb-3">
                <div style={{ width: "448px" }}>
                  <Row>
                    <div className="col-6">
                      <Form.Group className="mb-3" controlId="Storage Type">
                        <Form.Label>Storage Type</Form.Label>
                        <Form.Control
                          className="smtext"
                          type="text"
                          placeholder="Enter storage name"
                          name="type"
                          value={inventoryData?.type}
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.type}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {inventoryDataError.type}
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col-6">
                      <Form.Group className="mb-3" controlId="Product Batch">
                        <Form.Label className="margintext">
                          Product Batch
                        </Form.Label>
                        <Form.Control
                          className="smtext"
                          type="text"
                          placeholder="Enter batch name"
                          name="batch"
                          value={inventoryData?.batch}
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.batch}
                          autoComplete="off"
                        />
                        <span className="text-danger">
                          {inventoryDataError.batch}
                        </span>
                      </Form.Group>
                    </div>
                  </Row>
                </div>
              </div> */}

              <Form.Group className="mb-3" controlId="discount">
                <Form.Label>Discount % <span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar remove-spinner"
                  placeholder="Enter Discount"
                  name="discount"
                  value={inventoryData?.discount}
                  isInvalid={inventoryDataError?.discount}
                  autoComplete="off"
                  onChange={handleInventoryChange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                {inventoryData?.discount > 100 && (
                  <span className="text-danger">
                    Discount cannot be more than 100%
                  </span>
                )}
                <span className="text-danger">
                  {inventoryDataError?.discount}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="franchisediscount">
                <Form.Label>Franchise Discount % <span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar remove-spinner"

                  placeholder="Enter Franchise Discount"
                  name="franchisediscount"
                  value={inventoryData?.franchisediscount}
                  isInvalid={inventoryDataError?.franchisediscount}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                {inventoryData?.franchisediscount > 100 && (
                  <span className="text-danger">
                    Franchise Discount cannot be more than 100%
                  </span>
                )}
                <span className="text-danger">
                  {inventoryDataError?.franchisediscount}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="superfastdiscount">
                <Form.Label>SuperFast Discount % <span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar remove-spinner"

                  placeholder="Enter SuperFast Discount"
                  name="superfastdiscount"
                  value={inventoryData?.superfastdiscount}
                  isInvalid={inventoryDataError?.superfastdiscount}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                {inventoryData?.superfastdiscount > 100 && (
                  <span className="text-danger">
                    SuperFast Discount cannot be more than 100%
                  </span>
                )}
                <span className="text-danger">
                  {inventoryDataError?.superfastdiscount}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="cess">
                <Form.Label>Cess %<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="number"
                  placeholder="Enter cess"
                  name="cess"
                  value={inventoryData?.cess}
                  isInvalid={inventoryDataError?.cess}
                  autoComplete="off"
                  onWheelCapture={(e) => e.target.blur()}

                  onChange={handleInventorychange}
                />
                <span className="text-danger">
                  {inventoryDataError.cess}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="tax">
                <Form.Label>Tax %<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="number"

                  placeholder="Enter Tax Amount"
                  name="tax"
                  value={inventoryData?.tax}
                  isInvalid={inventoryDataError?.tax}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                <span className="text-danger">
                  {inventoryDataError.tax}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="price">
                <Form.Label>MRP<span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="number"
                  placeholder="Enter Cutout Price"
                  name="price"
                  isInvalid={inventoryDataError?.price}
                  autoComplete="off"
                  value={inventoryData?.price}
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                <span className="text-danger">
                  {inventoryDataError.price}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="Commission">
                <Form.Label>Commission % <span className='text-danger'>*</span></Form.Label>
                <Form.Control
                  className="Textbar"
                  type="number"
                  placeholder="Enter Commission"
                  name="commission"
                  value={inventoryData?.commission}
                  isInvalid={inventoryDataError?.commission}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                />
                <span className="text-danger">
                  {inventoryDataError.commission}
                </span>
              </Form.Group>
              <Row>
                <div className="col mb-3">
                  <Form.Label>City (Nativity)</Form.Label>
                  <Form.Control
                    onChange={handleInputChangedropdown}
                    list="cities-list"
                    className="Textbar"
                    controlId="nativity"
                    type="text"
                    placeholder="Enter City Name"
                    name="nativity"
                    isInvalid={inventoryDataError?.nativity}
                    autoComplete="off"
                    // disabled={isEditing}
                    // disabled={product?.nativity ? true : false}
                    value={query} // Set the input value to the query state
                  />
                  {filteredCities.length > 0 && (
                    <div className="">
                      {filteredCities?.map((city, index) => (
                        <div
                          className="itemSelectingdropdown"
                          key={index}
                          onClick={() => handleCitySelect(city)}
                        >
                          <p className="p-0 m-0">{city.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Row>
              <Form.Group className="mb-3" controlId="Commission">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  className="Textbar"
                  type="textarea"
                  placeholder="Enter description"
                  name="description"
                  value={inventoryData?.description}
                  // isInvalid={inventoryDataError?.description}
                  autoComplete="off"
                  onChange={handleInventorychange}
                  onWheelCapture={(e) => e.target.blur()}
                  as="textarea"
                  rows={3}
                />

              </Form.Group>
            </div>
            <div className="col-lg-6 mb-4 ">
              {/* <div className="py-2 mb-3">
                <label>Bar Code</label>
                <Row>
                  <Barcode
                    className="d-flex"
                    options={{
                      format: "code128",
                      width: "2",
                      marginLeft: 0,
                      text: inventoryData?.product_code || "Unique Code",
                    }}
                    value={inventoryData?.product_code || " "}
                  />
                </Row>
              </div> */}
              {/* <div className="py-2">
					      <label>Bar code</label>
					      {
                  true 
                  ?
                  <Row>
                    <Form.Group className="mb-3" controlId="Pan Card">
                      <div className="form-group py-2 position-relative mb-3">
                        <input type="file"
                          name='image'
                          onChange={handleInventorychange}
                          isInvalid={inventoryDataError?.image}
                          className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3" />
                        <div className="profile-box text-center" >
                          <div  style={{marginTop:"80px"}}>
                            <img src={placeholder} height='20px' width='20px' />{" "}<span style={{ color: '#A3A3A3' }}>Upload Photo</span>
                          </div>
                        </div>
                      </div>
                      <span className="text-danger" >{inventoryDataError.image}</span>
                    </Form.Group>
                  </Row>
                  :
                  <Row>
                    <div className="profile-box mb-3 g-0 d-flex justify-content-center align-items-center" >
                      <img src={inventoryData.image} width='100%' height='100%' />
                    </div>
                  </Row>
                }
              </div> */}
              {/* <div className="py-2 mb-3">
                <label>QR Code</label>
                <Row className="d-flex justify-content-center">
                  <QRCode
                    style={{ height: "250px", width: "250px" }}
                    value={JSON.stringify(inventoryData)}
                  />
                </Row>
              </div> */}
              <div className="py-2">
                <label>Product Image <span className='text-danger'>*</span></label>
                {!product?._id ? <Row>
                  <div className="form-group py-2 position-relative">
                    <input
                      type="file"
                      name="image"
                      multiple
                      onChange={(e) => handleInventorychange(e, "image")}
                      isInvalid={inventoryDataError?.image}
                      className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100  px-3"
                    />

                    <div
                      className={`d-flex align-items-center justify-content-center flex-column border rounded text-center ${Boolean(
                        inventoryDataError?.image
                      ) && "border-danger"}`}
                      style={{ height: "200px" }}
                    >
                      <div>
                        <img src={placeholder} alt="" />{" "}
                        <span style={{ color: "#A3A3A3" }}>Upload Photos</span>
                      </div>
                    </div>
                    <span className="text-danger">
                      {inventoryDataError.image}
                    </span>
                  </div>
                </Row> : ""}
                <Row>
                  <div className="mb-3 g-0 d-flex justify-content-center position-relative align-items-center">
                    <input
                      type="file"
                      name="image"
                      onChange={(e) => handleInventorychange(e, "image")}
                      isInvalid={inventoryDataError?.image}
                      className="opacity0 position-absolute placeholder-light form-control py-2 w-100 h-100 px-3"
                    />
                  </div>
                  <div>
                    <div className="card overflow-auto multiple-image-height">
                      {product?._id ? (
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          {Array.isArray(comingImage) &&
                            comingImage.map((image, index) => (
                              <div key={index} style={{ margin: "10px" }}>
                                <img
                                  src={image?.image}
                                  alt={`Product image ${index}`}
                                  className="ImageAddForm shadow image"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                                <div className="">
                                  <div
                                    className="image-container"
                                    onClick={(e) => {
                                      handleImageUpdate(e, index, 'delete');
                                    }}
                                  ></div>
                                  <button
                                    className="image_replace_option btn-primary px-3"
                                    onClick={(e) => {
                                      handleImageUpdate(e, index, 'replace');
                                    }}
                                  >
                                    Replace
                                  </button>
                                </div>
                              </div>
                            ))}
                          <div style={{ margin: "10px" }}>
                            <div
                              className="rounded shadow image"
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                              }}
                              onClick={(e) => handleImageCreate(e)}
                            >
                              <RiImageAddLine
                                className="d-flex justify-content-center align-items-center"
                                style={{ width: "100%", height: "46px" }}
                              />
                              <p className="d-flex justify-content-center align-items-center" style={{ cursor: "pointer" }}>
                                Add Image
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: "flex", flexWrap: "nowrap" }}>
                          {productImage.map((file, index) => (
                            <div key={index} style={{ margin: "10px" }}>
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Product image ${index}`}
                                className="rounded shadow image"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                className="image-container"
                                onClick={() => handleImageDelete(index)}
                              ></div>
                            </div>
                          ))}
                        </div>
                      )}

                    </div>
                  </div>
                </Row>
              </div>
              {/* <div>
                <div className="d-flex justify-content-between">
                  <p>Product Varient</p>
                  <Button variant="primary" onClick={handleAddVariant}>
                    Add
                  </Button>
                </div>


                {variantFields.map((variant, index) => (
                  <div key={index} className="d-flex gap-2 align-items-center">
                    <Form.Group className="mb-3" controlId={`Variant name ${index}`}>
                      <Form.Label>Variant name {index + 1}</Form.Label>
                      <Form.Control
                        className=""
                        type="text"
                        placeholder="Enter variant name"
                        name={`variant_name_${index}`}
                        value={variant[`variant_name_${index}`]}
                        autoComplete="off"
                        onChange={(e) => handleVariantChange(index, "variant_name", e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId={`MRP ${index}`}>
                      <Form.Label>MRP</Form.Label>
                      <Form.Control
                        className=""
                        type="text"
                        placeholder="Enter MRP"
                        name={`mrp_${index}`}
                        value={variant[`mrp_${index}`]}
                        autoComplete="off"
                        onChange={(e) => handleVariantChange(index, "mrp", e.target.value)}
                      />
                    </Form.Group>

                    <RiDeleteBin6Line
                      className="text-danger p-0 m-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteVariant(index)}
                    />
                  </div>
                ))}

              </div> */}



            </div>
            <div className="form-group py-2 pb-3">
              <Button
                variant="primary"
                type="submit"
                size="lg"
                id="btnRegister"
                style={{ backgroundColor: "#12352f" }}
              >
                Save Product
              </Button>
            </div>
          </div>
        </Row >
      </Form >
    </MainTemplate >
  );
}

export default InventoryProductAddForm;
