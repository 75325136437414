import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "./ProductPrice.css";
import Edit from "../../assets/images/Edit.png";
// import Delete from "../../assets/CategoryDetail";
import Modal from "react-modal";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteTokenApi, getApi } from "../../api_interface/apis";
import { HIBEE_PRODUCT_URL, PRODUCT_PATH } from "../../api_interface/apiURLs";
import MainTemplate from "../../components/MainTemplate";
import { customActionModalStyles } from "../../utils/helperFunctin";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProductPrice() {

  const { id } = useParams()
  const navigate = useNavigate()
  const [images, setImages] = useState([]);

  const [product, setProduct] = useState({})
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });

  const getData = async () => {
    if (id) {
      const res = await getApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?productId=${id}`)
      if (res?.data?.data) {
        setProduct(res?.data?.data)
        console.log();
        setSelectedImage(res.data.data.images)
      }
    }
  }

  const deleteProduct = async (id) => {
    try {
      const res = await deleteTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `/${id}`);
      setActionModal({});
      navigate("/products")
      toast.success(res.data.message, { position: "top-center", autoClose: 2000, });
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message, { position: "top-center", autoClose: 2000, });
    }
  };

  useEffect(() => {
    getData()
  }, [id])

  const [selectedImage, setSelectedImage] = useState([]);
  const [mainImage, setMainImage] = useState('');

  return (
    <MainTemplate categoryName="Categories" categoryList={["Admin", "Products", `${product?.product_name}`]}>
      <div className="container">
        <div className="row p-5 pe-0">
          <div className="col-md-6 ">
            <div>
              <div className="rounded w-100 p-3 d-flex shadow-sm align-items-center justify-content-center" style={{ background: "#F1F9F3", height: "500px" }}>
                {/* <img src={product.image1} style={{ width: "300px", height: "300px", objectFit: "cover" }} /> */}
                {product?.images && product.images.length > 0 ? (
                  <img src={product.images[0].image} alt="loading" style={{ width: "300px", height: "300px", objectFit: "contain" }} />
                ) : (
                  <img src={product?.image} alt="loading" style={{ width: "300px", height: "300px", objectFit: "contain" }} />
                )}
              </div>
              <div className="card overflow-auto multiple-image-height">
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  {selectedImage?.map((image, index) => (
                    <div key={index} style={{ margin: "10px" }}>
                      <img
                        src={image.image}
                        alt={`Product image ${index}`}
                        className={`rounded image ${mainImage === image ? 'selected' : ''}`}
                        style={{ width: "100px", height: "100px", objectFit: "contain" }}
                        onClick={() => setMainImage(image)}
                      />

                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>
          <div className="col-md-6 ps-4">
            <div className="row">
              <div className="row mb-3">
                <div className="lables">
                  <div
                    className={`border-properties ${product?.sizeList?.quantity
                      ? product.sizeList.quantity === 0
                        ? "low-stock" // CSS class for Low Stock
                        : "on-stock" // CSS class for On Stock
                      : "no-stock" // CSS class for No Stock
                      }`}
                  >
                    {product?.sizeList?.quantity ? (product.sizeList.quantity === 0 ? "Low Stock" : "In Stock") : "No Stock"}
                  </div>

                  <div className="border2">{product?.discount}% OFF</div>
                </div>
              </div>
              <div>
                <div className="">
                  <h3 className="padding">
                    <b>{product?.product_name}</b>
                  </h3>
                </div>
              </div>
              <div className=" mb-5 linehight">
                <div className="lables">
                  <div>Product Code:</div>
                  <div>#{product?.product_code}</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Price:</div>
                  <div>₹{product?.price}</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Discount:</div>
                  <div>{product?.discount}%</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Quantity:</div>
                  <div>{product?.sizeList?.quantity}</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Shelf Life:</div>
                  <div>{product?.expiration_date && product?.expiration_date.slice(0,10)} Days</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Commisions:</div>
                  <div>{product?.commission}%</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Category:</div>
                  <div>{product?.category?.name}</div>
                </div>
                <hr></hr>
                <div className="lables">
                  <div>Sub Category:</div>
                  <div>{product?.subcategory?.name}</div>
                </div>
                <hr></hr>
              </div>
              <div className="padding">
                <h6>
                  <b>Description</b>
                </h6>
              </div>
              <div className="row infobox mb-3">
                <div>
                  {product?.description}
                </div>
              </div>
              <div className="row md-3">
                <div className="lables imgcolor"><div className="col-7"></div>
                  <Link
                    to="/inventory-product-add"
                    state={{ id: product?._id, edit: true }}
                    style={{ textDecoration: "none" }}>
                    <div className="text-dark"><img src={Edit} />Edit</div>
                  </Link>
                  {/* <div className="cursorPointer text-dark" onClick={() => setActionModal({ ...actionModal, show: true, data: product, type: "DELETE", })}>
                    <img
                    /> Delete</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={actionModal?.show}
        onRequestClose={() => setActionModal({})}
        style={customActionModalStyles}
        contentLabel="Action Modal"
        ariaHideApp={false}
      >
        <div className="px-3 py-3">
          <div className="row">
            <span className="d-flex justify-content-end cursorPointer">
              <svg
                onClick={() => setActionModal({})}
                className="d-inline"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </span>
          </div>
          <div className="row">
            <h4 className="text-danger d-flex justify-content-center">
              Do you want to {actionModal?.type} this Product?
            </h4>
          </div>
          <div className="row my-5">
            <h5 className="d-flex justify-content-center">
              {actionModal?.data?.product_name}
            </h5>
          </div>
          <div className="form-group my-3 d-flex justify-content-center">
            <button
              disabled={actionModal?.loader}
              className="btn btn-dark me-3 mb-2 text-warning px-5"
              onClick={() => deleteProduct(actionModal?.data?._id)
              }
            >
              {actionModal?.loader ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            <button
              disabled={actionModal?.loader}
              className="btn btn-light me-3 mb-2 shadow-sm text-dark border px-5"
              onClick={() => setActionModal({})}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

    </MainTemplate>
  );
}

export default ProductPrice;
