import React, { useEffect, useState, useMemo } from "react";
// import "./ProductPrice.css";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import MainTemplate from "../../components/MainTemplate";
import { deleteTokenApi, getTokenApi, postTokenApi } from "../../api_interface/apis";
import { GROUPS_LIST, HIBEE_PRODUCT_URL, PRODUCT_PATH, PRODUCT_SUBCATEGORY } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import checkboxyellow from '../../assets/images/checkboxyellow.svg'
import AddNewProduct from '../../assets/images/AddNewProduct.png'

import { Spinner } from "react-bootstrap";

function HomepageModifierAddedProducts() {
    const { subCategoryId } = useParams()
    const location = useLocation();
    const productId = location?.state?.productId?.productId
    const data = location?.state?.data;
    const [sort, setSort] = useState("")
    const [query, setQuery] = useState("")
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(data?.selectedProduct || []);
    const [groupForm, setGroupForm] = useState({ title: "", description: "", products: [], id: "", loader: false })
    const [groupFormError, setGroupFormError] = useState({ title: "", description: "", products: "", id: "", loader: false })


    const [itemOffset, setItemOffset] = useState(0);
    const navigate = useNavigate();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(products.length / itemsPerPage);
    const currentItems = products.slice(itemOffset, endOffset);


    const [error, setError] = useState({});
    const [loader, setLoader] = useState(false);
    const [productImage, setProductImage] = useState("");
    const [actionModal, setActionModal] = useState({
        loader: false,
        show: false,
        type: "",
        data: {},
    });

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % products.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        getProducts()
    }, [sort])

    useEffect(() => {
        getSearchedData(query)
    }, [query])


    const getProducts = async search => {
        setLoader(true);
        let res
        if (subCategoryId) {
            res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_SUBCATEGORY + `?sort=${sort}&search=${search || query || ""}`, { subcategory: subCategoryId });
        } else {
            res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?sort=${sort}&search=${search || query || ""}`);
        }
        if (res.status == 200) {
            setProducts(res?.data?.data?.reverse());
            setLoader(false);
        }
    };


    const updateSort = e => {
        setSort(e.target.value)
    }

    const getSearchedData = useMemo(() => debounce(async (search) => {
        getProducts(search)
    }, 500), [])

    useEffect(() => {
        getProducts()
    }, [sort])

    useEffect(() => {
        getSearchedData(query)
    }, [query])

    const handleCheckBox = (el) => {
        if (!selectedProduct.includes(el)) {
            setSelectedProduct([...selectedProduct, el])
        }
    }

    const handleRemove = (ele) => {
        const removedObject = selectedProduct.filter((el) => el._id != ele._id)
        setSelectedProduct(removedObject)
        setGroupForm((prveState) => ({
            ...prveState,
            products: removedObject
        }))
    }

    const handleChange = (ele) => {
        setGroupForm({ ...groupForm, [ele.target.name]: ele.target.value })
        setGroupFormError({ ...groupFormError, [ele.target.name]: null })

    }

    const handleValidation = () => {
        const newError = {}
        if (!groupForm.title.trim()) {
            newError.title = "Please enter title"
        }
        if (!groupForm?.description.trim()) {
            newError.description = "Please enter description"
        }
        if (groupForm?.products.length == 0) {
            newError.products = "Please select products"
        }
        return newError
    }

    const handleSubmit = async () => {
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setGroupFormError(handleValidationObject)
        } else {
            const newProduct = []
            selectedProduct.map((ele, ind) => {
                newProduct.push(ele?._id)
            })
            try {
                const payload = {
                    products: newProduct,
                    title: groupForm?.title,
                    description: groupForm?.description
                }
                if (groupForm?.id) {
                    payload._id = groupForm?.id
                }

                setGroupForm({ ...groupForm, loader: true })
                const res = await postTokenApi(HIBEE_PRODUCT_URL + GROUPS_LIST, payload);
                if (res.status == 200) {
                    toast.success("Successfully created group...", { position: "top-center", autoClose: 2000, });
                    setGroupForm({ ...groupForm, loader: false })
                    // navigate("/grouping")
                    navigate("/homepage-modifer-groups-list")
                }
            } catch (err) {
                const message = err?.response?.data?.message || "Something went wrong";
                toast.error(message, { position: "top-center", autoClose: 2000 });
                setGroupForm({
                    ...groupForm,
                    loader: false
                })
                navigate("/grouping")
            }
        }
    }

    useEffect(() => {
        if (data?.selectedProduct?.length != 0) {
            setGroupForm((prevState) => ({
                ...prevState,
                products: data?.selectedProduct,
                title: productId?.title ? productId?.title : "",
                description: productId?.description ? productId?.description : "",
                id: productId?._id ? productId?._id : ""
            }))
            setGroupFormError((prevState) => ({
                ...prevState,
                products: null
            }))
        } else {

        }
    }, [])

    return (
        <MainTemplate categoryName="Add Products" categoryList={["Homepage Modifie", " Products"]}>
            <div className="row rounded px-3 bg-white py-2">
                <div>
                    <div>
                        <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">Title{" "}<span className="text-danger" >*</span></p>
                        <input
                            type="text"
                            className={`homepagemodifieraddedproductsinput ${groupFormError.title ? 'border-danger' : ""} `}
                            placeholder="Enter Title Name"
                            name="title"
                            onChange={handleChange}
                            value={groupForm.title}
                        />
                        <span className="text-danger" >{groupFormError.title}</span>
                    </div>
                    <div className="mt-3">
                        <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">Description{" "}<span className="text-danger" >*</span> </p>
                        <textarea
                            type="text"
                            className={`homepagemodifieraddedproductsinput ${groupFormError.description ? 'border-danger' : ""}  `}
                            style={{ height: "71px" }}
                            name="description"
                            onChange={handleChange}
                            value={groupForm.description}
                            placeholder="A description about the list in brief over here for your identification"
                        />
                        <span className="text-danger" >{groupFormError.description}</span>
                    </div>
                    <div className="mt-3" style={{ width: "452px" }}>
                        <div className="d-flex justify-content-between">
                            <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">  Products selected {" "}<span className="text-danger" >*</span>  </p>
                            <p className="homepagemodifieraddedproductslable" style={{ textDecorationLine: "underline" }} onClick={() => {
                                setSelectedProduct([]); setGroupForm((prveState) => ({
                                    ...prveState,
                                    products: []
                                }))
                            }}>
                                clear
                            </p>
                        </div>
                        <div className={`homepagemodifierselectedproducts px-2 py-4 ${groupFormError.products ? 'border-danger' : ""}`}>
                            {
                                selectedProduct?.map((ele, ind) => {
                                    return (
                                        <div className="">
                                            <div className="d-flex">
                                                <div className="corner-position" onClick={() => handleRemove(ele)} >
                                                    <img src={checkboxyellow} />
                                                </div>
                                                <div className="categoryImageDiv shadow-sm  rounded " style={{ marginLeft: '20px' }}>
                                                    {ele?.images && ele.images.length > 0 ? (
                                                        <img src={ele.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                                                    ) : (
                                                        <img src={ele?.image} alt="loading" className="categoryImage h-100 rounded" />
                                                    )}                        </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="" style={{ marginLeft: "20px" }}>
                                <Link
                                    to='/Homepage-modifier-group-products-list'
                                    state={{ page: 'ADDNEWPRODUCT', newProduct: selectedProduct, groupForm: groupForm }}
                                >
                                    {/* <div className="corner-position"
                                        > */}
                                    <img src={AddNewProduct} />
                                    {/* </div> */}
                                </Link>
                            </div>
                        </div>
                        <span className="text-danger" >{groupFormError.products}</span>

                        <div className="d-flex gap-4 mt-4">
                            <Link to='/homepagemodifieraddedproducts' state={{ data: { selectedProduct } }} type="reset"
                                onClick={handleSubmit}
                                // style={{ marginLeft: '10px' }}
                                className="btn btn-primary button-green px-5">
                                Add List{" "}
                                {
                                    groupForm?.loader
                                        ?
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                        />
                                        :
                                        ''
                                }
                            </Link>
                            <Link to='/homepagemodifieraddedproducts' state={{ data: { selectedProduct } }} type="reset"
                                // onClick={handleCleare}
                                className="btn btn-outline-dark  px-5">
                                Cancel
                            </Link>
                        </div>

                    </div>
                </div>

            </div>

        </MainTemplate>
    );
}

export default HomepageModifierAddedProducts;
