import React, { useEffect, useState, useMemo } from "react";
// import "./ProductPrice.css";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import { toast } from "react-toastify";
import MainTemplate from "../../components/MainTemplate";
import { deleteTokenApi, getTokenApi, postTokenApi } from "../../api_interface/apis";
import { HIBEE_PRODUCT_URL, PRODUCT_PATH, PRODUCT_SUBCATEGORY } from "../../api_interface/apiURLs";
import { customActionModalStyles, customModalStyles, debounce } from "../../utils/helperFunctin";
import checkboxyellow from '../../assets/images/checkboxyellow.svg'

function HomepageModifierGroupProductsList() {
  const location = useLocation();
  const { state } = location || {}

  const { subCategoryId } = useParams()
  const [sort, setSort] = useState("")
  const [query, setQuery] = useState("")
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([])
  const [productId, setProductId] = useState({})

  const [itemOffset, setItemOffset] = useState(0);
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const currentItems = products.slice(itemOffset, endOffset);
  const [loader, setLoader] = useState(false);
  const [actionModal, setActionModal] = useState({
    loader: false,
    show: false,
    type: "",
    data: {},
  });
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getProducts()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])

  const getProducts = async search => {
    setLoader(true);
    let res
    if (subCategoryId) {
      res = await postTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + PRODUCT_SUBCATEGORY + `?sort=${sort}&search=${search || query || ""}`, { subcategory: subCategoryId });
    } else {
      res = await getTokenApi(HIBEE_PRODUCT_URL + PRODUCT_PATH + `?sort=${sort}&search=${search || query || ""}`);
    }
    if (res.status == 200) {
      setProducts(res?.data?.data?.reverse());
      setLoader(false);
    }
  };

  const updateSort = e => {
    setSort(e.target.value)
  }

  const getSearchedData = useMemo(() => debounce(async (search) => {
    getProducts(search)
  }, 500), [])

  useEffect(() => {
    getProducts()
  }, [sort])

  useEffect(() => {
    getSearchedData(query)
  }, [query])



  const handleCheckBox = (el) => {
    if (!selectedProduct.includes(el)) {
      setSelectedProduct([...selectedProduct, el])
    }
  }

  const handleRemove = (ele) => {
    const removedObject = selectedProduct.filter((el) => el._id != ele._id)
    setSelectedProduct(removedObject)
  }

  useEffect(() => {
    getProducts()
  }, [])

  useEffect(() => {
    if (state && state?.page == "PRODUCTLIST" && state?.eidtData?.products) {
      setProductId(state?.eidtData)
      const editArray = state?.eidtData?.products
      const oldArray = products
      function findMatchingElements(oldArray, editArray) {
        return oldArray.filter((element) => editArray.includes(element._id));
      }
      const matchingElements = findMatchingElements(oldArray, editArray);
      setSelectedProduct(matchingElements)
    }

    if (state && state?.page == "ADDNEWPRODUCT" && state?.newProduct) {
      const editArray = state?.newProduct
      setSelectedProduct(editArray)
      setProductId((preState) => ({
        ...preState,
        title: state?.groupForm?.title,
        _id: state?.groupForm?.id,
        description: state?.groupForm?.description
      }))
    }

  }, [products])

  return (
    <MainTemplate categoryName="New Group" categoryList={["Homepage Modifier", "Groups", "New group"]}>
      <div className="row mb-4 rounded p-2 bg-white">
        {/* <div>
          <p className="homepagemodifieraddedproductslable p-0 m-0 py-1">
            Add title
          </p>
          <input
            type="text"
            className="homepagemodifieraddedproductsinput "
            placeholder="eg: New launch"
          />
        </div> */}
      </div>
      <div className="row mb-4 rounded p-2 bg-white">
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={updateSort}>
            <option value="" selected>Sort by</option>
            <option value="name">Alphabatically</option>
            <option value="dateold">Date: Acsending</option>
            <option value="datenew">Date: Descending</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-2">
          <select className="t-12 form-select" aria-label="Default select example" onChange={e => setItemsPerPage(e.target.value)}>
            <option value="10" selected>10 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
        <div className="col-sm-6 my-3 col-md-3">
          <input type="text" name="query" className="t-12 form-control" placeholder="Search" value={query} onChange={e => setQuery(e.target.value)} />
        </div>
        <div className="d-none d-md-block col-md-1" />
        {/* <div className="col-sm-6 my-3 col-md-4 d-flex gap-3">
          {subCategoryId && (
            <Link to={`/inventory-product-add/${subCategoryId}`}>
              <button type="button" className="t-12 w-100 btn btn-outline-dark">
                Add Product
              </button>
            </Link>
          )}
          <button type="button" className="products-actionbutton">
            Action
          </button>
        </div> */}
      </div>

      <div className="row rounded px-3 bg-white">
        {/* header */}
        <div className="row m-0 font-weight-bold t-12 text-uppercase">
          <div className="my-3 col-md-1">
            <input type="checkbox" />
          </div>
          <div className="p-0 my-3 col-md-1">ID</div>
          <div className="p-0 my-3 col-md-1">Photo</div>
          <div className="p-0 my-3 col-md-2">Product</div>
          <div className="p-0 my-3 col-1">Price</div>
          <div className="p-0 my-3 col-1 offset-2 mobile-size">Units</div>
          <div className="p-0 my-3 col-md-1" />
        </div>

        {/* body */}
        <div className="m-0 p-0 pe-3 overflow-auto caterogyPageScroll">
          {currentItems?.map((el) => (
            <div
              className="row border rounded m-0 px-2 mb-2 d-flex align-items-center"
              key={el?._id}
            >
              <div className="t-12 text-uppercase my-3 col-md-1">
                <input type="checkbox" onChange={() => handleCheckBox(el)} />
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1" style={{}}>
                <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                  {el?.product_code}
                </Link>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-1">
                <div className="categoryImageDiv shadow-sm  rounded p-1">
                  <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                    {el?.images && el.images.length > 0 ? (
                      <img src={el.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                    ) : (
                      <img src={el?.image} alt="loading" className="categoryImage h-100 rounded" />
                    )}
                  </Link>
                  <div>

                  </div>
                </div>
              </div>
              <div className="t-12 text-uppercase my-3 col-md-2">
                <Link className="text-dark" to={`/product/${el?._id}`} style={{ textDecoration: "none" }} >
                  {el?.product_name}
                </Link>
              </div>
              <div className="t-12 text-uppercase my-3 col">₹{el?.price}</div>
              <div className="t-12 text-uppercase my-3 col offset-2">
                {el?.sizeList?.size}{el?.sizeList?.unit}
              </div>

              <div className="col my-4 offset-2 dotsize">
                {/* <div className="dropdown">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    data-menu-target={`drop-down-menu-${el?._id}`}
                    className="bi bi-three-dots dropdown-btn cursorPointer"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                  </svg>

                  <div
                    id={`drop-down-menu-${el?._id}`}
                    className="dropdown-content p-2 mobile-dropdown2"
                  >
                    <div
                      className="px-3 py-2 d-flex cursorPointer"
                      onClick={() =>
                        setActionModal({
                          ...actionModal,
                          show: true,
                          data: el,
                          type: "DELETE",
                        })
                      }
                    >
                      <span className="mx-2 d-flex align-items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash3"
                          viewBox="0 0 16 16"
                        >
                          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                        </svg>
                      </span>

                      <span className="mx-2">
                        <div className="font-weight-bold">Delete</div>
                        <div className="">Delete this product</div>
                      </span>
                    </div>
                    <Link
                      to="/inventory-product-add"
                      state={{ data: el, edit: true }}
                      className="text-dark px-3 py-1 rounded"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="px-3 py-2 d-flex cursorPointer">
                        <span className="d-flex align-items-center">
                          <svg
                            className="bi bi-pencil border-bottom border-dark"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                          </svg>
                        </span>

                        <span className="mx-2">
                          <div className="font-weight-bold ml-1">Edit</div>
                          <div className="">Edit this product</div>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div> */}
              </div>

              
            </div>
          ))}
        </div>
      </div>
      <div className="row m-2 d-flex justify-content-center align-items-center">
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item mb-0"
          subContainerClassName="pages "
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          marginPagesDisplayed={2}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <div className="row mb-4 rounded p-2  bg-white" >
        <div className="row align-items-center" >
          <div className="col-8" >
            <p className="font-weight-bold t-12 text-uppercase">Selected products</p>
            <div className="" style={{ display: 'flex', flexWrap: 'wrap' }}>
              {
                selectedProduct?.map((ele, ind) => {
                  return (
                    <div className="" style={{ marginTop: '30px', marginLeft: '10px' }}>
                      <div className="d-flex">
                        <div className="corner-position" onClick={() => handleRemove(ele)} >
                          <img src={checkboxyellow} />
                        </div>
                        <div className="categoryImageDiv shadow-sm  rounded p-1" style={{ marginLeft: '20px' }}>
                          {ele?.images && ele.images.length > 0 ? (
                            <img src={ele.images[0].image} alt="loading" className="categoryImage h-100 rounded" />
                          ) : (
                            <img src={ele?.image} alt="loading" className="categoryImage h-100 rounded" />
                          )}                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {selectedProduct.length != 0 &&
            < div className="col-4 justify-content-end"  >
              {/*<button
                type="button"
                className="btn btn-success form-select actiondetail"
                outline="none"
              >
                Add Action
              </button> */}
              <Link to='/homepagemodifieraddedproducts' state={{ data: { selectedProduct }, productId: { productId } }} type="reset"
                // style={{ marginLeft: '10px' }}
                className="btn button-green text-warning px-5"
                style={{ backgroundColor: "#12352f" }}
              >Add Products</Link>
            </div>
          }
        </div>
      </div>
    </MainTemplate >
  );
}

export default HomepageModifierGroupProductsList;
