import React, { useEffect, useState } from 'react'
import MainTemplate from '../../components/MainTemplate'
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsChevronRight } from "react-icons/bs"
import { HIBEE_ADMIN_URL, INVENTORY_ALL_LIST } from '../../api_interface/apiURLs';
import { getTokenApi } from '../../api_interface/apis';

function CustominventoryHistory() {
    const [query, setQuery] = useState("");
    const [modal, setModal] = useState({ data: [], loader: false, show: false });
    const [sort, setSort] = useState("");
    const [formattedStartDate, setFormattedStartDate] = useState("");
    const [formattedEndDate, setFormattedEndDate] = useState("");
    const [loader, setLoader] = useState(false);
    const [inventory, setInventory] = useState(false);



    const getData = async (search) => {
        try {
            setLoader(true);
            const res = await getTokenApi(
                HIBEE_ADMIN_URL +
                INVENTORY_ALL_LIST + `?sort=${sort}&search=${search || query || ""}&dateFrom=${formattedStartDate}&dateTo=${formattedEndDate}`);
            if (res?.data) {
                const list = res.data.data;
                setInventory(list)

            }
            setLoader(false);
        } catch (err) {
            setLoader(false);
            console.log(err);
        }
    };

    useEffect(() => {
        getData()
    }, [sort, query, formattedEndDate, formattedStartDate])
    const actionClick = () => {
        if (modal?.data?.length) {
            setModal({ ...modal, show: true });
        } else {
            toast.error("Select orders first.", {
                position: "top-center",
                autoClose: 2000,
            });
        }
    };

    const updateSort = (e) => {
        console.log(e.target.value, 'oam');
        setSort(e.target.value);
    };

    const handleStartDateChange = (date) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const formattedDate = date
            ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
            : "";
        setFormattedStartDate(formattedDate);
        setFormattedEndDate("");
    };

    const handleEndDateChange = (date) => {
        const options = {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const formattedDate = date
            ? date.toLocaleDateString("en-GB", options).split("/").reverse().join("-")
            : "";
        setFormattedEndDate(formattedDate);
    };

    const [selectedInventory, setSelectedInventory] = useState(null);

    const handleInventoryClick = (inventory) => {
        setSelectedInventory(inventory?.product);
        setShowDiv((prevShowDiv) => (selectedInventory === inventory?.product ? !prevShowDiv : true));
    };


    const [showDiv, setShowDiv] = useState(false); // State to track visibility of the div

    const handleButtonClick = () => {
        setShowDiv(!showDiv); // Toggle the showDiv state on button click
    };

    const [exportOption, setExportOption] = useState(false)

    async function handleExportClick() {
        // Assuming you have the API JSON data stored in a variable called `jsonData`

        // Set the export option to true
        await setExportOption(true);

        // Get the API JSON data
        await getData();

        // Specify the headers you want to export
        const headersToExport = ['purchase_date', 'invoice_number', 'final_bill_amount'];

        // Convert JSON to CSV format with the specified headers
        const csvContent = convertToCSV(inventory, headersToExport);

        // Create a temporary anchor element to initiate the download
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
        downloadLink.download = 'data.csv';

        // Trigger the download
        downloadLink.click();

        // Set the export option back to false
        await setExportOption(false);
    }


    function convertToCSV(jsonData, headersToExport) {
        // Filter the JSON data to include only the objects with the desired headers
        const filteredData = jsonData.map(obj => {
            const filteredObj = {};
            headersToExport.forEach(header => {
                filteredObj[header] = obj[header];
            });
            return filteredObj;
        });

        // Extract column headers from the first object in the filtered data
        const headers = Object.keys(filteredData[0]);

        // Convert each object to an array of values
        const rows = filteredData.map(obj => Object.values(obj));

        // Combine headers and rows
        const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');

        return csvContent;
    }


    const today = new Date();
    // Set hours, minutes, seconds, and milliseconds to 0 to ensure only the date is considered
    today.setHours(0, 0, 0, 0);

    return (
        <MainTemplate
            categoryName="Custom Inventory History"
            categoryList={["Inventory", "Custom Inventory History"]}
        >
            <div className="row mb-2 rounded p-2 bg-white">

                <div className="col-sm-6 my-3 col-md-3">
                    <select
                        className="t-12 form-select"
                        aria-label="Default select example"
                        onChange={updateSort}
                    >
                        <option value="" disabled={true} selected>
                            Sort by
                        </option>
                        <option value="dateold">Date: Acsending</option>
                        <option value="datenew">Date: Descending</option>+
                    </select>
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <input
                        type="text"
                        name="query"
                        className="t-12 form-control"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="col-sm-6 my-3 col-md-5 ">

                    <div className="d-flex mx-0 align-items-center gap-2">
                        <p className="font-weight-bold t-12 text-uppercase m-0 p-0">From:</p>
                        <DatePicker
                            className="datepicker-value"
                            value={formattedStartDate ? formattedStartDate : "YYYY-MM-DD"}
                            onChange={handleStartDateChange}
                            dateFormat={"yyyy-MM-dd"}
                            maxDate={today}
                        />
                        <p className="font-weight-bold t-12 text-uppercase m-0 p-0">To:</p>
                        <DatePicker
                            className="datepicker-value "
                            value={formattedEndDate ? formattedEndDate : "YYYY-MM-DD"}
                            onChange={handleEndDateChange}
                            dateFormat={"yyyy-MM-dd"}
                            disabled={formattedStartDate ? false : true}
                            maxDate={today}
                        />
                    </div>

                    {/* <button
                        className="t-12 ms-2 px-4 btn btn-dark text-warning "
                        onClick={handleExportClick}
                    >
                        Export
                    </button> */}
                </div>
                <div className="col-sm-6 my-3 col-md-2">
                    <button
                        className="t-12 ms-2 px-4 btn btn-dark text-warning "
                        onClick={handleExportClick}
                    >
                        Export List
                    </button></div>
            </div>
            <div className="row mb-2 rounded p-2 bg-white">
                <table className=''>
                    <thead className=''>
                        <tr className="">
                            <td className='custominventoryhistoryheading py-2 px-2'>
                                Date
                            </td>
                            <td className='custominventoryhistoryheading py-2 px-2' style={{ width: 720 }}>
                                invoice number
                            </td>
                            <td className='custominventoryhistoryheading py-2 px-2'>
                                Total Amount
                            </td>
                            <td className='custominventoryhistoryheading py-2 px-2'>

                            </td>
                        </tr>
                    </thead>
                    <tbody className='my-5'>
                        {inventory ? (
                            inventory.map((item, index) => (
                                <React.Fragment key={item.invoice_number}>
                                    <tr
                                        className="border rounded"
                                        onClick={() => handleInventoryClick(item)}
                                    >
                                        <td className="custominventoryhistorydata py-2 px-2">
                                            {item.purchase_date}
                                        </td>
                                        <td className="custominventoryhistorydata py-2 px-2">
                                            {item.invoice_number}
                                        </td>
                                        <td className="custominventoryhistorydata py-2 px-2">
                                            ₹ {item.final_bill_amount}
                                        </td>
                                        <td className="custominventoryhistorydata py-2 px-2">
                                            <BsChevronRight />
                                        </td>
                                    </tr>
                                    {selectedInventory === item.product && showDiv && (
                                        <tr className="accordion-row">
                                            <td colSpan="4">
                                                <div className='mt-2' style={{ position: "", background: "white", width: "", display: "flex", justifyContent: "center" }}>
                                                    <div>
                                                        {/* Rest of your accordion content */}
                                                        <table className="" style={{ position: "" }} >
                                                            <thead>
                                                                <tr>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Item Name</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Qty</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Unit</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Exp Date</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Rate</p>
                                                                        </div>
                                                                    </th>
                                                                    <th className="orderstableheading">
                                                                        <div className="d-flex align-items-center p-1">
                                                                            <p className="m-0">Total</p>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.product.map((productItem, index) => (
                                                                    <tr key={index} style={{ marginTop: 1 }}>
                                                                        <td className="orderstabledetails">
                                                                            <div className="d-flex align-items-center custominventory400 customeinventoryData me-1">
                                                                                {productItem?.product_name}
                                                                            </div>
                                                                        </td>
                                                                        <td className="orderstabledetails">
                                                                            <div className="custominventory400 customeinventoryData me-1">
                                                                                {productItem?.Qty}
                                                                            </div>
                                                                        </td>
                                                                        <td className="orderstabledetails">
                                                                            <div className="d-flex align-items-center custominventory400 customeinventoryData me-1">
                                                                                {productItem?.unit}
                                                                            </div>
                                                                        </td>
                                                                        <td className="orderstabledetails">
                                                                            <div className="custominventory400 customeinventoryData me-1">
                                                                                {/* {productItem?.exp_date} */}
                                                                                {productItem?.exp_date.slice(0, 10)}
                                                                            </div>
                                                                        </td>
                                                                        <td className="orderstabledetails">
                                                                            <div className="custominventory400 customeinventoryData me-1">
                                                                                {productItem?.rate}
                                                                            </div>
                                                                        </td>
                                                                        <td className="orderstabledetails">
                                                                            <div className="d-flex align-items-center custominventory400 customeinventoryData py-3 me-1">
                                                                                {productItem?.total}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <div className='d-flex justify-content-between align-items-center gap-5' style={{ marginTop: 46, width: 350 }}>
                                                            <p className='custominventory500'>CGST Amount</p>
                                                            <p className="custominventory400 customeinventoryData mt-1">
                                                                {/* Add CGST amount value here */}
                                                                {item.cgst}
                                                            </p>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center gap-5' style={{ paddingTop: 2, width: 350 }}>
                                                            <p className='custominventory500'>SGST Amount</p>
                                                            <p className="custominventory400 customeinventoryData mt-1">
                                                                {/* Add SGST amount value here */}
                                                                {item.sgst}
                                                            </p>
                                                        </div>
                                                        <div className='d-flex justify-content-between align-items-center gap-5' style={{ paddingTop: 2, width: 350 }}>
                                                            <p className='custominventory500'>Final Bill Amount</p>
                                                            <p className="custominventory400 customeinventoryData mt-1">
                                                                {/* Add final bill amount value here */}
                                                                {item.final_bill_amount}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">
                                    <p>No inventory data available.</p>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
        </MainTemplate>
    )
}

export default CustominventoryHistory
